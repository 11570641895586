import React from 'react'
import c from 'classnames'

interface Props {
  content: string
  evaluated: boolean
  correct: boolean | undefined
  onEvaluate?: Function
  disabled: boolean
  className?: string
}

interface State {
  hover: boolean
}

class Answer extends React.Component<Props, State> {
  static defaultProps = {
    content: '',
    evaluated: false,
    correct: undefined,
    disabled: false,
    className: ''
  }

  constructor(props: Props) {
    super(props)

    this.state = {
      hover: false,
    }
  }

  handleMouseOver = () => {
    if (!this.props.disabled) {
      this.setState({
        hover: true,
      })
    }
  }

  handleMouseLeave = () => {
    if (!this.props.disabled) {
      this.setState({
        hover: false,
      })
    }
  }

  evaluationButtonCorrect = () => (
    <button
      className="evaluation-button"
      onClick={(e) => {
        e.preventDefault()

        const { onEvaluate } = this.props

        if (onEvaluate) {
          onEvaluate(true)
        }
      }}
    >
      <span role="img" aria-label="correct">
        👍
      </span>
    </button>
  )

  evaluationButtonIncorrect = () => (
    <button
      className="evaluation-button"
      onClick={(e) => {
        e.preventDefault()

        const { onEvaluate } = this.props

        if (onEvaluate) {
          onEvaluate(false)
        }
      }}
    >
      <span role="img" aria-label="incorrect">
        👎
      </span>
    </button>
  )

  render = () => {
    const { props } = this
    const { correct, evaluated, content } = props
    const classNames = ['provided-answer']

    if (evaluated) {
      classNames.push('evaluated')

      if (correct) {
        classNames.push('bg-green-200')
      } else {
        classNames.push('bg-red-200')
      }
    } else {
      classNames.push('bg-yellow-200')
    }

    const notCorrect = !evaluated || !correct
    const notIncorrect = !evaluated || correct

    const { disabled, children, className } = this.props

    return (
      <span
        className={c("font-bold", "relative", className)}
        onMouseOver={this.handleMouseOver}
        onMouseLeave={this.handleMouseLeave}
      >
        <span className={c([...classNames, className])}>
          {children ? children : content ? content : '______'}
        </span>

        {!disabled && this.state.hover && (
          <span className="absolute top-0 left-0 flex-no-wrap flex px-2 rounded-sm bg-gray-700">
            {notCorrect && this.evaluationButtonCorrect()}
            {notIncorrect && this.evaluationButtonIncorrect()}
          </span>
        )}
      </span>
    )
  }
}

export default Answer
