import React, { useState, useCallback, useEffect } from 'react'
import firebase from 'firebase/app'
import moment from 'moment'

const Responses = () => {
  const [responses, setResponses] = useState([])
  const [loading, setLoading] = useState(false)
  const [loaded, setLoaded] = useState(false)
  // const [status, setStatus] = useState('')

  const fetchData = useCallback(async () => {
    setLoading(true)

    const getAllResponses = firebase
      .functions()
      .httpsCallable('getAllResponses')
    const users = await getAllResponses()

    setResponses(users.data)
    setLoading(false)
    setLoaded(true)
  }, [])

  useEffect(() => {
    if (!loaded && !loading) {
      fetchData()
    }
  })

  return (
    <div>
      {/* {status && (
        <div className="bg-sadgreen text-white p-2 text-center mb-4">
          {status}
        </div>
      )} */}
      {loading && (
        <div className="bg-darkpurple text-white p-2 text-center mb-4">
          loading...
        </div>
      )}
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 sm:px-10">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      ID
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      Responded at
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      Chapter
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      Name
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      Student
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      Teacher
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      Evaluated
                    </th>
                    <th className="px-6 py-3 bg-gray-50"></th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {responses.map((response: any, index) => (
                    <tr key={index}>
                      <td className="px-6 py-4 whitespace-no-wrap">
                        {response.id}
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap">
                        {moment(response.createdAt).format(
                          'YYYY-MM-DD HH:mm:ss'
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap">
                        {response.quiz.chapter}
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap">
                        {response.quiz.name}
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap">
                        {response.studentEmail}
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap">
                        {response.teacherEmail}
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                        {response.evaluated ? (
                          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                            yes
                          </span>
                        ) : (
                          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                            no
                          </span>
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap text-right text-sm leading-5 font-medium">
                        <a
                          href={`/evaluate/${response.id}`}
                          className="text-indigo-600 hover:text-indigo-900"
                        >
                          Evaluate
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Responses
