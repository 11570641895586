import React from 'react'
import { Link, Route, BrowserRouter as Router } from 'react-router-dom'
import AdminUsers from './Admin/Users'
import AdminResponses from './Admin/Responses'

const Admin = () => {
  return (
    <Router>
      <Route path="/admin">
        <div className="w-full flex flex-row p-2 pl-20 h-10 bg-blue-900 text-white font-bold shadow-sm mt-0 mb-8">
          <div className="">
            <Link to="/admin/users">Users</Link>
          </div>
          <div className="pl-4">
            <Link to="/admin/responses">Responses</Link>
          </div>
        </div>
      </Route>
      <Route path="/admin/users">
        <AdminUsers />
      </Route>
      <Route path="/admin/responses">
        <AdminResponses />
      </Route>
    </Router>
  )
}

export default Admin
