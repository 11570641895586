import React, { useState, useEffect } from 'react'
import c from 'classnames'
import { storage } from '../../firebase'

const MainImage = (props: any) => {
  const [src, setSrc] = useState<string>(props.src)

  useEffect(() => {
    if (props.sanityRef) {
      // console.log(`😇😇 loading ref`, props.sanityRef)
      storage
        .ref(`images/${props.sanityRef}`)
        .getDownloadURL()
        .then((url: string) => {
          // console.log(
          //   `😇😇😇 getDownloadUrl(ref=${props.sanityRef}) returned`,
          //   url
          // )
          setSrc(url)
        })
    }
  })

  return (
    <div className={c('w-full', props.className)}>
      <img
        className={[
          'object-contain',
          'max-h-halfscreen',
          'lg:max-h-halfscreen',
          'max-w-screen',
          'w-full',
          'mb-4',
        ].join(' ')}
        src={src}
        alt="001"
      />
    </div>
  )
}

export default MainImage
