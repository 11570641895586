import React from 'react'

const SideImage = (props: any) => (
  <div className={'w-full text-center center p-0 m-0'}>
    <img
      src={props.src}
      alt="background"
      className="object-center max-h-2/3 w-full"
    />
  </div>
)

export default SideImage
