import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { auth } from './firebase'

const SignOut = () => {
  const [signedIn, setSignedIn] = useState(true)

  useEffect(() => {
    if (signedIn) {
      auth.signOut().then(() => setSignedIn(false))
    }
  })

  return <div>{!signedIn && <Redirect to="/" />}</div>
}
export default SignOut
