import React from 'react'
import QuizSection from './chapters/components/QuizSection'
import { QuizMode, QuizComponentsByType } from './Quizes/Quiz'
import moment from 'moment'
import firebase from 'firebase/app'

import { Quiz, AnswerEvaluation } from './client'

interface Props {
  evaluationID: string
}

interface State {
  quiz?: Quiz
  loading: boolean
  answers: object
  comments: (undefined | string)[]
  notes: string
  evaluations: AnswerEvaluation[]
  createdAt: string
}

class QuizReviewComponent extends React.Component<Props, State> {
  static defaultProps = {
    evaluationID: undefined,
  }

  constructor(props: Props) {
    super(props)

    this.state = {
      comments: [],
      quiz: undefined,
      loading: false,
      answers: [],
      notes: '',
      evaluations: [],
      createdAt: '',
    }
  }

  loadEvaluationWithID = async (evaluationID: string) => {
    this.setState({
      loading: true,
    })

    const getEvaluation = firebase.functions().httpsCallable('getEvaluation')
    const getResponse = firebase.functions().httpsCallable('getResponse')

    const evaluation = (await getEvaluation({ id: evaluationID })) as any

    if (evaluation && evaluation.data && evaluation.data.responseID) {
      const response = (await getResponse({
        id: evaluation.data.responseID,
      })) as any

      if (response) {
        this.setState({
          loading: false,
          answers: response.data.answers,
          quiz: response.data.quiz,
          comments: evaluation.data.comments,
          evaluations: evaluation.data.evaluations,
          notes: evaluation.data.notes,
          createdAt: evaluation.data.createdAt,
        })
      }
    }
  }

  componentDidMount = async () => {
    await this.loadEvaluationWithID(this.props.evaluationID)
  }

  renderQuiz = (quiz: Quiz) => {
    const { answers, evaluations, comments, notes } = this.state

    const component = QuizComponentsByType[quiz._type]

    if (!component) {
      return
    }

    return React.createElement(component, {
      quiz,
      mode: QuizMode.Review,
      notes,
      evaluations,
      comments,
      answers,
      onAnswersUpdate: (answers: object) => {
        this.setState({ answers })
      },
    })
  }

  render = () => {
    const { quiz, loading, createdAt } = this.state

    if (loading) {
      return <div>loading...</div>
    }

    if (!quiz) {
      return <div>evaluation not found</div>
    }

    return (
      <div className="container mx-auto">
        <div className="flex flex-col justify-center w-full">
          <div className="italic text-white bg-blue-900 p-2 shadow-sm w-full text-center">
            Evaluation submitted at{' '}
            <span className="whitespace-no-wrap">
              {moment(createdAt).format('YYYY-MM-DD HH:mm:ss')}
            </span>
          </div>
          <h1 className="text-3xl mt-5 font-bold text-center">
            Chapter {quiz.chapter}
          </h1>

          <h2 className="text-2xl font-bold text-center">{quiz.name}</h2>

          <QuizSection>
            <div className="mb-10">{this.renderQuiz(quiz)}</div>
          </QuizSection>
        </div>
      </div>
    )
  }
}

export default QuizReviewComponent
