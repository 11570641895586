import React from 'react'
import SubmitAnswer from './SubmitAnswer'
import EmojiLoading from './EmojiLoading'

import { Quiz } from './client'
import { QuizMode, QuizComponentsByType } from './Quizes/Quiz'

import firebase from 'firebase/app'

const getQuiz = firebase.functions().httpsCallable('getQuiz')

interface Props {
  quizID?: string
  quiz?: Quiz
}

interface State {
  quiz?: Quiz
  loading: boolean
  submitting: boolean
  answers?: object
}

class QuizComponent extends React.Component<Props, State> {
  static defaultProps = {
    quizID: undefined,
    quiz: undefined,
  }

  constructor(props: Props) {
    super(props)

    this.state = {
      quiz: props.quiz,
      loading: false,
      submitting: false,
      answers: undefined,
    }
  }

  onSubmit = (e: any) => {
    e.preventDefault()

    this.setState({
      submitting: true,
    })
  }

  renderNavigation = () => (
    <div className="mt-8 w-full text-right pr-20">
      <input
        type="reset"
        className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-50 focus:outline-none focus:border-indigo-300 focus:shadow-outline-indigo active:bg-indigo-200 transition ease-in-out duration-150"
      />

      <span className="inline-flex rounded-md shadow-sm ml-5">
        <button
          type="button"
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150"
          onClick={this.onSubmit}
        >
          Send
        </button>
      </span>
    </div>
  )

  setQuiz = (quiz: Quiz) => {
    if (quiz) {
      this.setState({
        quiz,
        loading: false,
      })
    }
  }

  loadQuizWithID = async (quizID: string) => {
    const { loading } = this.state

    if (!loading && quizID) {
      this.setState(
        {
          loading: true,
        },
        async () => {
          const quiz = await getQuiz({ id: quizID })

          if (quiz) {
            this.setQuiz(quiz.data)
          }
        }
      )
    }
  }

  componentDidMount = async () => {
    if (!this.props.quiz && this.props.quizID) {
      await this.loadQuizWithID(this.props.quizID)
    }
  }

  renderQuiz = (quiz: Quiz) => {
    const component = QuizComponentsByType[quiz._type]

    if (!component) {
      return
    }

    return React.createElement(component, {
      quiz,
      mode: QuizMode.Answer,
      answers: this.state.answers,
      onAnswersUpdate: (answers: object) => {
        this.setState({ answers })
      },
    })
  }

  renderSubmitAnswerWindow = () => {
    const { quiz, submitting, answers } = this.state

    if (quiz && submitting) {
      return <SubmitAnswer quiz={quiz} answers={answers} />
    }

    return
  }

  render = () => {
    const { quiz, loading } = this.state
    // console.log('QuizResponseComponent quiz', quiz)

    if (loading) {
      return (
        <div className="w-full h-full text-center align-middle">
          <EmojiLoading />
        </div>
      )
    }

    if (!quiz) {
      return <div>quiz not found</div>
    }

    return (
      <div className="w-full">
        <div>
          <form>
            {this.renderQuiz(quiz)}

            {this.renderSubmitAnswerWindow()}

            {this.renderNavigation()}
          </form>
        </div>
      </div>
    )
  }
}

export default QuizComponent
