import React from 'react'
import { SectionHeader, SectionType } from './components/SectionHeader'
import ChapterHeader from './components/ChapterHeader'
import QuizSection from './components/QuizSection'
import QuizResponseComponent from '../QuizResponseComponent'

const Chapter = () => {
  return (
    <div>
      <ChapterHeader text="Lección 5" />

      <SectionHeader type={SectionType.Review} text="REPASO 1" />

      <QuizSection>
        <QuizResponseComponent
          quizID={'a2b322d7-0d5b-425d-bfeb-cfd2ceef35f2'}
        />
      </QuizSection>

      <SectionHeader type={SectionType.Review} text="REPASO 2" />

      <QuizSection>
        <QuizResponseComponent
          quizID={'7e5f94e7-6377-4a57-9cd6-2ddf522a8301'}
        />
      </QuizSection>

      <SectionHeader type={SectionType.Review} text="REPASO 3" />

      <QuizSection>
        <QuizResponseComponent
          quizID={'6f7e5cca-7107-46de-a212-c02a73f9bb0d'}
        />
      </QuizSection>

      <SectionHeader type={SectionType.Review} text="REPASO 4" />

      <QuizSection>
        <QuizResponseComponent
          quizID={'e6605d99-8da8-4518-8a63-6ce277b2c3a6'}
        />
      </QuizSection>

      <SectionHeader type={SectionType.Review} text="      1" />

      <QuizSection>
        <QuizResponseComponent
          quizID={'72a51fd7-97cb-4176-9176-b73e2984c698'}
        />
      </QuizSection>
    </div>
  )
}

export default Chapter
