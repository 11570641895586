import React, { useState } from 'react'
import { auth } from './firebase'

const SignUp = (props: { email?: string | null }) => {
  const [email, setEmail] = useState(props.email || '')
  const [password, setPassword] = useState('')
  const [password2, setPassword2] = useState('')
  const [displayName, setDisplayName] = useState('')
  const [accountCreated, setAccountCreated] = useState(false)
  const [error, setError] = useState({ message: '' })

  let emailLocked = false

  if (props.email) {
    emailLocked = true
  }

  const createUserWithEmailAndPasswordHandler = async (
    event: any,
    email: string,
    password: string
  ) => {
    event.preventDefault()
    try {
      if (password !== password2) {
        alert('passwords do not match!')
      }

      await auth.createUserWithEmailAndPassword(email, password)

      setAccountCreated(true)
    } catch (error) {
      setError(error)
    }

    setEmail('')
    setPassword('')
    setDisplayName('')
  }

  const onChangeHandler = (event: any) => {
    const { name, value } = event.currentTarget
    if (name === 'userEmail') {
      setEmail(value)
    } else if (name === 'userPassword') {
      setPassword(value)
    } else if (name === 'userPassword2') {
      setPassword2(value)
    } else if (name === 'displayName') {
      setDisplayName(value)
    }
  }

  return (
    <div>
      {accountCreated && (
        <div className="w-full flex flex-row justify-center">
          <div className="w-full md:w-1/2 text-center rounded-sm py-2 bg-sadgreen text-white">
            Your account has been successfully created - check your e-mail to
            access it.
          </div>
        </div>
      )}
      <div className="bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md mb-6">
          <h2 className="text-center text-3xl leading-9 font-extrabold text-gray-900">
            Sign up for an your account
          </h2>
        </div>
        {error && error.message && (
          <div className="sm:mx-auto sm:w-full sm:max-w-md mb-6 bg-red-600 rounded py-2">
            <h3 className="text-center text-l leading-9 text-white">
              {error.message}
            </h3>
          </div>
        )}
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form action="#" method="POST">
              <div>
                <label
                  htmlFor="name"
                  className="block text-sm font-medium leading-5 text-gray-700"
                >
                  Name
                </label>
                <div className="mt-1 rounded-md shadow-sm">
                  <input
                    id="displayName"
                    name="displayName"
                    type="text"
                    required
                    value={displayName}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    onChange={(event) => onChangeHandler(event)}
                  />
                </div>
              </div>

              <div className="mt-6">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-5 text-gray-700"
                >
                  Email address
                </label>
                <div className="mt-1 rounded-md shadow-sm">
                  <input
                    id="userEmail"
                    name="userEmail"
                    type="email"
                    value={email}
                    disabled={emailLocked}
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    onChange={(event) => onChangeHandler(event)}
                  />
                </div>
              </div>

              <div className="mt-6">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-5 text-gray-700"
                >
                  Password
                </label>
                <div className="mt-1 rounded-md shadow-sm">
                  <input
                    id="userPassword"
                    name="userPassword"
                    type="password"
                    value={password}
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    onChange={(event) => onChangeHandler(event)}
                  />
                </div>
              </div>

              <div className="mt-6">
                <label
                  htmlFor="password2"
                  className="block text-sm font-medium leading-5 text-gray-700"
                >
                  Repeat password
                </label>
                <div className="mt-1 rounded-md shadow-sm">
                  <input
                    id="userPassword2"
                    name="userPassword2"
                    type="password"
                    value={password2}
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    onChange={(event) => onChangeHandler(event)}
                  />
                </div>
              </div>

              <div className="mt-6">
                <span className="block w-full rounded-md shadow-sm">
                  <button
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                    onClick={(event) => {
                      createUserWithEmailAndPasswordHandler(
                        event,
                        email,
                        password
                      )
                    }}
                  >
                    Sign Up
                  </button>
                </span>
              </div>
            </form>
            <div className="text-gray-700 w-full text-center mt-5">
              Already have an account?{' '}
              <a href="/signIn" className="underline">
                Sign in here
              </a>
              .
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default SignUp
