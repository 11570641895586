import React from 'react'
import ReactDOM from 'react-dom'
import './assets/main.css'
import TopLevelContainer from './TopLevelContainer'
import * as serviceWorker from './serviceWorker'
import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"

// SENTRY_DSN

Sentry.init({
  dsn: "https://a1c3581ea0f649d2b50db04345b20dec@o460909.ingest.sentry.io/5461916",
  integrations: [
    new Integrations.BrowserTracing(),
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
})

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={"An error has occured"}>
    <React.StrictMode>
      <TopLevelContainer />
    </React.StrictMode>
  </Sentry.ErrorBoundary>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
