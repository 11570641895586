import React from 'react'
import Confetti from 'react-dom-confetti'
import firebase from 'firebase/app'

const createEvaluation = firebase.functions().httpsCallable('createEvaluation')

interface Props {
  evaluations: object
  comments: string[]
  notes: string
  responseID: string
  onCancel?: Function
}

interface State {
  submitting: boolean
  submitted: boolean
}

class SubmitEvaluation extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      submitting: false,
      submitted: false,
    }
  }

  onSubmit = async (event: any) => {
    const { evaluations, comments, notes, responseID } = this.props

    event.preventDefault()

    this.setState({
      submitting: true,
    })

    await createEvaluation({
      evaluations,
      responseID,
      comments,
      notes,
    })

    this.setState({
      submitting: false,
      submitted: true,
    })
  }

  render = () => {
    const { submitting, submitted } = this.state
    const { onCancel } = this.props

    return submitted ? (
      <div className="bg-sadgreen p-4 text-white text-center mt-6 xl:w-1/2 sm:w-full">
        <h1>Thanks for submitting your evaluation!</h1>
        <Confetti active={submitted} />

        <h2>We will notify the student right away.</h2>
      </div>
    ) : (
      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
          &#8203;
          <div
            className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div>
              <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                <svg
                  className="h-6 w-6 text-green-600"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </div>
              <div className="mt-3 text-center sm:mt-5">
                <h3
                  className="text-lg leading-6 font-medium text-gray-900"
                  id="modal-headline"
                >
                  Send your evaluation of this response?
                </h3>
              </div>
              <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <span className="flex w-full rounded-md shadow-sm sm:col-start-2">
                  <button
                    type="button"
                    className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    disabled={submitting}
                    onClick={this.onSubmit}
                  >
                    {submitting ? 'Sending...' : 'Send'}
                  </button>
                </span>
                <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1">
                  <button
                    type="button"
                    className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    onClick={() => onCancel && onCancel() }
                  >
                    Cancel
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default SubmitEvaluation
