import React from 'react'
import classnames from 'classnames'
import { Section, SectionSymbol } from './Utils'

const c = classnames

interface QuestionsBoxProps {
  questions: string[]
}

const QuestionsBox = ({ questions }: QuestionsBoxProps) => (
  <Section>
    <div>
      <div className="w-10">
        <SectionSymbol>
          <span aria-label="questions" role="img">
            ¿?
          </span>
        </SectionSymbol>
      </div>
    </div>
    <div className="xl:w-1/2 sm:w-full max-w-full text-blue-600 sm:ml-10 lg:ml-20">
      <ol
        className={
          'bg-blue-100 opacity-75 rounded-lg list-decimal pl-12 py-5 pr-5'
        }
      >
        {questions.map((question, questionIndex) => (
          <li
            key={questionIndex}
            className={c('ml-4', { 'mt-4': questionIndex > 0 })}
          >
            {question}
          </li>
        ))}
      </ol>
    </div>
  </Section>
)

export default QuestionsBox
