import React from 'react'
import classnames from 'classnames'

const c = classnames

interface ChapterHeaderProps {
  text: string
}

const ChapterHeader = ({ text }: ChapterHeaderProps) => (
  <div className={c('max-h-halfscreen')}>
    <div
      className={c(
        'uppercase',
        'absolute',
        'mt-3',
        'sm:mt-20',
        'xl:mt-24',
        'bg-dirtygray',
        'text-white',
        'z-10',
        'font-bold',
        'text-xl',
        'sm:text-4xl',
        'text-right',
        'w-1/2',
        'pr-4'
      )}
    >
      <h2>{text}</h2>
    </div>
    <div
      className={c(
        'text-5xl',
        'leading-tight',
        'xl:text-giga',
        'sm:text-mega',
        'text-gray-300',
        'z-0',
        'font-extrabold',
        'text-center',
        'p-0',
        'mt-10',
        'sm:m-0'
      )}
    >
      {text}
    </div>
  </div>
)

export default ChapterHeader
