import React from 'react'

interface WordSoupProps {
  words: any[]
}

const COLORS = [
  ['lightpurple', 'darkpurple'],
  ['lightgreen', 'sadgreen'],
  ['sadgreen', 'white'],
  ['dirtygray', 'white'],
  ['ochra', 'white'],
  ['darkpurple', 'white'],
]

const FONTSIZES = [
  'text-l sm:text-xl',
  'text-l sm:text-2xl',
  'text-l sm:text-3xl',
  'text-l sm:text-4xl',
]

const WordSoup = ({ words }: WordSoupProps) => {
  return (
    <div className="w-full flex flex-row h-auto flex-wrap justify-center">
      {words.map((word, wordIndex) => {
        const color = COLORS[wordIndex % COLORS.length]
        const fontSize = FONTSIZES[wordIndex % FONTSIZES.length]
        return (
          <div
            className={`m-2 p-2 sm:m-4 sm:p-4 font-bold ${fontSize} align-middle rounded-lg text-${color[1]} bg-${color[0]} shadow-md hover:shadow-2xl`}
            style={{ fontFamily: 'Impact' }}
            key={word}
          >
            {word}
          </div>
        )
      })}
    </div>
  )
}

export default WordSoup
