import React from 'react'
import { QuizMode, QuizComponentsByType } from './Quizes/Quiz'
import { Trigger as CommentTrigger } from './Quizes/Comment'
import SubmitEvaluation from './SubmitEvaluation'
import moment from 'moment'
import firebase from 'firebase/app'
import QuizSection from './chapters/components/QuizSection'
import classnames from 'classnames'
import EmojiLoading from './EmojiLoading'
import { Quiz } from './client'
const c = classnames
// import { Quiz, AnswerEvaluation, getResponse } from './client'

// import { ConnectWordsInTwoColumnsQuiz } from './Quizes/ConnectWordsInTwoColumns'

interface Props {
  responseID: string
}

interface State {
  quiz?: Quiz
  commentEditorsOpen: number[]
  hoverQuestion?: number
  loading: boolean
  loaded: boolean
  submitting: boolean
  answers: object
  comments: string[]
  notes: string
  evaluations: object
  studentEmail: string
  teacherEmail: string
  createdAt: string
}

class QuizEvaluationComponent extends React.Component<Props, State> {
  lastOpenedCommentEditorQuestionID?: number

  static defaultProps = {
    responseID: undefined,
  }

  constructor(props: Props) {
    super(props)

    this.lastOpenedCommentEditorQuestionID = undefined

    this.state = {
      commentEditorsOpen: [],
      comments: [],
      quiz: undefined,
      loading: false,
      loaded: false,
      hoverQuestion: undefined,
      submitting: false,
      answers: [],
      notes: '',
      evaluations: [],
      studentEmail: '',
      teacherEmail: '',
      createdAt: '',
    }
  }

  onSubmit = (e: any) => {
    e.preventDefault()

    this.setState({
      submitting: true,
    })
  }

  renderNavigation = () => (
    <div className="mt-8 w-full text-right pr-20 mb-10">
      <span className="inline-flex rounded-md shadow-sm ml-5">
        <button
          type="button"
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150"
          onClick={this.onSubmit}
        >
          Send evaluation to student
        </button>
      </span>
    </div>
  )

  loadResponseWithID = async (responseID: string) => {
    const getResponse = firebase.functions().httpsCallable('getResponse')

    const response: any = await getResponse({ id: responseID })

    if (response) {
      const {
        quiz,
        answers,
        studentEmail,
        teacherEmail,
        createdAt,
      } = response.data

      this.setState({
        quiz,
        answers,
        loading: false,
        loaded: true,
        studentEmail,
        teacherEmail,
        createdAt,
      })
    }
  }

  componentDidMount = async () => {
    await this.loadResponseWithID(this.props.responseID)
  }

  onCommentEditorOpen = (questionIndex: number) => {
    this.lastOpenedCommentEditorQuestionID = questionIndex

    this.setState((prevState: any) => {
      const newState = {
        ...prevState,
        commentEditorsOpen: prevState.commentEditorsOpen + questionIndex,
      }

      return newState
    })
  }

  isCommentEditorOpenForQuestion = (questionIndex: number) => {
    const { commentEditorsOpen } = this.state

    return commentEditorsOpen.includes(questionIndex)
  }

  lastCommentEditorOpen = () => this.lastOpenedCommentEditorQuestionID

  commentTriggerForQuestion = (questionIndex: number, className?: any) => {
    const { hoverQuestion, commentEditorsOpen } = this.state

    const isHoverOn = hoverQuestion === questionIndex
    const isEditorOpen = commentEditorsOpen.includes(questionIndex)

    const show = isHoverOn && !isEditorOpen

    return (
      <CommentTrigger
        handler={() => this.onCommentEditorOpen(questionIndex)}
        className={c({ visible: show, invisible: !show }, 'mr-2', className)}
      />
    )
  }

  onMouseOverQuestion = (questionIndex: number) => {
    this.setState({
      hoverQuestion: questionIndex,
    })
  }

  onMouseLeftQuestion = (questionIndex: number) => {
    this.setState({
      hoverQuestion: questionIndex,
    })
  }

  renderQuiz = (quiz: Quiz) => {
    const component = QuizComponentsByType[quiz._type]

    if (!component) {
      return
    }

    const { answers, notes, evaluations } = this.state

    return React.createElement(component, {
      quiz,
      mode: QuizMode.Evaluation,
      isCommentEditorOpenForQuestion: this.isCommentEditorOpenForQuestion,
      commentTriggerForQuestion: this.commentTriggerForQuestion,
      onMouseOverQuestion: this.onMouseOverQuestion,
      onMouseLeftQuestion: this.onMouseLeftQuestion,
      lastCommentEditorOpen: this.lastCommentEditorOpen,
      answers,
      notes,
      evaluations,
      onAnswersUpdate: (answers: object) => this.setState({ answers }),
      onEvaluationsUpdate: (evaluations: object) =>
        this.setState({ evaluations }),
      onCommentsUpdate: (comments: string[]) => this.setState({ comments }),
      onNotesUpdate: (notes: string) => this.setState({ notes }),
    })
  }

  renderSubmitAnswerWindow = () => {
    const { quiz, submitting, evaluations, comments, notes } = this.state
    const { responseID } = this.props

    if (quiz && submitting) {
      return (
        <SubmitEvaluation
          responseID={responseID}
          evaluations={evaluations}
          comments={comments}
          notes={notes}
          onCancel={() => this.setState({
            submitting: false
          })}
        />
      )
    }

    return
  }

  render = () => {
    const { quiz, loading, loaded, studentEmail, createdAt } = this.state

    if (loaded) {
      if (!quiz) {
        return <div>response not found</div>
      }

      return (
        <div className="container mx-auto">
          <div className="flex flex-col justify-center w-full">
            <div className="italic text-white bg-blue-900 p-2 shadow-sm w-full text-center">
              Response submitted by{' '}
              <a href={`mailto:${studentEmail}`}>{studentEmail}</a> at{' '}
              {moment(createdAt).format('YYYY-MM-DD HH:mm:ss')}
            </div>
            <h1 className="text-3xl mt-10 font-bold text-center">
              Chapter {quiz.chapter}, {quiz.name}
            </h1>

            <QuizSection>
              <div className="">{this.renderQuiz(quiz)}</div>
            </QuizSection>

            <div className="my-10">{this.renderNavigation()}</div>

            <div>{this.renderSubmitAnswerWindow()}</div>
          </div>
        </div>
      )
    }

    if (loading) {
      return (
        <div className="w-full h-full text-center align-middle">
          <EmojiLoading />
        </div>
      )
    }

    return <div></div>

  }
}

export default QuizEvaluationComponent
