import React from 'react'
import Answer from './Answer'
import { QuizMode, FillInGapsDataSource } from './Quiz'
import InputField from './InputField'
import Comment from './Comment'
import { QuizComponentProps } from '../Quizes/Quiz'
import { Quiz } from '../client'
import CommentReview from './CommentReview'
import { QuizWrapper, QuizInstruction } from '../chapters/components/Utils'

import classnames from 'classnames'
const c = classnames

interface CrosswordKeyword {
  question: string
  padding: number
  length: number
}

interface CrosswordTableProps {
  keywords: CrosswordKeyword[]
  answers: string[][]
  highlight_column_index: number
}

interface CrosswordQuiz extends Quiz {
  keywords: CrosswordKeyword[]
  highlight_column_index: number
}

interface CrosswordQuizComponentProps extends QuizComponentProps {
  quiz: CrosswordQuiz
  mode: QuizMode
}

const CrosswordTable = (props: CrosswordTableProps) => {
  const characterInputField = (selected: boolean, character?: string) => {
    return (
      <input
        className={c('border', 'h-10', 'w-10', 'text-center', 'uppercase', {
          'bg-lightgreen': selected,
          'bg-white': !selected,
          'font-bold': selected,
        })}
        disabled={true}
        value={character ? character : ''}
      />
    )
  }

  const { keywords, highlight_column_index, answers } = props

  return (
    <div className="">
      <table className="">
        <tbody>
          {keywords.map((keyword, index) => (
            <tr key={index}>
              {[...Array(keyword.padding)].map((foo, index2) => (
                <td key={index2}></td>
              ))}
              {[...Array(keyword.length)].map((foo, wordCharacterIndex) => {
                const columnIndex = wordCharacterIndex + keyword.padding

                let answer

                if (answers[index] && answers[index][0]) {
                  answer = answers[index][0]
                }

                let character

                if (answer && answer[wordCharacterIndex]) {
                  character = answer[wordCharacterIndex]
                }

                return (
                  <td key={wordCharacterIndex}>
                    {characterInputField(
                      columnIndex === highlight_column_index,
                      character
                    )}
                  </td>
                )
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

interface State {
  dataSource: FillInGapsDataSource
}

class Crossword extends React.Component<CrosswordQuizComponentProps, State> {
  constructor(props: CrosswordQuizComponentProps) {
    super(props)

    const { answers, evaluations, comments, notes } = props

    const newDataSource = new FillInGapsDataSource(
      answers,
      evaluations,
      comments,
      notes
    )

    if (props.onAnswersUpdate) {
      newDataSource.onAnswersUpdate = props.onAnswersUpdate
    }

    if (props.onEvaluationsUpdate) {
      newDataSource.onEvaluationsUpdate = props.onEvaluationsUpdate
    }

    if (props.onCommentsUpdate) {
      newDataSource.onCommentsUpdate = props.onCommentsUpdate
    }

    if (props.onNotesUpdate) {
      newDataSource.onNotesUpdate = props.onNotesUpdate
    }

    this.state = {
      dataSource: newDataSource,
    }
  }

  renderAnswer = (questionIndex: number, inputIndex: number) => {
    const { dataSource } = this.state
    const {
      getAnswerValue,
      setAnswerEvaluation,
      isAnswerEvaluated,
      isAnswerCorrect,
    } = dataSource

    return (
      <Answer
        disabled={this.props.mode === QuizMode.Review}
        content={getAnswerValue(questionIndex, inputIndex)}
        evaluated={
          isAnswerEvaluated && isAnswerEvaluated(questionIndex, inputIndex)
        }
        correct={isAnswerCorrect && isAnswerCorrect(questionIndex, inputIndex)}
        onEvaluate={(correct: boolean) =>
          setAnswerEvaluation &&
          setAnswerEvaluation(questionIndex, inputIndex, correct)
        }
      />
    )
  }

  renderQuestion = (question: any, questionIndex: number) => {
    const { mode } = this.props

    switch (mode) {
      case QuizMode.Answer:
        return this.renderQuestionAnswer(question, questionIndex)

      case QuizMode.Evaluation:
        return this.renderEditableQuestionEvaluation(question, questionIndex)

      case QuizMode.Review:
        return this.renderQuestionEvaluation(question, questionIndex)

      default:
        break
    }

    return
  }

  splitQuestion = (question: any) => question.question.split(/\.\.\.+/)

  renderQuestionAnswer = (question: any, questionIndex: number) => {
    const {
      dataSource: { setAnswerValue },
    } = this.state

    const questionFragments = this.splitQuestion(question)

    return (
      <li key={`input-list-${questionIndex}`} className={'mt-3 pl-2'}>
        {questionFragments.map(
          (questionFragment: string, fragmentIndex: number) => (
            <span key={`input-list-span-${questionIndex}-${fragmentIndex}`}>
              {questionFragment}

              {fragmentIndex !== questionFragments.length - 1 && (
                <InputField
                  questionIndex={questionIndex}
                  inputIndex={fragmentIndex}
                  setValue={(value: string) =>
                    setAnswerValue(questionIndex, fragmentIndex, value)
                  }
                  className={'mx-4 w-32'}
                />
              )}
            </span>
          )
        )}
      </li>
    )
  }

  renderCommentEditorIfNeeded = (quizMode: QuizMode, questionIndex: number) => {
    if (quizMode === QuizMode.Answer) {
      return
    }

    const { dataSource } = this.state
    const { isCommentEditorOpenForQuestion, lastCommentEditorOpen } = this.props

    const autoFocus =
      lastCommentEditorOpen && lastCommentEditorOpen() === questionIndex

    if (
      isCommentEditorOpenForQuestion &&
      !isCommentEditorOpenForQuestion(questionIndex)
    ) {
      return undefined
    }

    const value = dataSource.getCommentForAnswer(questionIndex)

    if (quizMode === QuizMode.Review) {
      return <CommentReview general={true} text={value} />
    }

    return (
      <Comment
        autoFocus={autoFocus}
        value={value}
        disabled={false}
        onChange={(e: React.FormEvent<HTMLInputElement>) =>
          dataSource.setCommentForAnswer(questionIndex, e.currentTarget.value)
        }
      />
    )
  }

  renderQuestionEvaluation = (question: string, questionIndex: number) => {
    const questionFragments = this.splitQuestion(question)

    return (
      <li key={`input-list-${questionIndex}`}>
        {questionFragments.map((fragment: string, fragmentIndex: number) => (
          <span key={`input-list-span-${questionIndex}-${fragmentIndex}`}>
            {fragment}

            {fragmentIndex !== questionFragments.length - 1 &&
              this.renderAnswer(questionIndex, fragmentIndex)}
          </span>
        ))}

        {this.renderCommentEditorIfNeeded(QuizMode.Review, questionIndex)}
      </li>
    )
  }

  renderEditableQuestionEvaluation = (
    question: string,
    questionIndex: number
  ) => {
    const questionFragments = this.splitQuestion(question)
    const {
      commentTriggerForQuestion,
      onMouseOverQuestion,
      onMouseLeftQuestion,
    } = this.props

    return (
      <li
        key={`input-list-${questionIndex}`}
        onMouseOver={() =>
          onMouseOverQuestion && onMouseOverQuestion(questionIndex)
        }
        onMouseLeave={() =>
          onMouseLeftQuestion && onMouseLeftQuestion(questionIndex)
        }
      >
        {commentTriggerForQuestion && commentTriggerForQuestion(questionIndex)}

        {questionFragments.map((fragment: string, fragmentIndex: number) => (
          <span key={`input-list-span-${questionIndex}-${fragmentIndex}`}>
            {fragment}

            {fragmentIndex !== questionFragments.length - 1 &&
              this.renderAnswer(questionIndex, fragmentIndex)}
          </span>
        ))}

        {this.renderCommentEditorIfNeeded(QuizMode.Evaluation, questionIndex)}
      </li>
    )
  }

  renderNotes = (notes?: string) =>
    notes && <CommentReview general={true} text={notes} />

  render() {
    const { quiz, mode, notes } = this.props
    const { keywords, instruction, highlight_column_index } = quiz
    const {
      dataSource: { setNotes },
    } = this.state

    return (
      <QuizWrapper>
        <QuizInstruction>{instruction}</QuizInstruction>

        <div className="my-5 w-full">
          {mode === QuizMode.Evaluation && (
            <Comment
              label="General notes to the student"
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setNotes && setNotes(e.currentTarget.value)
              }
            />
          )}
          {mode === QuizMode.Review && this.renderNotes(notes)}
        </div>

        <div className="my-10 w-full flex-row justify-center">
          <CrosswordTable
            keywords={keywords}
            highlight_column_index={highlight_column_index}
            answers={this.state.dataSource.answers}
          />
        </div>

        <div className="my-4">
          <ol className={'list-decimal pl-8'}>
            {keywords.map(this.renderQuestion)}
          </ol>
        </div>
      </QuizWrapper>
    )
  }
}

export default Crossword
