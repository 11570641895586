import React from 'react'
import { Section } from './Utils'

export const QuizSection = (props: any) => (
  <Section title={props.title}>
    <div className="flex flex-row justify-center w-full">
      <div className="w-full">{props.children}</div>
    </div>
  </Section>
)

export default QuizSection
