import React from 'react'
import Dialog from './components/Dialog'
import { SectionHeader, SectionType } from './components/SectionHeader'
import ChapterHeader from './components/ChapterHeader'
// import QuestionsBox from './components/QuestionsBox'
import QuizSection from './components/QuizSection'
import MainImage from './components/MainImage'
import QuizResponseComponent from '../QuizResponseComponent'
// import MainImage from './components/MainImage'
import {
  Table,
  TableSection,
  BoldTableContent,
  TableCell,
  QuizInstruction,
  QuizWrapper,
  Section,
} from './components/Utils'

import c from 'classnames'

const Frequency = (stats: Record<string, number>) => {
  return (
    <div className="w-full">
      {Object.keys(stats).map((word, wordIndex) => (
        <div
          key={wordIndex}
          className={c(`p-2 text-darkpurple font-bold`, {
            'bg-lightpurple': wordIndex % 2 === 0,
            'bg-semilightpurple': wordIndex % 2 !== 0,
          })}
          style={{ width: `${stats[word] - 10}vw` }}
        >
          {word}
        </div>
      ))}
    </div>
  )
}

const Chapter = () => {
  return (
    <div>
      <ChapterHeader text="Lección 10" />
      <SectionHeader type={SectionType.Dialog} text="diálogo 1" />
      <Dialog
        lines={[
          {
            speaker: 'Eva',
            text: '¡Hola Adán! ¿Hoy no trabajas?',
          },
          {
            speaker: 'Adán',
            text:
              'No. Tengo un día libre. Hoy preparo los documentos y escucho música. ¿Y tú?',
          },
          {
            speaker: 'Eva',
            text:
              'Yo contesto los correos electrónicos y hablo por teléfono con los clientes. ¿Y Gordi y Verdi?',
          },
          {
            speaker: 'Adán',
            text: 'Escuchan música y practican español. ¿Es verdad?',
          },
          {
            speaker: 'Gordi',
            text:
              'Sí, y también miramos fotos y compramos por internet. ¿Y vosotros trabajáis mucho hoy?',
          },
          {
            speaker: 'Eva',
            text: 'Mi marido no trabaja pero yo trabajo.',
          },
        ]}
      />
      <SectionHeader type={SectionType.Practice} text="PRÁCTICA 1" />
      <QuizSection>
        <QuizResponseComponent
          quizID={'2e463500-e5f3-42b9-a28a-149f030e23ce'}
        />
      </QuizSection>
      <SectionHeader type={SectionType.Practice} text="PRÁCTICA 2" />
      <QuizSection>
        <QuizResponseComponent
          quizID={'7391dd67-8a6b-45fc-a80b-c9f743883c5b'}
        />
      </QuizSection>
      <SectionHeader type={SectionType.Dialog} text="diálogo 2" />
      <Dialog
        lines={[
          {
            speaker: 'Eva',
            text: '¿Tenéis hambre?',
          },
          {
            speaker: 'Verdi',
            text: 'Sí, un poco.',
          },
          {
            speaker: 'Adán',
            text: '¿Qué coméis en Marte?',
          },
          {
            speaker: 'Verdi',
            text: 'Comemos carne y verduras y bebemos leche.',
          },
          {
            speaker: 'Gordi',
            text: 'Yo no como carne y no bebo leche porque soy vegetariano.',
          },
          {
            speaker: 'Verdi',
            text:
              '¿Cómo es posible? Eres vegetariano pero no eres muy delgado.',
          },
          {
            speaker: 'Gordi',
            text:
              'El problema es que no corro como tú. Soy intelectual y leo libros.',
          },
          {
            speaker: 'Eva',
            text: '¿Qué leen los habitantes de Marte?',
          },
          {
            speaker: 'Gordi',
            text:
              'Leemos novelas históricas y aprendemos idiomas. Vendemos muchos productos al extranjero y es importante hablar con los clientes. Y Verdi no comprende que los idiomas son necesarios.',
          },
          {
            speaker: 'Eva',
            text: 'Yo veo que tú comprendes muy bien el mercado internacional.',
          },
        ]}
      />
      <SectionHeader type={SectionType.Practice} text="PRÁCTICA 1" />
      <QuizSection>
        <QuizResponseComponent
          quizID={'766c3576-1394-436b-924b-ae573e512be0'}
        />
      </QuizSection>
      <SectionHeader type={SectionType.Practice} text="PRÁCTICA 2" />
      <QuizSection>
        <QuizResponseComponent
          quizID={'c0f65ac7-a9ef-4d24-a5a9-e5fece25dee0'}
        />
      </QuizSection>
      <SectionHeader type={SectionType.Dialog} text="diálogo 3" />
      <Dialog
        lines={[
          {
            speaker: 'Gordi',
            text: '¿Por qué discutís?',
          },
          {
            speaker: 'Adán',
            text: 'Yo no discuto, Eva discute.',
          },
          {
            speaker: 'Eva',
            text: 'Yo no discuto, tú discutes.',
          },
          {
            speaker: 'Gordi',
            text: '¿Por qué no abrimos una botella de vino y hablamos?',
          },
          {
            speaker: 'Adán',
            text: 'Perfecto. Compartimos el vino y no discutimos más.',
          },
          {
            speaker: 'Verdi',
            text:
              'Yo abro la botella de vino. ¿Vosotros abrís un paquete de patatas fritas?',
          },
          {
            speaker: 'Adán',
            text:
              'Sí, y también abrimos el balcón, porque tenemos mucho calor.',
          },
          {
            speaker: 'Eva',
            text: 'Verdi, ¿Dónde están las postales?',
          },
          {
            speaker: 'Verdi',
            text:
              'Están dentro del libro. Yo escribo una postal para mi novia Roti y tú Gordi, escribes una postal para tus abuelos.',
          },
          {
            speaker: 'Gordi',
            text: 'Sí, mis abuelos siempre reciben muchas cartas y postales.',
          },
          {
            speaker: 'Eva',
            text: '¡Qué bien! Nosotros nunca recibimos postales.',
          },
        ]}
      />
      <SectionHeader type={SectionType.Practice} text="PRÁCTICA 1" />
      <QuizSection>
        <QuizResponseComponent
          quizID={'963aecd2-044c-47dc-b63b-ca8d2f9c6717'}
        />
      </QuizSection>
      <SectionHeader type={SectionType.Practice} text="PRÁCTICA 2" />
      <QuizSection>
        <QuizResponseComponent
          quizID={'80317c11-01ed-432e-aa28-1edf6ca05078'}
        />
      </QuizSection>

      <SectionHeader type={SectionType.Grammar} text="GRAMÁTICA 1" />

      <div className="mt-10">
        <h2 className="text-2xl sm:text-4xl font-light w-full text-center">
          Verbos regulares Presente de Indicativo
        </h2>

        <TableSection>
          <Table>
            <thead>
              <tr className={'bg-lightpurple text-darkpurple font-bold'}>
                <th className={''}></th>
                <th className={''}>
                  <strong>-AR</strong>
                </th>
                <th className={''}>
                  <strong>-ER</strong>
                </th>
                <th className={''}>
                  <strong>-IR</strong>
                </th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <TableCell>
                  <BoldTableContent>yo</BoldTableContent>
                </TableCell>
                <TableCell className="whitespace-no-wrap">
                  trabaj-<span className="font-bold">o</span>
                </TableCell>
                <TableCell className="whitespace-no-wrap">
                  com-<span className="font-bold">o</span>
                </TableCell>
                <TableCell className="whitespace-no-wrap">
                  escrib-<span className="font-bold">o</span>
                </TableCell>
              </tr>
              <tr>
                <TableCell>
                  <BoldTableContent>tú</BoldTableContent>
                </TableCell>
                <TableCell className="whitespace-no-wrap">
                  trabaj-<span className="font-bold">as</span>
                </TableCell>
                <TableCell className="whitespace-no-wrap">
                  com-<span className="font-bold">es</span>
                </TableCell>
                <TableCell className="whitespace-no-wrap">
                  escrib-<span className="font-bold">es</span>
                </TableCell>
              </tr>
              <tr>
                <TableCell>
                  <BoldTableContent>él/ella/usted</BoldTableContent>
                </TableCell>
                <TableCell className="whitespace-no-wrap">
                  trabaj-<span className="font-bold">a</span>
                </TableCell>
                <TableCell className="whitespace-no-wrap">
                  com-<span className="font-bold">e</span>
                </TableCell>
                <TableCell className="whitespace-no-wrap">
                  escrib-<span className="font-bold">e</span>
                </TableCell>
              </tr>
              <tr>
                <TableCell>
                  <BoldTableContent>nosotros/as</BoldTableContent>
                </TableCell>
                <TableCell className="whitespace-no-wrap">
                  trabaj-<span className="font-bold">amos</span>
                </TableCell>
                <TableCell className="whitespace-no-wrap">
                  com-<span className="font-bold">emos</span>
                </TableCell>
                <TableCell className="whitespace-no-wrap">
                  escrib-<span className="font-bold">imos</span>
                </TableCell>
              </tr>
              <tr>
                <TableCell>
                  <BoldTableContent>vosotros/as</BoldTableContent>
                </TableCell>
                <TableCell className="whitespace-no-wrap">
                  trabaj-<span className="font-bold">áis</span>
                </TableCell>
                <TableCell className="whitespace-no-wrap">
                  com-<span className="font-bold">éis</span>
                </TableCell>
                <TableCell className="whitespace-no-wrap">
                  escrib-<span className="font-bold">ís</span>
                </TableCell>
              </tr>
              <tr>
                <TableCell>
                  <BoldTableContent>ellos/as</BoldTableContent>
                </TableCell>
                <TableCell className="whitespace-no-wrap">
                  trabaj-<span className="font-bold">an</span>
                </TableCell>
                <TableCell className="whitespace-no-wrap">
                  com-<span className="font-bold">en</span>
                </TableCell>
                <TableCell className="whitespace-no-wrap">
                  escrib-<span className="font-bold">en</span>
                </TableCell>
              </tr>
            </tbody>
          </Table>
        </TableSection>
      </div>

      <SectionHeader type={SectionType.Grammar} text="GRAMÁTICA 2" />

      <div className="mt-10">
        <Section title="Marcadores de frecuencia">
          {Frequency({
            siempre: 100,
            'casi siempre': 90,
            'generalmente/normalmente': 80,
            'a menudo': 70,
            'a veces': 60,
            'raras veces': 50,
            'casi nunca': 40,
            nunca: 30,
          })}
        </Section>
      </div>

      <SectionHeader type={SectionType.Practice} text="PRÁCTICA 1" />
      <QuizWrapper>
        <QuizInstruction>Completa las frases.</QuizInstruction>
      </QuizWrapper>
      <QuizSection>
        <QuizResponseComponent
          quizID={'7c501f5f-e49b-4b7b-af53-5ab01224a361'}
        />
      </QuizSection>
      <QuizSection>
        <QuizResponseComponent
          quizID={'12c1884a-c9d0-45b8-bc2f-8a74a32b80bb'}
        />
      </QuizSection>
      <QuizSection>
        <QuizResponseComponent
          quizID={'22887814-a290-4080-bfc0-b2d3be9db5e9'}
        />
      </QuizSection>

      <SectionHeader type={SectionType.Practice} text="PRÁCTICA 2" />

      <QuizSection>
        <QuizResponseComponent
          quizID={'1bd2d919-c506-4298-aa42-5f72ad85fd74'}
        />
      </QuizSection>

      <SectionHeader type={SectionType.Practice} text="PRÁCTICA 3" />

      <div className="w-full rounded-tl-lg bg-lightgreen text-sadgreen font-bold py-2 sm:p-4 sm:mx-4 xl:text-2xl xl:mx-10 mt-5 xl:mt-20">
        <table className="w-full text-center">
          <tbody>
            <tr>
              <td>comer</td>
              <td>escribir</td>
              <td>ver</td>
              <td>vender</td>
              <td>contestar</td>
            </tr>
            <tr>
              <td>leer</td>
              <td>recibir</td>
              <td>beber</td>
              <td>comprar</td>
              <td>abrir</td>
            </tr>
          </tbody>
        </table>
      </div>
      <QuizSection>
        <QuizResponseComponent
          quizID={'fab5d049-abaa-4b55-b2cc-61e46ef125b0'}
        />
      </QuizSection>
      <SectionHeader type={SectionType.Practice} text="PRÁCTICA 4" />
      <QuizSection>
        <QuizResponseComponent
          quizID={'b0b34743-4d76-48ed-a1d0-7a6eb5747cbd'}
        />
      </QuizSection>
      <SectionHeader type={SectionType.Practice} text="PRÁCTICA 5" />
      <QuizSection>
        <QuizResponseComponent
          quizID={'414e0406-348b-43f5-abec-ca4d518153a5'}
        />
      </QuizSection>
      <SectionHeader type={SectionType.Practice} text="PRÁCTICA 6" />
      <Section>
        <div className="flex flex-row w-full">
          <div className="w-1/2 md:w-1/4 pl-2">
            <ul>
              <li>la leche</li>
              <li>el pan</li>
              <li>la carne</li>
              <li>la verdura</li>
              <li>los cereales</li>
              <li>el brócoli</li>
              <li>la patata</li>
              <li>el yogur</li>
              <li>el refresco</li>
              <li>las fresas</li>
              <li>las cerezas</li>
              <li>los huevos</li>
              <li>las nueces</li>
              <li>el limón</li>
              <li>el pepino</li>
            </ul>
          </div>

          <div className="hidden md:block w-1/2">
            <MainImage src="/images/024.svg" alt="024" />
          </div>

          <div className="w-1/2 md:w-1/4">
            <ul>
              <li>el queso</li>
              <li>el pescado</li>
              <li>el chocolate</li>
              <li>los chupachups</li>
              <li>el tomate</li>
              <li>la zanahoria</li>
              <li>el plátano</li>
              <li>la naranja</li>
              <li>el pollo</li>
              <li>la mantequilla</li>
              <li>la lechuga</li>
              <li>el zumo</li>
              <li>el pastel</li>
              <li>la fruta</li>
              <li>agua</li>
            </ul>
          </div>
        </div>
        <div className="block md:hidden">
          <MainImage src="/images/024.svg" alt="024" />
        </div>
      </Section>
      <SectionHeader type={SectionType.Practice} text="PRÁCTICA 7" />

      <MainImage src="/images/025.svg" alt="025" />

      <QuizSection>
        <QuizResponseComponent
          quizID={'439ab8c3-205e-41c3-b77a-91f75c3481a9'}
        />
      </QuizSection>
    </div>
  )
}

export default Chapter
