import React from 'react'

import { QuizMode, MatchTwoIndexesDataSource } from './Quiz'
import { Quiz } from '../client'
import { QuizComponentProps } from '../Quizes/Quiz'
import {
  QuizWrapper,
  QuizContainer,
  QuizInstruction,
} from '../chapters/components/Utils'
import Comment from './Comment'
import Answer from './Answer'
import CommentReview from './CommentReview'

import c from 'classnames'

interface State {
  selected?: [number, number]
  dataSource: MatchTwoIndexesDataSource
}

export interface ConnectWordsInTwoColumnsQuiz extends Quiz {
  questions1: string[]
  questions2: string[]
}

interface ConnectWordsInTwoColumnsQuizComponentProps
  extends QuizComponentProps {
  quiz: ConnectWordsInTwoColumnsQuiz
}

class ConnectWordsInTwoColumns extends React.Component<
  ConnectWordsInTwoColumnsQuizComponentProps,
  State
> {
  constructor(props: ConnectWordsInTwoColumnsQuizComponentProps) {
    super(props)

    const { answers, evaluations, comments, notes } = props

    const newDataSource = new MatchTwoIndexesDataSource(
      answers,
      evaluations,
      comments,
      notes
    )

    if (props.onAnswersUpdate) {
      newDataSource.onAnswersUpdate = props.onAnswersUpdate
    }

    if (props.onEvaluationsUpdate) {
      newDataSource.onEvaluationsUpdate = props.onEvaluationsUpdate
    }

    if (props.onCommentsUpdate) {
      newDataSource.onCommentsUpdate = props.onCommentsUpdate
    }

    if (props.onNotesUpdate) {
      newDataSource.onNotesUpdate = props.onNotesUpdate
    }

    this.state = {
      selected: undefined,
      dataSource: newDataSource,
    }
  }

  addNewConnection = (index1: number, index2: number) => {
    this.state.dataSource.unsetAnswersWithValue(index2)
    this.state.dataSource.setAnswerValue(index1, index2)
  }

  handleClick = (columnIndex: number, rowIndex: number) => {
    const { selected: previouslySelected } = this.state
    let newSelected = previouslySelected

    if (previouslySelected) {
      if (previouslySelected[0] === columnIndex) {
        // same column - do not allow connections

        if (previouslySelected[1] === rowIndex) {
          // previously selected was clicked again - unselect
          this.setState({ selected: undefined })
        }

        return
      }

      // create a connection
      if (previouslySelected[0] === 0) {
        this.addNewConnection(previouslySelected[1], rowIndex)
      } else {
        this.addNewConnection(rowIndex, previouslySelected[1])
      }

      // remove selection
      newSelected = undefined
    } else {
      // first selection
      newSelected = [columnIndex, rowIndex]
    }

    this.setState({ selected: newSelected })
  }

  isCellSelected = (columnIndex: number, rowIndex: number) =>
    this.state.selected
      ? this.state.selected.join('-') === [columnIndex, rowIndex].join('-')
      : false

  isCellConnected = (columnIndex: number, rowIndex: number) => {
    if (columnIndex === 0) {
      if (this.state.dataSource.answers[rowIndex] !== undefined) {
        return true
      }
    } else {
      const matches = this.state.dataSource.answers.filter(
        (index) => rowIndex === index
      )

      if (matches.length > 0) {
        return true
      }
    }

    return false
  }

  renderTableQuestionRowsForIndex = (rowIndex: number) => {
    const { onMouseOverQuestion, onMouseLeftQuestion } = this.props

    return (
      <tbody key={`tbody-${rowIndex}`}>
        <tr
          key={`tr-${rowIndex}`}
          onMouseOver={() =>
            onMouseOverQuestion && onMouseOverQuestion(rowIndex)
          }
          onMouseLeave={() =>
            onMouseLeftQuestion && onMouseLeftQuestion(rowIndex)
          }
        >
          {this.renderFillOutTableCell(0, rowIndex)}

          {this.renderFillOutTableCell(1, rowIndex)}
        </tr>
      </tbody>
    )
  }

  renderFillOutTableCell = (columnIndex: number, rowIndex: number) => {
    const key = `input-${columnIndex}-${rowIndex}`
    let className = 'my-2 hover:bg-lightgreen'

    if (columnIndex === 0) {
      className = className + ' column-left'
    } else {
      className = className + ' column-right'
    }

    if (this.isCellSelected(columnIndex, rowIndex)) {
      className = [className, 'bg-lightgreen'].join(' ')
    }

    if (this.isCellConnected(columnIndex, rowIndex)) {
      className = [className, 'opacity-50'].join(' ')
    }

    return (
      <td
        key={key}
        className={className}
        onClick={() => this.handleClick(columnIndex, rowIndex)}
      >
        <div className={className}>
          {this.wordForQuestionIndex(columnIndex, rowIndex)}
        </div>
      </td>
    )
  }

  renderTableForQuestions = () => {
    const {
      quiz: { questions1, questions2 },
    } = this.props
    const rowCount =
      questions1.length > questions2.length
        ? questions1.length
        : questions2.length
    const rowIndexes = [...Array(rowCount).keys()]

    return (
      <table className="w-full">
        {rowIndexes.map((rowIndex) =>
          this.renderTableQuestionRowsForIndex(rowIndex)
        )}
      </table>
    )
  }

  wordForQuestionIndex(columnIndex: number, rowIndex?: number) {
    const { quiz } = this.props

    if (rowIndex === undefined) {
      return
    }

    if (columnIndex === 0) {
      return quiz.questions1[rowIndex]
    } else {
      return quiz.questions2[rowIndex]
    }
  }

  renderConnectionContent = (index1: number, index2?: number) => {
    const {
      isAnswerEvaluated,
      isAnswerCorrect,
      setAnswerEvaluation,
    } = this.state.dataSource

    const { mode } = this.props

    const firstWord = this.wordForQuestionIndex(0, index1)

    let secondWord

    if (mode === QuizMode.Evaluation || mode === QuizMode.Review) {
      secondWord = (
        <Answer
          evaluated={isAnswerEvaluated(index1)}
          correct={isAnswerCorrect(index1)}
          disabled={mode === QuizMode.Review}
          onEvaluate={(correct: boolean) =>
            setAnswerEvaluation(index1, correct)
          }
        >
          {this.wordForQuestionIndex(1, index2)}
        </Answer>
      )
    } else {
      if (typeof index2 === 'number') {
        secondWord = this.wordForQuestionIndex(1, index2)
      } else {
        secondWord = '🤷'
      }
    }

    return (
      <div className="italic flex flex-row justify-center w-full">
        <span>{firstWord}</span>

        <span className="px-6">→</span>

        <span>{secondWord}</span>
      </div>
    )
  }

  renderCommentEditorIfNeeded = (index1: number) => {
    const { dataSource } = this.state
    const {
      isCommentEditorOpenForQuestion,
      lastCommentEditorOpen,
      mode,
    } = this.props

    if (mode === QuizMode.Answer) {
      return
    }

    const autoFocus =
      lastCommentEditorOpen && lastCommentEditorOpen() === index1

    if (
      isCommentEditorOpenForQuestion &&
      !isCommentEditorOpenForQuestion(index1)
    ) {
      return undefined
    }

    const value = dataSource.getCommentForAnswer(index1)

    return (
      <div className="w-full flex justify-center my-2">
        {mode === QuizMode.Review ? (
          <CommentReview className="bg-gray-100" text={value} />
        ) : (
          <Comment
            autoFocus={autoFocus}
            value={value}
            className="w-1/2"
            disabled={false}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              dataSource.setCommentForAnswer(index1, e.currentTarget.value)
            }
          />
        )}
      </div>
    )
  }

  renderConnection(index1: number, index2?: number) {
    const {
      commentTriggerForQuestion,
      mode,
      onMouseLeftQuestion,
      onMouseOverQuestion,
    } = this.props

    if (mode === QuizMode.Evaluation || mode === QuizMode.Review) {
      // when evaluating, include empty answers
      return (
        <div
          key={`connection-${index1}-${index2}`}
          className="py-2 bg-white hover:bg-indigo-200 hover:font-bold w-full"
          onMouseOver={() => onMouseOverQuestion && onMouseOverQuestion(index1)}
          onMouseLeave={() =>
            onMouseLeftQuestion && onMouseLeftQuestion(index1)
          }
        >
          <div className="flex flex-row justify-center">
            {this.renderConnectionContent(index1, index2)}

            {commentTriggerForQuestion && commentTriggerForQuestion(index1)}
          </div>

          {this.renderCommentEditorIfNeeded(index1)}
        </div>
      )
    } else if (mode === QuizMode.Answer && index2 !== undefined) {
      // when answering, display only established connections
      return (
        <div key={`connection-${index1}-${index2}`}>
          {this.renderConnectionContent(index1, index2)}
        </div>
      )
    }

    return
  }

  renderConnections() {
    const { answers } = this.state.dataSource
    const { mode } = this.props

    if (answers) {
      const nonEmptyAnswers = answers.filter(
        (value) => typeof value === 'number'
      )

      if (nonEmptyAnswers.length > 0) {
        return (
          <div
            className={c(
              'rounded-lg my-4 text-center py-4 shadow-sm',
              mode === QuizMode.Answer ? 'bg-gray-200' : 'bg-white'
            )}
          >
            <div className="flex flex-col">
              {answers.map((index2, index1) =>
                this.renderConnection(index1, index2)
              )}
            </div>
          </div>
        )
      }
    }

    return
  }

  renderNotes = (notes?: string) =>
    notes && <CommentReview general={true} text={notes} />

  render() {
    const { quiz, mode, notes } = this.props
    const {
      dataSource: { setNotes },
    } = this.state

    return (
      <QuizWrapper>
        <QuizInstruction>{quiz.instruction}</QuizInstruction>

        <div className="my-5 w-full">
          {mode === QuizMode.Evaluation && (
            <Comment
              label="General notes to the student"
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setNotes && setNotes(e.currentTarget.value)
              }
            />
          )}
          {mode === QuizMode.Review && this.renderNotes(notes)}
        </div>

        <QuizContainer className="w-full flex flex-col justify-start">
          <div className="w-full">
            {mode === QuizMode.Answer && this.renderTableForQuestions()}
          </div>
          <div className="w-full">{this.renderConnections()}</div>
        </QuizContainer>
      </QuizWrapper>
    )
  }
}

export default ConnectWordsInTwoColumns
