import React, { Component, createContext } from 'react'
import { auth, app } from '../firebase'

export const UserContext = createContext<{
  user: any
  afterAuth: null | boolean
}>({ user: null, afterAuth: false })

class UserProvider extends Component {
  state = {
    user: null,
    afterAuth: false,
    tokenReloadForced: false,
  }

  componentDidMount = () => {
    auth.onAuthStateChanged((userAuth: any) => {
      if (userAuth) {
        const func = app.functions().httpsCallable('getCurrentUserInfo')

        func()
          .then(async (result: any) => {
            // console.log('🔥 RESULT', result)
            if (result.data.emailVerified !== true) {
              /* const idToken =  */ await auth.currentUser?.getIdTokenResult(
                true
              )
              // console.log('-------- ID TOKEN', idToken)
            }

            this.setState({
              user: { ...userAuth, ...result.data },
              afterAuth: true,
            })
          })
          .catch((error: any) => {
            console.log(error)
            this.setState({ user: userAuth, afterAuth: true })
          })
      } else {
        this.setState({ user: null, afterAuth: true })
      }
    })
  }
  render() {
    return (
      <UserContext.Provider value={this.state}>
        {this.props.children}
      </UserContext.Provider>
    )
  }
}

export default UserProvider
