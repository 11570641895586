import React from 'react'

interface Props {
  text: string
  label?: string
  autoFocus: boolean
  onChange?: Function
  disabled: boolean
  value?: string
  className?: string
}

class Comment extends React.Component<Props> {
  static defaultProps = {
    text: '',
    autoFocus: false,
    disabled: false,
    className: ''
  }

  render = () => {
    const { autoFocus, onChange, disabled, value, label, className } = this.props

    const classNames =
      'form-input block w-full sm:text-sm sm:leading-5 mt-1'

    return (
      <div className={className}>
        {label && (
          <label
            htmlFor="input"
            className="block text-sm font-medium leading-5 text-gray-700"
          >
            {label}
          </label>
        )}
        <div className="mt-1 relative rounded-md shadow-sm">
          {autoFocus ? (
            <textarea
              name="input"
              id="input"
              disabled={disabled}
              className={classNames}
              defaultValue={value || ''}
              onBlur={(e) => onChange && onChange(e)}
              onChange={(e) => onChange && onChange(e)}
              autoFocus
              placeholder="comment here..."
            />
          ) : (
              <textarea
                name="input"
                id="input"
                disabled={disabled}
                className={classNames}
                defaultValue={value || ''}
                onBlur={(e) => onChange && onChange(e)}
                onChange={(e) => onChange && onChange(e)}
                placeholder="comment here..."
              />
            )}
        </div>
      </div>
    )
  }
}

export function Trigger(props: { handler: any; className: string }) {
  return (
    <button
      className={props.className}
      onClick={(e) => {
        e.preventDefault()
        props.handler()
      }}
    >
      <span role="img" aria-label="comment">
        📝
      </span>
    </button>
  )
}

export default Comment
