// student < teacher < admin

export const isUserNoRole = (user: any) => !user || !user.role

export const isUserStudent = (user: any) => {
  if (user && user.role) {
    if (user.role === 'student') {
      return true
    }

    if (user.role === 'admin') {
      return true
    }

    if (user.role === 'teacher') {
      return true
    }
  }

  return false
}

export const isUserFree = (user: any) => {
  if (user && user.role) {
    if (user.role === 'free') {
      return true
    }
  }

  return false
}

export const isUserAdmin = (user: any) =>
  user && user.role && user.role === 'admin'

export const isUserTeacher = (user: any) =>
  user && user.role && (user.role === 'teacher' || user.role === 'admin')

export const canUserKnowAboutChapter = (user: any, chapterNumber: number) => {
  if (isUserAdmin(user)) {
    return true
  }

  if (isUserNoRole(user) || (isUserFree(user) && chapterNumber > 5)) {
    return false
  }

  // PREVIEW - USERS SHOULD NOT KNOW ABOUT NEW PREVIEW CHAPTERS
  // if (isUserStudent(user) && chapterNumber > 9) {
  //   return false
  // }

  return true
}

export const hasUserAccessToChapter = (user: any, chapterNumber: number) => {
  if (isUserNoRole(user)) {
    return false
  }

  if (isUserAdmin(user)) {
    return true
  }

  // OUTSIDE OF FREE PACKAGE
  if (isUserFree(user) && chapterNumber > 5) {
    return false
  }

  // PREVIEW - USERS CANNOT SEE NEWEST CHAPTERS
  // if (isUserStudent(user) && chapterNumber > 9) {
  //   return false
  // }

  return true
}
