import React from 'react'

import { QuizMode, FillInGapsDataSource } from './Quiz'
import Answer from './Answer'
import CommentReview from './CommentReview'
import { QuizComponentProps } from '../Quizes/Quiz'
import InputField from './InputField'
import Markdown from 'react-markdown'
import { Quiz } from '../client'
import {
  QuizWrapper,
  QuizContainer,
  QuizInstruction,
} from '../chapters/components/Utils'
import Comment from './Comment'

import c from 'classnames'

interface State {
  dataSource: FillInGapsDataSource
}

export interface FillOutSentencesInTableQuiz extends Quiz {
  header: boolean
  inputWidth?: number
  fullWidth?: boolean
  columnClasses?: string[]
}

interface FillOutSentencesInTableQuizComponentProps extends QuizComponentProps {
  quiz: FillOutSentencesInTableQuiz
  mode: QuizMode
}

class FillOutSentencesInTable extends React.Component<
  FillOutSentencesInTableQuizComponentProps,
  State
> {
  constructor(props: FillOutSentencesInTableQuizComponentProps) {
    super(props)

    const { answers, evaluations, comments, notes } = props

    const newDataSource = new FillInGapsDataSource(
      answers,
      evaluations,
      comments,
      notes
    )

    if (props.onAnswersUpdate) {
      newDataSource.onAnswersUpdate = props.onAnswersUpdate
    }

    if (props.onEvaluationsUpdate) {
      newDataSource.onEvaluationsUpdate = props.onEvaluationsUpdate
    }

    if (props.onCommentsUpdate) {
      newDataSource.onCommentsUpdate = props.onCommentsUpdate
    }

    if (props.onNotesUpdate) {
      newDataSource.onNotesUpdate = props.onNotesUpdate
    }

    this.state = {
      dataSource: newDataSource,
    }
  }

  renderCommentEditorIfNeeded = (quizMode: QuizMode, questionIndex: number) => {
    if (quizMode === QuizMode.Answer) {
      return
    }

    const { dataSource } = this.state
    const { isCommentEditorOpenForQuestion, lastCommentEditorOpen } = this.props

    const autoFocus =
      lastCommentEditorOpen && lastCommentEditorOpen() === questionIndex

    if (
      isCommentEditorOpenForQuestion &&
      !isCommentEditorOpenForQuestion(questionIndex)
    ) {
      return undefined
    }

    const value = dataSource.getCommentForAnswer(questionIndex)

    return (
      <tr>
        <td className=""></td>
        <td colSpan={100} className="py-5">
          {quizMode === QuizMode.Review ? (
            <CommentReview text={value} />
          ) : (
            <Comment
              autoFocus={autoFocus}
              value={value}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                dataSource.setCommentForAnswer(
                  questionIndex,
                  e.currentTarget.value
                )
              }
            />
          )}
        </td>
      </tr>
    )
  }

  renderTableQuestionRowsForQuestion = (
    quizMode: QuizMode,
    cells: string[],
    questionIndex: number
  ) => {
    const {
      commentTriggerForQuestion,
      onMouseOverQuestion,
      onMouseLeftQuestion,
    } = this.props

    const hasHeader = questionIndex === 0 && this.props.quiz.header

    return (
      <tbody key={`tbody-${questionIndex}`}>
        <tr
          key={`tr-${questionIndex}`}
          className={c('hover:bg-gray-200', {
            'bg-lightgreen': hasHeader,
          })}
          onMouseOver={() =>
            onMouseOverQuestion && onMouseOverQuestion(questionIndex)
          }
          onMouseLeave={() =>
            onMouseLeftQuestion && onMouseLeftQuestion(questionIndex)
          }
        >
          <td className={c('p-2', { 'py-8': hasHeader })}>
            {commentTriggerForQuestion &&
              commentTriggerForQuestion(questionIndex)}
          </td>

          {cells.map((questionSentence: string, index: number) =>
            this.renderFillOutTableCell(
              quizMode,
              questionIndex,
              index,
              questionSentence
            )
          )}
        </tr>

        {this.renderCommentEditorIfNeeded(quizMode, questionIndex)}
      </tbody>
    )
  }

  renderFillOutTableCell = (
    quizMode: QuizMode,
    questionIndex: number,
    inputIndex: number,
    questionSentence: any
  ) => {
    const { dataSource } = this.state
    const {
      getAnswerValue,
      setAnswerValue,
      isAnswerEvaluated,
      isAnswerCorrect,
      setAnswerEvaluation,
    } = dataSource

    const key = `input-${questionIndex}-${inputIndex}`

    const columnWidth = this.props.quiz.columnClasses
      ? this.props.quiz.columnClasses[inputIndex]
      : ''

    if (questionSentence === '....') {
      // user needs to fill it out
      if (quizMode === QuizMode.Answer) {
        return (
          <td key={key} className={c(`p-2 ${columnWidth}`)}>
            <InputField
              questionIndex={questionIndex}
              inputIndex={inputIndex}
              setValue={(value: string) =>
                setAnswerValue(questionIndex, inputIndex, value)
              }
              className={
                this.props.quiz.inputWidth
                  ? `w-${this.props.quiz.inputWidth}`
                  : 'w-100'
              }
              fullWidth={this.props.quiz.fullWidth}
            />
          </td>
        )
      } else if (quizMode === QuizMode.Evaluation) {
        return (
          <td key={key} className={c(columnWidth)}>
            <Answer
              content={getAnswerValue(questionIndex, inputIndex)}
              evaluated={isAnswerEvaluated(questionIndex, inputIndex)}
              correct={isAnswerCorrect(questionIndex, inputIndex)}
              onEvaluate={(correct: boolean) =>
                setAnswerEvaluation(questionIndex, inputIndex, correct)
              }
            />
          </td>
        )
      } else if (quizMode === QuizMode.Review) {
        return (
          <td key={key} className={c(columnWidth)}>
            <Answer
              disabled
              content={getAnswerValue(questionIndex, inputIndex)}
              evaluated={isAnswerEvaluated(questionIndex, inputIndex)}
              correct={isAnswerCorrect(questionIndex, inputIndex)}
            />
          </td>
        )
      }
    } else {
      return (
        <td key={key} className={c(columnWidth)}>
          <Markdown children={questionSentence} />
        </td>
      )
    }

    return
  }

  renderTableForQuestions = (quizMode: QuizMode, questions: string[]) => (
    <table className="w-full">
      {questions.map((question: string, questionIndex: number) => {
        const cells = question.split(/\|/g).map((s) => s.trim())

        return this.renderTableQuestionRowsForQuestion(
          quizMode,
          cells,
          questionIndex
        )
      })}
    </table>
  )

  render() {
    const { quiz, mode } = this.props
    const { questions } = quiz
    const { notes } = this.props
    const { dataSource } = this.state
    const { setNotes } = dataSource

    return (
      <QuizWrapper>
        <QuizInstruction>{quiz.instruction}</QuizInstruction>
        <div className="my-5">
          {mode === QuizMode.Evaluation && (
            <Comment
              label="General notes to the student"
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setNotes && setNotes(e.currentTarget.value)
              }
            />
          )}

          {mode === QuizMode.Review && (
            <CommentReview general={true} text={notes} />
          )}
        </div>
        <QuizContainer className="w-full flex flex-row justify-start">
          {this.renderTableForQuestions(mode, questions)}
        </QuizContainer>
      </QuizWrapper>
    )
  }
}

export default FillOutSentencesInTable
