import React, { Component } from 'react'

const emojiChangeDelay = 0.2
const emojiChangeDuration = 0.1

class EmojiLoading extends Component<any, any> {
  emojis = [
    '🇲🇽',
    '🇨🇴',
    '🇪🇸',
    '🇦🇷',
    '🇵🇪',
    '🇻🇪',
    '🇨🇱',
    '🇪🇨',
    '🇬🇹',
    '🇨🇺',
    '🇧🇴',
    '🇩🇴',
    '🇭🇳',
    '🇵🇾',
    '🇸🇻',
    '🇳🇮',
    '🇨🇷',
    '🇵🇦',
    '🇺🇾',
    '🇬🇶',
    '🇵🇷'
  ]

  constructor(props: {}) {
    super(props)

    this.state = {
      currentEmoji: null,
      nextEmoji: null,
      timer: null,
      currentEmojiOpacity: 1,
      nextEmojiOpacity: 0,
    }
  }

  componentDidMount() {
    this.tick()
  }

  componentWillUnmount() {
    clearTimeout(this.state.timer)
    this.setState({ timer: null })
  }

  tick = () => {
    this.swapEmojis()
    this.setState({ timer: setTimeout(this.tick, emojiChangeDelay * 1000) })
  }

  randomOtherEmoji() {
    let emojiId
    let emoji

    do {
      emojiId = Math.floor(Math.random() * 100) % this.emojis.length
      emoji = this.emojis[emojiId]
    } while (emoji === this.state.currentEmoji)

    return emoji
  }

  swapEmojis() {
    if (this.state.currentEmojiOpacity === 0) {
      // current emoji is fully visible
      this.setState({
        currentEmoji: this.randomOtherEmoji(),
        currentEmojiOpacity: 1,
        nextEmojiOpacity: 0,
      })
    } else {
      // next emoji is fully visible
      this.setState({
        nextEmoji: this.randomOtherEmoji(),
        currentEmojiOpacity: 0,
        nextEmojiOpacity: 1,
      })
    }
  }

  render() {
    return (
      <div className="w-100 h-100 text-4xl relative">
        <div
          id="logo-image-current"
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            transition: `opacity ${emojiChangeDuration}s ease-in-out`,
            opacity: this.state.currentEmojiOpacity

          }}
        >
          {this.state.currentEmoji}
        </div>
        <div
          id="logo-image-next"
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            transition: `opacity ${emojiChangeDuration}s ease-in-out`,
            opacity: this.state.nextEmojiOpacity

          }}
        >
          {this.state.nextEmoji}
        </div>
      </div>
    )
  }
}

export default EmojiLoading
