import FillOutWordsInSentences from './FillOutWordsInSentences'
import FillOutSentencesInTable from './FillOutSentencesInTable'
import ConnectWordsInTwoColumns from './ConnectWordsInTwoColumns'
import AssignWordsToGroups from './AssignWordsToGroups'
import Crossword from './Crossword'
import PickOneWord from './PickOneWord'
import WriteText from './WriteText'
import FillOutSingleChoiceAnswerInSentences from './FillOutSingleChoiceAnswerInSentences'

import { Quiz } from '../client'

export enum QuizMode {
  Answer = 1,
  Evaluation,
  Review,
}

export const QuizComponentsByType: { [key: string]: any } = {
  fill_out_words_in_sentences: FillOutWordsInSentences,
  fill_out_table: FillOutSentencesInTable,
  connect_words_in_two_columns: ConnectWordsInTwoColumns,
  assign_words_to_groups: AssignWordsToGroups,
  fill_out_single_choice_answer_in_sentence: FillOutSingleChoiceAnswerInSentences,
  crossword: Crossword,
  pick_one_word: PickOneWord,
  write_text: WriteText,
}

export interface QuizComponentProps {
  quiz: Quiz
  mode: QuizMode
  answers?: object
  evaluations?: object
  comments?: (undefined | string)[]
  onEvaluate?: Function
  isAnswerCorrect?: Function
  isAnswerEvaluated?: Function
  setNotes?: Function
  getnotes?: Function
  commentEditorForQuestion?: Function
  isCommentEditorOpenForQuestion?: Function
  lastCommentEditorOpen?: Function
  commentTriggerForQuestion?: Function
  onMouseOverQuestion?: Function
  onMouseLeftQuestion?: Function
  notes?: string
  onAnswersUpdate?: Function
  onEvaluationsUpdate?: Function
  onCommentsUpdate?: Function
  onNotesUpdate?: Function
}

export class FillInGapsDataSource {
  answers: string[][]
  evaluations?: (undefined | boolean)[][]
  comments?: (undefined | string)[]
  notes?: string

  onAnswersUpdate?: Function
  onEvaluationsUpdate?: Function
  onCommentsUpdate?: Function
  onNotesUpdate?: Function

  constructor(
    answers?: any,
    evaluations?: any,
    comments?: (undefined | string)[],
    notes?: string
  ) {
    this.answers = (answers as string[][]) || []
    this.evaluations = (evaluations as boolean[][]) || []
    this.comments = (comments as string[]) || []
    this.notes = notes
  }

  getAnswerValue = (questionIndex: number, inputIndex: number) => {
    const { answers } = this

    if (answers) {
      const answer = answers[questionIndex]

      if (answer) {
        return answer[inputIndex]
      }
    }

    return undefined
  }

  setAnswerValue = (
    questionIndex: number,
    inputIndex: number,
    value: string
  ) => {
    const { answers, onAnswersUpdate } = this

    if (!answers[questionIndex]) {
      this.answers[questionIndex] = []
    }

    this.answers[questionIndex][inputIndex] = value

    onAnswersUpdate && onAnswersUpdate(answers)
  }

  getAnswerEvaluation = (
    questionIndex: number,
    inputIndex: number
  ): boolean | undefined => {
    const { evaluations } = this

    if (evaluations) {
      const evaluation = evaluations[questionIndex]

      if (evaluation === undefined || evaluation === null) {
        return undefined
      }

      return evaluation[inputIndex]
    }

    return undefined
  }

  setAnswerEvaluation = (
    questionIndex: number,
    inputIndex: number,
    correct: boolean
  ) => {
    if (!this.evaluations) {
      this.evaluations = []
    }

    if (!this.evaluations[questionIndex]) {
      this.evaluations[questionIndex] = []
    }

    this.evaluations[questionIndex][inputIndex] = correct

    this.onEvaluationsUpdate && this.onEvaluationsUpdate(this.evaluations)
  }

  isAnswerCorrect = (
    questionIndex: number,
    inputIndex: number
  ): boolean | undefined => {
    return this.getAnswerEvaluation(questionIndex, inputIndex)
  }

  isAnswerEvaluated = (questionIndex: number, inputIndex: number) => {
    const evaluation = this.getAnswerEvaluation(questionIndex, inputIndex)

    return evaluation !== undefined
  }

  setNotes = (notes: string) => {
    this.notes = notes

    const { onNotesUpdate } = this
    onNotesUpdate && onNotesUpdate(notes)
  }

  setCommentForAnswer = (questionIndex: number, comment: string) => {
    const { onCommentsUpdate } = this

    if (!this.comments) {
      this.comments = []
    }

    this.comments[questionIndex] = comment
    onCommentsUpdate && onCommentsUpdate(this.comments)
  }

  getCommentForAnswer = (questionIndex: number) => {
    if (!this.comments) {
      return undefined
    }

    return this.comments[questionIndex]
  }
}

export class WriteTextDataSource {
  answers: string
  evaluations?: boolean
  notes?: string

  onAnswersUpdate?: Function
  onEvaluationsUpdate?: Function
  onNotesUpdate?: Function

  constructor(answers?: any, evaluations?: any, notes?: string) {
    this.answers = answers as string
    this.evaluations = evaluations as boolean
    this.notes = notes
  }

  getAnswerValue = () => {
    const { answers } = this
    return answers
  }

  setAnswerValue = (value: string) => {
    this.answers = value

    this.onAnswersUpdate && this.onAnswersUpdate(this.answers)
  }

  getAnswerEvaluation = (): boolean | undefined => {
    return this.evaluations
  }

  setAnswerEvaluation = (correct: boolean) => {
    this.evaluations = correct
    this.onEvaluationsUpdate && this.onEvaluationsUpdate(this.evaluations)
  }

  isAnswerCorrect = (): boolean | undefined => {
    return this.getAnswerEvaluation()
  }

  isAnswerEvaluated = () => {
    const evaluation = this.getAnswerEvaluation()

    return evaluation !== undefined
  }

  getNotes = () => {
    return this.notes
  }

  setNotes = (notes: string) => {
    this.notes = notes

    const { onNotesUpdate } = this
    onNotesUpdate && onNotesUpdate(notes)
  }

  setCommentForAnswer = (questionIndex: number, comment: string) => {}

  getCommentForAnswer = (questionIndex: number) => {}
}

export class MatchTwoIndexesDataSource {
  answers: (undefined | number)[]
  evaluations: (undefined | boolean)[]
  comments: (undefined | string)[]
  notes?: string

  onAnswersUpdate?: Function
  onEvaluationsUpdate?: Function
  onCommentsUpdate?: Function
  onNotesUpdate?: Function

  constructor(
    answers?: any,
    evaluations?: any,
    comments?: (undefined | string)[],
    notes?: string
  ) {
    this.answers = (answers as (undefined | number)[]) || []
    this.evaluations = (evaluations as boolean[]) || []
    this.comments = (comments as string[]) || []
    this.notes = notes
  }

  unsetAnswersWithValue = (value: number) =>
    (this.answers = this.answers.map((v) => (v === value ? undefined : v)))

  getAnswerValue = (questionIndex: number) => {
    const { answers } = this

    return answers[questionIndex]
  }

  getStringAnswerValue = (questionIndex: number) => {
    const { answers } = this

    return answers[questionIndex]?.toString()
  }

  setAnswerValue = (questionIndex: number, value: number) => {
    this.answers[questionIndex] = value

    this.onAnswersUpdate && this.onAnswersUpdate(this.answers)
  }

  getAnswerEvaluation = (questionIndex: number): boolean | undefined => {
    return this.evaluations[questionIndex] === true
  }

  setAnswerEvaluation = (questionIndex: number, correct: boolean) => {
    this.evaluations[questionIndex] = correct

    this.onEvaluationsUpdate && this.onEvaluationsUpdate(this.evaluations)
  }

  isAnswerCorrect = (questionIndex: number): boolean | undefined => {
    return this.getAnswerEvaluation(questionIndex)
  }

  isAnswerEvaluated = (questionIndex: number) => {
    const value = this.evaluations[questionIndex]
    return value === true || value === false
  }

  setNotes = (notes: string) => {
    this.notes = notes

    const { onNotesUpdate } = this
    onNotesUpdate && onNotesUpdate(notes)
  }

  setCommentForAnswer = (questionIndex: number, comment: string) => {
    const { onCommentsUpdate } = this

    if (!this.comments) {
      this.comments = []
    }

    this.comments[questionIndex] = comment
    onCommentsUpdate && onCommentsUpdate(this.comments)
  }

  getCommentForAnswer = (questionIndex: number) => {
    if (!this.comments) {
      return undefined
    }

    return this.comments[questionIndex]
  }
}
