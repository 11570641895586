import React, { useState } from 'react'
import { auth } from './firebase'

const PasswordReset = () => {
  const [email, setEmail] = useState('')
  const [emailHasBeenSent, setEmailHasBeenSent] = useState(false)
  const [error, setError] = useState('')

  const onChangeHandler = (event: any) => {
    const { name, value } = event.currentTarget
    if (name === 'userEmail') {
      setEmail(value)
    }
  }

  const sendResetEmail = (event: any) => {
    event.preventDefault()
    auth
      .sendPasswordResetEmail(email)
      .then(() => {
        setEmailHasBeenSent(true)
        setTimeout(() => {
          setEmailHasBeenSent(false)
        }, 5000)
      })
      .catch(() => {
        setError('Error resetting password')
      })
  }

  return (
    <div>
      {emailHasBeenSent && (
        <div className="w-full flex flex-row justify-center">
          <div className="w-full md:w-1/2 text-center rounded-sm py-2 bg-sadgreen text-white">
            Looking up your e-mail and sending password reset instructions now!
          </div>
        </div>
      )}

      <div className="bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md mb-6">
          <h2 className="text-center text-3xl leading-9 font-extrabold text-gray-900">
            Reset your password
          </h2>
        </div>
        {error && (
          <div className="sm:mx-auto sm:w-full sm:max-w-md mb-6 bg-red-600 rounded py-2">
            <h3 className="text-center text-l leading-9 text-white">{error}</h3>
          </div>
        )}
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form action="#" method="POST">
              <div className="mt-6">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-5 text-gray-700"
                >
                  Email address
                </label>
                <div className="mt-1 rounded-md shadow-sm">
                  <input
                    id="userEmail"
                    name="userEmail"
                    type="email"
                    value={email}
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    onChange={(event) => onChangeHandler(event)}
                  />
                </div>
              </div>
              <div className="mt-6">
                <span className="block w-full rounded-md shadow-sm">
                  <button
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                    onClick={sendResetEmail}
                  >
                    Send Password Reset Instructions
                  </button>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )

  // <div className="mt-8">
  //   <h1 className="text-xl text-center font-bold mb-3">
  //     Reset your Password
  //   </h1>
  //   <div className="border border-blue-300 mx-auto w-11/12 md:w-2/4 rounded py-8 px-4 md:px-8">
  //     <form action="">
  //       {emailHasBeenSent && (
  //         <div className="py-3 bg-green-400 w-full text-white text-center mb-3">
  //           An email has been sent to you!
  //         </div>
  //       )}
  //       {error !== null && (
  //         <div className="py-3 bg-red-600 w-full text-white text-center mb-3">
  //           {error}
  //         </div>
  //       )}
  //       <label htmlFor="userEmail" className="w-full block">
  //         Email:
  //       </label>
  //       <input
  //         type="email"
  //         name="userEmail"
  //         id="userEmail"
  //         value={email}
  //         placeholder="Input your email"
  //         onChange={onChangeHandler}
  //         className="mb-3 w-full px-1 py-2"
  //       />
  //       <button
  //         className="w-full bg-blue-400 text-white py-3"
  //         onClick={sendResetEmail}
  //       >
  //         Send me a reset link
  //       </button>
  //     </form>
  //     <Link
  //       to="/"
  //       className="my-2 text-blue-700 hover:text-blue-800 text-center block"
  //     >
  //       &larr; back to sign in page
  //     </Link>
  //   </div>
  // </div>
  // )
}
export default PasswordReset
