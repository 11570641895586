import React, { useContext } from 'react'
import { UserContext } from './providers/UserProvider'
// import Profile from './Profile'
import PasswordReset from './PasswordReset'
import SignUp from './SignUp'
// import { auth } from './firebase'
import SignIn from './SignIn'
import SignOut from './SignOut'
// import MeetCam from './MeetCam'
import Responses from './Responses'
import EmojiLoading from './EmojiLoading'
import { isUserNoRole, isUserAdmin } from './Auth'
// import firebase from 'firebase'

import {
  BrowserRouter as Router,
  Redirect,
  Switch,
  Route,
  useLocation,
  useParams,
} from 'react-router-dom'

import './assets/main.css'

import QuizEvaluationComponent from './QuizEvaluationComponent'
import QuizResponseComponent from './QuizResponseComponent'
import QuizReviewComponent from './QuizReviewComponent'

import Navigation from './Navigation'
import Chapter from './Chapter'
import Admin from './Admin'

import classnames from 'classnames'

const c = classnames

function RenderQuiz() {
  const params = useParams() as any
  const quizID: string = params['quizID']

  return <QuizResponseComponent quizID={quizID} />
}

function RenderEvaluation() {
  const params = useParams() as any
  const responseID: string = params['responseID']

  return <QuizEvaluationComponent responseID={responseID} />
}

function RenderReview() {
  const params = useParams() as any
  const evaluationID: string = params['evaluationID']

  return <QuizReviewComponent evaluationID={evaluationID} />
}

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const LoggedOut = () => {
  const query = useQuery()

  return (
    <div>
      <Logo />
      <Switch>
        <Route path="/signIn" children={<SignIn />} />
        <Route path="/signUp">
          <SignUp email={query.get('email')} />
        </Route>
        <Route path="/passwordReset" children={<PasswordReset />} />
        <Route path="/" children={<SignIn />} />
      </Switch>
    </div>
  )
}

const EmailConfirmationRequiredNotice = () => (
  <div>
    You need to confirm your e-mail address first - check your e-mail (including
    spam folder) and click the activation button.
  </div>
)

const renderNoRole = (props: { user: any }) => {
  return (
    <div>
      <Logo />
      <div className="w-full flex flex-row justify-center">
        <div className="w-full md:w-1/2 text-center rounded-sm py-2 bg-sadgreen text-white">
          Your account <span className="underline">{props.user.email}</span>{' '}
          does not have access to content.
          <div>
            Please sign in to another account below or{' '}
            <a
              href="http://www.espanoldeotroplaneta.com"
              className="font-bold underline px-2"
            >
              purchase the course
            </a>
            first.
          </div>
        </div>
      </div>
      <SignIn />
    </div>
  )
}

const LoggedIn = (props: { user: any }) => {
  if (props.user.emailVerified !== true) {
    return EmailConfirmationRequiredNotice()
  }

  return (
    <div className="app flex flex-col">
      {/* <Profile /> */}
      {isUserNoRole(props.user) ? (
        renderNoRole({ ...props })
      ) : (
        <Navigation>
          <Switch>
            <Route path="/responses" component={Responses} />
            <Route path="/respond/:quizID" children={<RenderQuiz />} />

            <Route
              path="/evaluate/:responseID"
              children={<RenderEvaluation />}
            />
            <Route path="/review/:evaluationID" children={<RenderReview />} />

            {isUserAdmin(props.user) && (
              <Route path="/admin/" component={Admin} />
            )}

            <Route path="/chapters/:chapterID" children={<RenderChapter />} />

            <Route path="/">
              <Redirect to="/chapters/1" />
            </Route>
          </Switch>
        </Navigation>
      )}
    </div>
  )
}

function RenderChapter() {
  const params = useParams() as any
  const chapterID: string = params['chapterID']
  // console.log('PARAMS:', params)

  return <Chapter id={chapterID} />
}

const Logo = () => (
  <div className={c('flex flex-row max-w-full border-blue-600 justify-center')}>
    <div className={c('')}>
      <a href="https://www.espanoldeotroplaneta.com">
        <img
          id="logo"
          src="/images/logo-black.png"
          className={c('h-40')}
          alt="Español de Otro Planeta Logo"
        />
      </a>
    </div>
  </div>
)

function App() {
  const userContext: any = useContext(UserContext)

  const { afterAuth, user } = userContext

  console.log(
    'App#render afterAuth',
    afterAuth,
    'user',
    user ? 'yes' : 'no',
    'emailVerified',
    user ? user.emailVerified : '<none>',
    'role',
    user ? user.role : '<none>'
  )

  return (
    <Router>
      {!afterAuth ? (
        <div className="w-full h-full text-center align-middle">
          <EmojiLoading />
        </div>
      ) : (
        <Switch>
          {/* <Route path="/meetcam" component={MeetCam} /> */}
          <Route path="/signOut" component={SignOut} />
          {user && user.emailVerified ? (
            <LoggedIn user={user} />
          ) : (
            <LoggedOut />
          )}
        </Switch>
      )}
    </Router>
  )
}

export default App
