import React from 'react'
import { SectionHeader, SectionType } from './components/SectionHeader'
import MainImage from './components/MainImage'
import ChapterHeader from './components/ChapterHeader'
import QuizSection from './components/QuizSection'
import QuizResponseComponent from '../QuizResponseComponent'

const Chapter = () => {
  return (
    <div>
      <ChapterHeader text="Lección 12" />

      <SectionHeader type={SectionType.Review} text="REPASO 1" />

      <QuizSection>
        <QuizResponseComponent
          quizID={'ce07e33f-1b8c-40a3-baca-f0d334222a31'}
        />
      </QuizSection>

      <SectionHeader type={SectionType.Review} text="REPASO 2" />

      <QuizSection>
        <QuizResponseComponent
          quizID={'69859530-64ec-4629-9fa5-3799756267d5'}
        />
      </QuizSection>

      <SectionHeader type={SectionType.Review} text="REPASO 3" />

      <MainImage src="/images/027.svg" alt="027" />

      <QuizSection>
        <QuizResponseComponent
          quizID={'66955544-3da7-454a-88d5-815957fccbfa'}
        />
      </QuizSection>
    </div>
  )
}

export default Chapter
