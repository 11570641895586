import React from 'react'
import { Section } from './Utils'
import c from 'classnames'

const BlockContent = require('@sanity/block-content-to-react')

interface NoteProps {
  body: string
}

const serializers = {
  types: {
    block: (props: any) => {
      const { style = 'normal' } = props.node

      if (/^h\d/.test(style)) {
        const level = style.replace(/[^\d]/g, '')
        return React.createElement(
          style,
          { className: c({ 'font-bold my-3': level === '1' }) },
          props.children
        )
      }

      // Fall back to default handling
      return BlockContent.defaultSerializers.types.block(props)
    },
  },
}

const Note = ({ body }: NoteProps) => (
  <Section>
    <div className="w-full text-white p-4 bg-darkpurple rounded-b-lg font-thin">
      <div className="font-bold pb-3 text-2xl">¡Observa!</div>
      <BlockContent blocks={body} serializers={serializers} />
    </div>
  </Section>
)

export default Note
