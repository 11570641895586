import React from 'react'
import classnames from 'classnames'

const c = classnames

export const Section = (props: any) => (
  <div
    className={c(
      'sm:pl-3 sm:px-6 lg:px-8 sm:text-xl',
      'z-10',
      props.className ? props.className : ''
    )}
  >
    {props.title && (
      <div className={'text-center font-bold xl:text-4xl sm:text-2xl'}>
        {props.title}
      </div>
    )}
    <div className="w-full flex flex-row justify-start">
      <div className={'hidden sm:block sm:w-10 lg:w-1/4'}></div>
      <div
        className={
          'flex mx-1 sm:ml-0 my-4 flex-col justify-start sm:flex-row sm:justify-center w-full'
        }
      >
        {props.children}
      </div>
    </div>
  </div>
)

export const SectionSymbol = (props: any) => (
  <div
    className={c(
      'mr-8 min-w-1/5 text-right h-full w-1/5 text-4xl text-blue-600',
      props.className
    )}
  >
    {props.children}
  </div>
)

export const TableHeader = (cells: string[]) => {
  return (
    <thead>
      <tr className={c('bg-lightpurple text-darkpurple font-bold')}>
        {cells.map((cell, index) => (
          <th className={c('p-4', 'xl:text-3xl sm:text-xl')} key={index}>
            {cell}
          </th>
        ))}
      </tr>
    </thead>
  )
}

export const TableSection = (props: any) => (
  <div className={'xl:flex mb-16 pt-20 sm:text-xl w-full'}>
    <div className={'xl:w-1/5 hidden sm:hidden lg:visible'}></div>
    <div className={'xl:w-full sm:w-full sm:px-2 w-full flex justify-center'}>
      {props.children}
    </div>
  </div>
)

export const Table = (props: any) => (
  <table
    className={c(
      'md:font-light sm:w-full lg:w-3/4 table-auto border',
      props.className
    )}
  >
    {props.children}
  </table>
)

export const TableCell = (props: any) => (
  <td {...props} className={c('p-4', '', props.className)}>
    {props.children}
  </td>
)

export const BoldTableContent = (props: any) => (
  <div className="font-bold text-dirtygray text-right">{props.children}</div>
)

export const QuizWrapper = (props: any) => (
  <div {...props} className={c('w-full', props.className)}>
    {props.children}
  </div>
)

export const QuizInstruction = (props: any) => (
  <div className={'my-5 xl:text-3xl sm:text-xl font-bold'}>
    {props.children}
  </div>
)

export const SectionInstruction = (props: any) => (
  <div {...props} className={c('w-full', props.className)}>
    <div className={'my-5 xl:text-3xl sm:text-xl font-bold'}>{props.text}</div>
  </div>
)

export const QuizContainer = (props: any) => (
  <div className={c('w-full flex flex-col justify-start', props.className)}>
    {props.children}
  </div>
)

export const USO = (props: any) => {
  return (
    <div>
      <h2 className="text-2xl sm:text-4xl font-light w-full text-center">
        USO
      </h2>

      <Section>
        <div className="flex flex-col">
          {props.uses.map((use: string[], useIndex: number) => {
            const title = use[0]
            const example = use[1]

            return (
              <div key={useIndex}>
                <div className="font-bold text-xl mt-4 sm:text-2xl">
                  {title}
                </div>
                <div className="ml-4 text-xl sm:text-2xl italic">{example}</div>
              </div>
            )
          })}
        </div>
      </Section>
    </div>
  )
}
