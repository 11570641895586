import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

const c = classnames

export enum SectionType {
  Dialog = 'dialog',
  Grammar = 'grammar',
  Practice = 'practice',
  Phonetics = 'phonetics',
  Review = 'review',
  Vocabulary = 'vocabulary',
}

const SectionTypeBackgroundColors = {
  dialog: 'semilightblue',
  grammar: 'darkpurple',
  practice: 'sadgreen',
  phonetics: 'ochra',
  review: 'gray-800',
  vocabulary: 'darkyellow',
}

const SectionTypeSymbols = {
  dialog: '👀',
  grammar: '¡!',
  practice: '✍🏼',
  phonetics: '👂',
  review: '🤺',
  vocabulary: '🔖',
}

interface SectionHeaderProps {
  text: string
  type: SectionType
}

export const SectionHeader = ({ text, type }: SectionHeaderProps) => {
  const sectionAnchorIDForHeader = text
    .toLowerCase()
    .trim()
    .replace(/[^a-zA-Z0-9\u00C0-\u017F]/, '-')

  const headerRef = useRef(null)

  useEffect(() => {
    const hash = window.location.hash
    const hashValue = decodeURIComponent(hash.replace(/^#/, ''))

    if (sectionAnchorIDForHeader === hashValue) {
      const {
        // @ts-ignore
        current: { offsetTop },
      } = headerRef

      if (headerRef && headerRef.current !== null) {
        let scrollToTop
        scrollToTop = offsetTop + window.screen.availHeight * 0.85

        // @ts-ignore
        window.scrollBy({
          behavior: 'smooth',
          top: scrollToTop - window.scrollY,
        })
      }
    }
  })

  return (
    <div
      className={c(
        'uppercase',
        'left-0',
        `bg-${SectionTypeBackgroundColors[type]}`,
        'text-white',
        'rounded-br-lg',
        'py-1',
        'text-right',
        'pr-4',
        'w-1/2',
        'sm:w-1/2',
        'sm:text-2xl',
        'font-bold',
        'xl:text-4xl',
        'inline-block',
        'mb-10',
        'mt-12',
        'sm:mt-20',
        'sm:mb-20',
        'block'
      )}
    >
      <h3 id={sectionAnchorIDForHeader} ref={headerRef}>
        <Link
          to={{
            // @ts-ignore
            pathname: window.location.pathname,
            hash: sectionAnchorIDForHeader,
          }}
        >
          {SectionTypeSymbols[type]} {text}
        </Link>
      </h3>
    </div>
  )
}

export default SectionHeader
