import React, { useContext, useState } from 'react'
import { UserContext } from './providers/UserProvider'
import Confetti from 'react-dom-confetti'
import { Quiz } from './client'
import firebase from 'firebase/app'

interface Props {
  quiz: Quiz
  answers?: object
}

const createResponse = firebase.functions().httpsCallable('createResponse')

const SubmitAnswer = (props: Props) => {
  const userContext = useContext(UserContext)
  const { user } = userContext

  const [studentEmail] = useState(user.email)
  const [teacherEmail, setTeacherEmail] = useState(user.defaultTeacherEmail)
  const [submitting, setSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [cancelled, setCancelled] = useState(false)

  const onSubmit = async (event: any) => {
    const { quiz, answers } = props

    event.preventDefault()

    setSubmitting(true)

    await createResponse({
      quiz,
      answers: answers || [],
      studentEmail,
      teacherEmail,
    })

    setSubmitting(false)
    setSubmitted(true)
  }

  if (cancelled) {
    return <div></div>
  }

  return (
    <div className="w-full">
      {submitted ? (
        <div className="bg-sadgreen p-4 text-white text-center mt-6 xl:w-1/2 sm:w-full">
          <h1>Thanks for submitting your response!</h1>

          <h2>We will send you an e-mail when it's evaluated.</h2>
        </div>
      ) : (
          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              {/*<!--
              Background overlay, show/hide based on modal state.
        
              Entering: "ease-out duration-300"
                From: "opacity-0"
                To: "opacity-100"
              Leaving: "ease-in duration-200"
                From: "opacity-100"
                To: "opacity-0"
            -->*/}
              <div className="fixed inset-0 transition-opacity">
                <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
              </div>
              {/*<!-- This element is to trick the browser into centering the modal contents. -->*/}
              <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
            &#8203;
            {/*<!--
              Modal panel, show/hide based on modal state.
        
              Entering: "ease-out duration-300"
                From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                To: "opacity-100 translate-y-0 sm:scale-100"
              Leaving: "ease-in duration-200"
                From: "opacity-100 translate-y-0 sm:scale-100"
                To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            -->*/}
              <div
                className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
                role="dialog"
                aria-modal="true"
                aria-labelledby="modal-headline"
              >
                <div>
                  <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-gray-100">
                    <span role="img" aria-label="envelope">
                      ✉️
                  </span>
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <h3
                      className="text-lg leading-6 font-medium text-gray-900"
                      id="modal-headline"
                    >
                      Send your response for evaluation
                  </h3>
                    <div className="mt-5">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium leading-5 text-gray-700 text-left"
                      >
                        Your teacher's email
                    </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <svg
                            className="h-5 w-5 text-gray-400"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                            <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                          </svg>
                        </div>
                        <input
                          id="email"
                          className="form-input block w-full pl-10 sm:text-sm sm:leading-5"
                          placeholder="your@teacher.edu"
                          defaultValue={user.defaultTeacherEmail}
                          onChange={(e) => setTeacherEmail(e.currentTarget.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                    <span className="flex w-full rounded-md shadow-sm sm:col-start-2">
                      <button
                        type="button"
                        className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                        disabled={submitting}
                        onClick={onSubmit}
                      >
                        {submitting ? 'Sending...' : 'Send'}
                      </button>
                    </span>
                    <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1">
                      <button
                        type="button"
                        className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                        onClick={() => setCancelled(true)}
                      >
                        Cancel
                    </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

      <Confetti active={submitted} />
    </div>
  )
}

export default SubmitAnswer
