import React, { useContext } from 'react'
import { UserContext } from './providers/UserProvider'
import { hasUserAccessToChapter } from './Auth'

import Chapter1 from './chapters/Chapter1'
import Chapter2 from './chapters/Chapter2'
import Chapter3 from './chapters/Chapter3'
import Chapter4 from './chapters/Chapter4'
import Chapter5 from './chapters/Chapter5'
import Chapter6 from './chapters/Chapter6'
import Chapter7 from './chapters/Chapter7'
import Chapter8 from './chapters/Chapter8'
import Chapter9 from './chapters/Chapter9'
import Chapter10 from './chapters/Chapter10'
import Chapter11 from './chapters/Chapter11'
import Chapter12 from './chapters/Chapter12'
import BackendChapter from './chapters/Backend'

const CHAPTERS: { [key: string]: any } = {
  '1': Chapter1,
  '2': Chapter2,
  '3': Chapter3,
  '4': Chapter4,
  '5': Chapter5,
  '6': Chapter6,
  '7': Chapter7,
  '8': Chapter8,
  '9': Chapter9,
  '10': Chapter10,
  '11': Chapter11,
  '12': Chapter12,
}

interface ChapterProps {
  id: string
}

const Chapter = ({ id }: ChapterProps) => {
  const chapterComponent = CHAPTERS[id] || BackendChapter
  const userContext: any = useContext(UserContext)
  const user = userContext.user

  if (!hasUserAccessToChapter(user, (id as unknown) as number)) {
    return <div>Unfortunately you don't have access to this chapter.</div>
  }

  return (
    <div className="container">
      {chapterComponent &&
        React.createElement(chapterComponent, { chapterID: id })}

      <div className="bg-white">
        <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
          <div className="flex justify-center md:order-2"></div>
          <div className="mt-8 md:mt-0 md:order-1">
            <p className="text-center text-base leading-6 text-gray-400">
              &copy; 2016-
              {new Date().getFullYear()} Katarzyna Hajost-Żak, Sylwia Jakubas.
              All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Chapter
