import React from 'react'
import classnames from 'classnames'
import { Section } from './Utils'

const c = classnames

interface DialogLine {
  text?: string
  speaker?: string
  separator?: boolean
}

interface DialogProps {
  lines: DialogLine[]
  title?: string
}

const Dialog = ({ lines, title }: DialogProps) => (
  <Section title={title}>
    <div className="flex flex-row justify-center sm:w-full xl:w-1/2">
      <div className="max-w-full w-full">
        <table className="w-full">
          <tbody>
            {lines.map((line, index) => {
              if (line.separator) {
                return (
                  <tr key={index}>
                    <td className={'text-right align-text-top pt-4'}></td>
                    <td className={'text-left pt-4'}>
                      <span className={c('font-bold')}>...</span>
                    </td>
                  </tr>
                )
              } else {
                return (
                  <tr key={index}>
                    <td
                      className={
                        'text-right align-text-top pt-4 whitespace-no-wrap'
                      }
                    >
                      <span className={c('mr-1 font-bold')}>
                        {line.speaker}:
                      </span>
                    </td>
                    <td className={'text-left align-text-top pt-4'}>
                      <span className={c('')}>{line.text}</span>
                    </td>
                  </tr>
                )
              }
            })}
          </tbody>
        </table>
      </div>
    </div>
  </Section>
)

export default Dialog
