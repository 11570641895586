import React from 'react'
import Markdown from 'react-markdown/with-html'
import { Section } from './Utils'
import c from 'classnames'

interface GrammarTableProps {
  data: any
  format: any
  emphasisStyle: string
  borderless?: boolean
  nonEqualColumns?: boolean
}

const renderTable = (
  data: any,
  format: any,
  emphasisStyle: string,
  borderless?: boolean,
  nonEqualColumns?: boolean
) => {
  const columns = Math.max(...data.rows.map((row: any) => row.cells.length))
  const columnsClasses = nonEqualColumns ? '' : `grid-cols-${columns}`

  return (
    <div
      className={c(
        `grid grid-rows-${data.rows.length} gap-0 w-full`,
        columnsClasses
      )}
    >
      {data.rows.map((row: any, rowIndex: number) => {
        // let lastNonEmptyIndex = Math.max(
        //   ...row.cells.map((c: string, cIndex: number) =>
        //     c === '' ? 0 : cIndex
        //   )
        // )

        return row.cells.map((cell: any, cellIndex: number) => {
          if (!cell) {
            return ''
          }

          return (
            <div
              key={cellIndex}
              className={c(
                { 'border-t-0': rowIndex === 0 },
                {
                  'border-none': borderless === true,
                },
                `g-table-cell ${
                  format.rows[rowIndex] &&
                  format.rows[rowIndex].cells &&
                  format.rows[rowIndex].cells[cellIndex]
                }`
              )}
            >
              <Markdown
                allowDangerousHtml
                source={cell}
                className={`markdown-grammar-table-content emphasis-${emphasisStyle}`}
              />
            </div>
          )
        })
      })}
    </div>
  )
}

const GrammarTable = ({
  data,
  format,
  borderless,
  emphasisStyle,
  nonEqualColumns,
}: GrammarTableProps) => (
  <Section>
    {renderTable(data, format, emphasisStyle, borderless, nonEqualColumns)}
  </Section>
)

export default GrammarTable
