import React from 'react'
import Dialog from './components/Dialog'
import { SectionHeader, SectionType } from './components/SectionHeader'
import ChapterHeader from './components/ChapterHeader'
import QuestionsBox from './components/QuestionsBox'
import MainImage from './components/MainImage'
import {
  Table,
  TableSection,
  TableCell,
  BoldTableContent,
  Section,
  USO,
} from './components/Utils'
import QuizSection from './components/QuizSection'
import QuizResponseComponent from '../QuizResponseComponent'
import SmallImage from './components/SmallImage'

const PHONETICS = [
  ['B/V', '[B]arcelona, [V]alencia'],
  ['Ch', '[Ch]ile, [Ch]ina'],
  ['H', '[h]otel, [h]ombre'],
  ['J', '[j]irafa, naran[j]a'],
  ['K', '[k]ilómetro, [k]ilógramo, [k]iwi'],
  ['LL/Y', 'torti[ll]a, po[ll]o, [y]eti, [y]ogur'],
  ['Q', '[que], [que]so, [qui]en, po[qui]to'],
  ['Z', '[z]apato, [z]umo'],
]

const PhoneticsTableRow = (contents: string[]) => (
  <tr key={contents.join(' ')}>
    <td className="font-bold text-right">{contents[0]}</td>
    <td
      className="pl-4"
      dangerouslySetInnerHTML={{
        __html: contents[1]
          .replace(/\[/g, '<strong>')
          .replace(/\]/g, '</strong>'),
      }}
    ></td>
  </tr>
)

const Chapter = () => {
  return (
    <div>
      <ChapterHeader text="Lección 3" />
      <MainImage src="/images/013.svg" />
      <SectionHeader type={SectionType.Dialog} text="diálogo 1" />
      <Dialog
        lines={[
          {
            speaker: 'Gordi',
            text: 'Hola Eva. ¿Dónde está Adán?',
          },
          {
            speaker: 'Eva',
            text: 'Adán está en la cama. Está enfermo y está triste.',
          },
          {
            separator: true,
          },
          {
            speaker: 'Verdi',
            text: '¿Cómo estás Adán?',
          },
          {
            speaker: 'Adán',
            text: 'Estoy mal. Estoy cansado. Y vosotros ¿Cómo estáis?',
          },
          {
            speaker: 'Verdi',
            text:
              'Estamos muy bien. La isla es interesante y el hotel está cerca de la playa.',
          },
          { speaker: 'Adán', text: 'La isla es bonita pero está sucia.' },
          {
            speaker: 'Gordi',
            text: '¿Dónde están los osos polares?',
          },
          {
            speaker: 'Eva',
            text: 'Los osos no están aquí, están con las focas en el mar.',
          },
          {
            speaker: 'Verdi',
            text: '¿Qué son las focas?',
          },
          {
            speaker: 'Adán',
            text: 'Son unos animales grises e inteligentes.',
          },
          {
            separator: true,
          },
          {
            speaker: 'Eva',
            text:
              'Aquí está el zumo. Es de naranja y cereza y es bueno para la salud.',
          },
          {
            speaker: 'Gordi',
            text: 'Muchas gracias. ¿Está frío?',
          },
          {
            speaker: 'Eva',
            text: 'No, no está muy frío pero aquí está el hielo.',
          },
          {
            speaker: 'Adán',
            text: '¡Qué bueno! Ahora estoy muy bien y estoy contento.',
          },
        ]}
      />
      <div className="flex flex-row flex-wrap justify-center">
        <SmallImage src="/images/014.svg" />
        <SmallImage src="/images/007.svg" />
      </div>
      <QuestionsBox
        questions={[
          '¿Dónde está Adán?',
          '¿Cómo está Adán?',
          '¿Cómo están los marcianos?',
          '¿Dónde está el hotel?',
          '¿Cómo es la isla?',
          '¿Cómo está la isla?',
          '¿Cómo son las focas?',
          '¿De qué es el zumo?',
          '¿Cómo está el zumo?',
          '¿Cómo estás tú?',
          '¿Cómo eres?',
          '¿Dónde estás?',
          '¿Dónde están los osos?',
        ]}
      />
      <SectionHeader type={SectionType.Grammar} text="GRAMÁTICA 1" />
      <TableSection>
        <Table>
          <thead>
            <tr className={'bg-lightpurple text-darkpurple font-bold'}>
              <th colSpan={4} className={'w-1/3'}>
                ESTAR (presente)
              </th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <TableCell>
                <BoldTableContent>Yo</BoldTableContent>
              </TableCell>
              <TableCell>estoy</TableCell>
              <TableCell>
                <BoldTableContent>Nosotros/nosotras</BoldTableContent>
              </TableCell>
              <TableCell>estamos</TableCell>
            </tr>
            <tr>
              <TableCell>
                <BoldTableContent>Tú</BoldTableContent>
              </TableCell>
              <TableCell>estás</TableCell>
              <TableCell>
                <BoldTableContent>Vosotros/vosotras</BoldTableContent>
              </TableCell>
              <TableCell>estáis</TableCell>
            </tr>
            <tr>
              <TableCell>
                <BoldTableContent>Él/ella/usted</BoldTableContent>
              </TableCell>
              <TableCell>está</TableCell>
              <TableCell>
                <BoldTableContent>Ellos/ellas/ustedes</BoldTableContent>
              </TableCell>
              <TableCell>están</TableCell>
            </tr>
          </tbody>
        </Table>
      </TableSection>

      <USO
        uses={[
          ['1. Localización', 'El oso polar está en el mar.'],
          ['2. Estado temporal', 'Adán está enfermo.'],
          ['3. Emociones', 'Adán está triste.'],
        ]}
      />

      <SectionHeader text="PRÁCTICA 1" type={SectionType.Practice} />

      <QuizSection>
        <QuizResponseComponent
          quizID={'cd1d3ed8-f4c0-4a40-8292-8a4db07bcadb'}
        />
      </QuizSection>

      <SectionHeader text="PRÁCTICA 2" type={SectionType.Practice} />

      <QuizSection>
        <QuizResponseComponent
          quizID={'b9e41f9e-b211-4aac-b27a-43bf4d653590'}
        />
      </QuizSection>

      <SectionHeader text="PRÁCTICA 3" type={SectionType.Practice} />
      <Section title="Construye frases con “ser” o “estar”.">
        <div className="flex flex-row m-4 max-w-full flex-wrap">
          {[
            ['la mesa', 'redonda', 'sucia'],
            ['la puerta', 'pequeña', 'limpia'],
            ['la chica', 'guapa', 'triste'],
            ['la ventana', 'abierta', 'grande'],
            ['el parque', 'desordenado', 'bonito'],
            ['Juan', 'enfermo', 'joven'],
            ['nosotros', 'pobres', 'contentos'],
            ['los libros', 'caros', 'rotos'],
            ['la nevera', 'moderna', 'vacía'],
          ].map((text) => (
            <div
              key={text.join(' ')}
              className="bg-sadgreen text-white w-40 mr-2 p-4 mb-4 text-center"
            >
              <div className="font-bold">{text[0]}</div>
              <div>{text[1]}</div>
              <div>{text[2]}</div>
            </div>
          ))}
        </div>
      </Section>
      <SectionHeader text="PRÁCTICA 4" type={SectionType.Practice} />
      <QuizSection>
        <QuizResponseComponent
          quizID={'0519cba8-98ff-4cec-a72d-10cd0c4e986e'}
        />
      </QuizSection>

      <SectionHeader text="PRÁCTICA 5" type={SectionType.Practice} />
      <Section title="Compara.">
        <div className="flex flex-col justify-start">
          <MainImage src="/images/015.svg" />
          <MainImage src="/images/016.svg" />
        </div>
      </Section>

      <SectionHeader text="FONÉTICA" type={SectionType.Phonetics} />
      <Section>
        <table>
          <tbody>{PHONETICS.map(PhoneticsTableRow)}</tbody>
        </table>
      </Section>

      <SectionHeader text="PRÁCTICA 6" type={SectionType.Practice} />
      <Section title="Lee.">
        <div className="w-full">
          <p>
            Tres tristes tigres tragaban trigo, tres tristes tigres en un
            trigal.
          </p>
          <p className="mb-4">Un tigre, dos tigres, tres tigres.</p>
          <p>¿Con cuántas planchas plancha Pancha?</p>
          <p className="mb-4">Pancha plancha con cuatro planchas.</p>
          <p>El perro de Ramón Rodríguez no tiene rabo</p>
          <p>Porque Ramón Rodríguez se lo ha cortado.</p>
        </div>
      </Section>
    </div>
  )
}

export default Chapter
