import React, { useEffect, useState } from 'react'
import { firestore } from '../firebase'
import ChapterHeader from './components/ChapterHeader'
import QuizResponseComponent from '../QuizResponseComponent'
import MainImage from './components/MainImage'
import { SectionHeader } from './components/SectionHeader'
import Dialog from './components/Dialog'
import GrammarTable from './components/GrammarTable'
import QuestionsBox from './components/QuestionsBox'
import SpeechBubble from './components/SpeechBubble'
import TextChat from './components/TextChat'
import WordSoup from './components/WordSoup'
import Note from './components/Note'
import QuizSection from './components/QuizSection'
import { SectionInstruction } from './components/Utils'

const BlockContent = require('@sanity/block-content-to-react')

interface ChapterProps {
  chapterID?: string
}

const getChapter = async (chapterID: string) => {
  // console.log(`---- getChapter(${chapterID})`)
  try {
    return (await firestore.collection('chapters').doc(chapterID).get()).data()
  } catch (error) {
    console.error('error in getChapter()', error)
  }

  return undefined
}

const Chapter = ({ chapterID }: ChapterProps) => {
  const [loading, setLoading] = useState(false)
  const [chapter, setChapter] = useState<object | undefined>(undefined)

  const renderChapter = (chapter: any) => {
    if (!chapter) {
      if (loading) {
        return 'loading...'
      } else {
        return 'no chapter data.'
      }
    }

    const blocks = JSON.parse(chapter.body)
    // console.log(blocks)

    const quizSerializer = (props: any) => (
      <QuizSection>
        <QuizResponseComponent quiz={props.node} />
      </QuizSection>
    )

    const serializers = {
      types: {
        fill_out_single_choice_answer_in_sentence: quizSerializer,
        fill_out_table: quizSerializer,
        fill_out_words_in_sentences: quizSerializer,
        write_text: quizSerializer,
        pick_one_word: quizSerializer,
        image: ({ node }: any) => (
          <MainImage sanityRef={node.asset._ref} alt="foo" />
        ),
        section_header: ({ node }: any) => (
          <SectionHeader type={node.type} text={node.text} />
        ),
        grammar_table: ({ node }: any) => (
          <GrammarTable
            data={node.data}
            format={node.format}
            borderless={node.borderless}
            emphasisStyle={node.emphasis_style}
            nonEqualColumns={node.non_equal_columns}
          />
        ),
        question_box: ({ node }: any) => (
          <QuestionsBox questions={node.questions} />
        ),
        section_instruction: ({ node }: any) => (
          <QuizSection>
            <SectionInstruction text={node.body} />
          </QuizSection>
        ),
        text_section: ({ node }: any) => (
          <QuizSection>
            <BlockContent blocks={node.body} />
          </QuizSection>
        ),
        speech_bubble: ({ node }: any) => <SpeechBubble body={node.body} />,
        assign_words_to_groups: quizSerializer,
        text_chat: ({ node }: any) => <TextChat bubbles={node.bubbles} />,
        word_soup: ({ node }: any) => <WordSoup words={node.words} />,
        note: ({ node }: any) => <Note body={node.body} />,
        dialog: ({ node }: any) => (
          <Dialog title={node.title} lines={node.statements} />
        ),
        code: ({ node }: any) => (
          <pre data-language={node.language}>
            <code>{node.code}</code>
          </pre>
        ),
      },
    }

    return (
      <div className="block-content">
        <BlockContent blocks={blocks} serializers={serializers} />
      </div>
    )
  }

  const loadChapter = async () => {
    if (!loading && chapterID) {
      // console.log('Backend#loadChapter', chapterID)
      setLoading(true)

      console.log(`scrollY: ${window.scrollY}`)
      const chapter = await getChapter(chapterID)

      console.log('chapter gotten', chapter)

      if (chapter) {
        // console.log('body')
        // console.log(chapter.body)

        setChapter(chapter)
        // const hash = window.location.hash
        // const hashValue = decodeURI(hash.replace(/^#/, ''))

        // console.log(`XXX: ${hashValue}`)
        // const element = document.getElementById(hashValue)
        // console.log(`YYY: ${element}`)

        // console.log(window.screen.availHeight)

        // if (element) {
        //   const elementY = element.getBoundingClientRect()
        //   console.log(`elementY:`, elementY)

        //   if (elementY.y > window.scrollY) {
        //     console.log(
        //       `should scroll DOWN from ${window.scrollY} to ${elementY.y}`
        //     )

        //     window.scrollBy(
        //       0,
        //       elementY.y - window.scrollY + window.screen.availHeight / 2
        //     )
        //   } else {
        //     console.log(
        //       `should scroll UP from ${window.scrollY} to ${elementY.y}`
        //     )
        //     window.scrollBy(0, window.scrollY - elementY.y)
        //   }
        // }
      }
    }
  }

  useEffect(() => {
    loadChapter()
  })

  return (
    <div>
      {/* <div className="grid grid-cols-3 gap-0 border border-red-500">
        <div
          className={
            'text-white bg-darkpurple font-bold p-2 col-start-2 col-span-2'
          }
        >
          POSESIVOS
        </div>
        <div
          className={
            'text-darkpurple bg-semilightpurple font-bold p-2 col-start-2'
          }
        >
          Tener 1 cosa
        </div>
        <div className={'text-darkpurple bg-semilightpurple font-bold p-2'}>
          Tener más cosas
        </div>
        <div className={'font-bold p-2 col-start-2'}>(el) libro (la) casa</div>
        <div className={'font-bold p-2'}>(los) libros (las) casas</div>
        <div className={'font-bold text-dirtygray col-start-1'}>yo</div>
        <div className={'font-bold'}>mi</div>
        <div className={'font-bold'}>mis</div>
      </div> */}

      <ChapterHeader text={`Lección ${chapterID}`} />
      {renderChapter(chapter)}
    </div>
  )
}

export default Chapter
