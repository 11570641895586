import React from 'react'
import Dialog from './components/Dialog'
import { SectionHeader, SectionType } from './components/SectionHeader'
import ChapterHeader from './components/ChapterHeader'
import QuizSection from './components/QuizSection'
import QuizResponseComponent from '../QuizResponseComponent'
import MainImage from './components/MainImage'
import QuestionsBox from './components/QuestionsBox'
import {
  Table,
  TableSection,
  TableCell,
  BoldTableContent,
  Section,
  USO,
} from './components/Utils'

const WordList = (words: string[]) => (
  <div className="m-1 p-2 md:m-2 lg:m-4">
    <ul className="bg-lightgreen rounded-sm p-2 shadow-sm md:p-4">
      {words.map((word, index) => (
        <li key={index}>{word}</li>
      ))}
    </ul>
  </div>
)

const N = (x: string) => (
  <td className="font-bold">
    <div className="bg-darkpurple mt-10 text-white my-2 tracking-tighter ml-1 text-center rounded-sm xl:p-2 xl:mr-6 xl:ml-10 lg:p-1 lg:mr-2 lg:ml-8 sm:mr-0 sm:mt-10 md:mt-0 md:mr-0">
      {x}
    </div>
  </td>
)

const V = (props: any) => (
  <td className="text-left pt-8 md:pr-4 lg:pr-0 sm:pt-8 md:pt-0 md:mt-0">
    {props.children}
  </td>
)

const Chapter = () => {
  return (
    <div>
      <ChapterHeader text="Lección 4" />
      <MainImage src="/images/004.svg" />
      <SectionHeader type={SectionType.Dialog} text="diálogo 1" />
      <Dialog
        lines={[
          {
            speaker: 'Adán',
            text: '¡Hola! ¿Cómo estás?',
          },
          {
            speaker: 'Gordi',
            text: 'Bien, pero tengo hambre y tengo sed.',
          },
          {
            speaker: 'Eva',
            text: 'Aquí tienes agua y un bocadillo.',
          },
          {
            speaker: 'Gordi',
            text: 'Gracias, ¡Qué interesante! No tenemos bocadillos en Marte',
          },
          {
            speaker: 'Eva',
            text: '¿Qué tenéis en Marte?',
          },
          {
            speaker: 'Gordi',
            text:
              'Tenemos frutas exóticas y cerveza pero no tenemos osos polares.',
          },
          {
            speaker: 'Verdi',
            text: 'Este oso polar es un poco viejo, ¿no? ¿Cuántos años tiene?',
          },
          {
            speaker: 'Adán',
            text: 'No, es bastante joven, tiene diez años.',
          },
          {
            speaker: 'Gordi',
            text: '¿¡Diez años!? Yo sólo tengo veinticuatro meses.',
          },
          {
            speaker: 'Eva',
            text: 'Y ¿tienes familia?',
          },
          {
            speaker: 'Gordi',
            text:
              'Sí, tengo una hermana pequeña y Verdi tiene siete hermanos y cuatro hermanas.',
          },
          {
            speaker: 'Adán',
            text: '¡Es genial!',
          },
        ]}
      />
      <QuestionsBox
        questions={[
          '¿Cómo está Gordi?',
          '¿Qué tienen en Marte?',
          '¿Qué no tienen los marcianos?',
          '¿Cuántos años tiene el oso polar?',
          '¿Cuántos años tiene Gordi?',
          '¿Tienen familia los marcianos?',
          '¿Cuántos años tienes?',
          '¿Tienes familia o amigos?',
          '¿Qué profesión tienes?',
          '¿Qué número de teléfono tienes?',
        ]}
      ></QuestionsBox>

      <SectionHeader type={SectionType.Grammar} text="GRAMÁTICA 1" />
      <TableSection>
        <Table>
          <thead>
            <tr className={'bg-lightpurple text-darkpurple font-bold'}>
              <th colSpan={4} className={'w-1/3 h-10'}>
                TENER (presente)
              </th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <TableCell>
                <BoldTableContent>Yo</BoldTableContent>
              </TableCell>
              <TableCell>tengo</TableCell>
              <TableCell>
                <BoldTableContent>Nosotros/nosotras</BoldTableContent>
              </TableCell>
              <TableCell>tenemos</TableCell>
            </tr>
            <tr>
              <TableCell>
                <BoldTableContent>Tú</BoldTableContent>
              </TableCell>
              <TableCell>tienes</TableCell>
              <TableCell>
                <BoldTableContent>Vosotros/vosotras</BoldTableContent>
              </TableCell>
              <TableCell>tenéis</TableCell>
            </tr>
            <tr>
              <TableCell>
                <BoldTableContent>Él/ella/usted</BoldTableContent>
              </TableCell>
              <TableCell>tiene</TableCell>
              <TableCell>
                <BoldTableContent>Ellos/ellas/ustedes</BoldTableContent>
              </TableCell>
              <TableCell>tienen</TableCell>
            </tr>
          </tbody>
        </Table>
      </TableSection>

      <USO
        uses={[
          ['1. Posesión', 'Tenemos una casa grande.'],
          ['2. Edad', 'El oso polar tiene 10 años.'],
          ['3. Sensaciones y sentimientos', 'Gordi tiene hambre.'],
        ]}
      />

      <Section>
        <svg width="640" height="495" xmlns="http://www.w3.org/2000/svg">
          <g>
            <ellipse
              cx="290"
              cy="290"
              rx="288"
              ry="200"
              stroke="#d6d8e4"
              fill="#d6d8e4"
            />
            <text
              x="50%"
              y="58%"
              className="text"
              textAnchor="middle"
              fill="#5c659b"
              fontSize="20px"
              style={{ fontWeight: 'bold', fontSize: '30px' }}
            >
              TENER
            </text>
            <text
              x="32%"
              y="28%"
              className="text"
              textAnchor="middle"
              fill="#5c659b"
              fontSize="20px"
              style={{ fontWeight: 'lighter' }}
              transform="rotate(-14, 320, 320)"
            >
              una familia pequeña
            </text>
            <text
              x="40%"
              y="42%"
              className="text"
              textAnchor="middle"
              fill="#5c659b"
              fontSize="20px"
              style={{ fontWeight: 'lighter' }}
              transform="rotate(10, 320, 320)"
            >
              pocos libros
            </text>
            <text
              x="50%"
              y="28%"
              className="text"
              textAnchor="middle"
              fill="#5c659b"
              fontSize="20px"
              style={{ fontWeight: 'lighter' }}
              transform="rotate(10, 320, 320)"
            >
              ordenador
            </text>
            <text
              x="70%"
              y="38%"
              className="text"
              textAnchor="middle"
              fill="#5c659b"
              fontSize="20px"
              style={{ fontWeight: 'lighter' }}
              transform="rotate(-5, 320, 320)"
            >
              sombrero verde
            </text>
            <text
              x="18%"
              y="50%"
              className="text"
              textAnchor="middle"
              fill="#5c659b"
              fontSize="20px"
              style={{ fontWeight: 'lighter' }}
              transform="rotate(0, 320, 320)"
            >
              clases de español
            </text>
            <text
              x="50%"
              y="49%"
              className="text"
              textAnchor="middle"
              fill="#5c659b"
              fontSize="20px"
              style={{ fontWeight: 'lighter' }}
              transform="rotate(-7, 320, 320)"
            >
              muchos amigos
            </text>
            <text
              x="80%"
              y="48%"
              className="text"
              textAnchor="middle"
              fill="#5c659b"
              fontSize="20px"
              style={{ fontWeight: 'lighter' }}
              transform="rotate(-5, 320, 320)"
            >
              un gato gordo
            </text>
            <text
              x="55%"
              y="93%"
              className="text"
              textAnchor="middle"
              fill="#5c659b"
              fontSize="20px"
              style={{ fontWeight: 'lighter' }}
              transform="rotate(-2, 320, 320)"
            >
              carné de conducir
            </text>
            <text
              x="18%"
              y="64%"
              className="text"
              textAnchor="middle"
              fill="#5c659b"
              fontSize="20px"
              style={{ fontWeight: 'lighter' }}
              transform="rotate(1, 320, 320)"
            >
              muchos amigos
            </text>
            <text
              x="50%"
              y="77%"
              className="text"
              textAnchor="middle"
              fill="#5c659b"
              fontSize="20px"
              style={{ fontWeight: 'lighter' }}
              transform="rotate(-3, 320, 320)"
            >
              tiempo libre
            </text>
            <text
              x="80%"
              y="64%"
              className="text"
              textAnchor="middle"
              fill="#5c659b"
              fontSize="20px"
              style={{ fontWeight: 'lighter' }}
              transform="rotate(3, 320, 320)"
            >
              un hermano
            </text>
            <text
              x="80%"
              y="55%"
              className="text"
              textAnchor="middle"
              fill="#5c659b"
              fontSize="20px"
              style={{ fontWeight: 'lighter' }}
              transform="rotate(3, 320, 320)"
            >
              un problema
            </text>
            <text
              x="80%"
              y="74%"
              className="text"
              textAnchor="middle"
              fill="#5c659b"
              fontSize="20px"
              style={{ fontWeight: 'lighter' }}
              transform="rotate(-5, 320, 320)"
            >
              bicicleta
            </text>{' '}
            <text
              x="60%"
              y="70%"
              className="text"
              textAnchor="middle"
              fill="#5c659b"
              fontSize="20px"
              style={{ fontWeight: 'lighter' }}
              transform="rotate(-5, 320, 320)"
            >
              fiebre
            </text>{' '}
            <text
              x="40%"
              y="65%"
              className="text"
              textAnchor="middle"
              fill="#5c659b"
              fontSize="20px"
              style={{ fontWeight: 'lighter' }}
              transform="rotate(2, 320, 320)"
            >
              hambre
            </text>{' '}
            <text
              x="20%"
              y="75%"
              className="text"
              textAnchor="middle"
              fill="#5c659b"
              fontSize="20px"
              style={{ fontWeight: 'lighter' }}
              transform="rotate(0, 320, 320)"
            >
              teléfono
            </text>
            <text
              x="26%"
              y="79%"
              className="text"
              textAnchor="middle"
              fill="#5c659b"
              fontSize="20px"
              style={{ fontWeight: 'lighter' }}
              transform="rotate(-5, 320, 320)"
            >
              sueño
            </text>
            <text
              x="35%"
              y="83%"
              className="text"
              textAnchor="middle"
              fill="#5c659b"
              fontSize="20px"
              style={{ fontWeight: 'lighter' }}
              transform="rotate(-5, 320, 320)"
            >
              frío
            </text>
            <text
              x="55%"
              y="85%"
              className="text"
              textAnchor="middle"
              fill="#5c659b"
              fontSize="20px"
              style={{ fontWeight: 'lighter' }}
              transform="rotate(5, 320, 320)"
            >
              prisa
            </text>
            <text
              x="72%"
              y="78%"
              className="text"
              textAnchor="middle"
              fill="#5c659b"
              fontSize="20px"
              style={{ fontWeight: 'lighter' }}
              transform="rotate(5, 320, 320)"
            >
              20 años
            </text>
          </g>
        </svg>
      </Section>

      <SectionHeader type={SectionType.Grammar} text="GRAMÁTICA 2" />

      <Section>
        <table className="w-full">
          <tbody>
            <tr>
              {N('0')}
              <V>cero</V>
              {N('10')}
              <V>diez</V>
              {N('20')}
              <V>veinte</V>
              {N('30')}
              <V>treinta</V>
            </tr>
            <tr>
              {N('1')}
              <V>uno</V>
              {N('11')}
              <V>
                on<span className="font-bold">ce</span>
              </V>
              {N('21')}
              <V>veintiuno</V>
              {N('31')}
              <V>
                treinta <span className="font-bold">y</span> uno
              </V>
            </tr>
            <tr>
              {N('2')}
              <V>dos</V>
              {N('12')}
              <V>
                do<span className="font-bold">ce</span>
              </V>
              {N('22')}
              <V>veintidós</V>
              {N('42')}
              <V>
                cuarenta <span className="font-bold">y</span> dos
              </V>
            </tr>
            <tr>
              {N('3')}
              <V>tres</V>
              {N('13')}
              <V>
                tre<span className="font-bold">ce</span>
              </V>
              {N('23')}
              <V>veintitrés</V>
              {N('53')}
              <V>
                cincuenta <span className="font-bold">y</span> tres
              </V>
            </tr>
            <tr>
              {N('4')}
              <V>cuatro</V>
              {N('14')}
              <V>
                cator<span className="font-bold">ce</span>
              </V>
              {N('24')}
              <V>veinticuatro</V>
              {N('64')}
              <V>
                sesenta <span className="font-bold">y</span> cuatro
              </V>
            </tr>
            <tr>
              {N('5')}
              <V>cinco</V>
              {N('15')}
              <V>
                quin<span className="font-bold">ce</span>
              </V>
              {N('25')}
              <V>veinticinco</V>
              {N('75')}
              <V>
                setenta <span className="font-bold">y</span> cinco
              </V>
            </tr>
            <tr>
              {N('6')}
              <V>seis</V>
              {N('16')}
              <V>
                <span className="font-bold">dieci</span>séis
              </V>
              {N('26')}
              <V>veintiséis</V>
              {N('86')}
              <V>
                ochenta <span className="font-bold">y</span> seis
              </V>
            </tr>
            <tr>
              {N('7')}
              <V>siete</V>
              {N('17')}
              <V>
                <span className="font-bold">dieci</span>siete
              </V>
              {N('27')}
              <V>veintisiete</V>
              {N('97')}
              <V>
                noventa <span className="font-bold">y</span> siete
              </V>
            </tr>
            <tr>
              {N('8')}
              <V>ocho</V>
              {N('18')}
              <V>
                <span className="font-bold">dieci</span>ocho
              </V>
              {N('28')}
              <V>veintiocho</V>
              {N('100')}
              <V>cien</V>
            </tr>
            <tr>
              {N('9')}
              <V>nueve</V>
              {N('19')}
              <V>
                <span className="font-bold">dieci</span>nueve
              </V>
              {N('29')}
              <V>veintinueve</V>
              {N('101')}
              <V>
                cien<span className="font-bold">to</span> uno
              </V>
            </tr>
          </tbody>
        </table>
      </Section>

      <SectionHeader text="PRÁCTICA 1" type={SectionType.Practice} />

      <QuizSection>
        <QuizResponseComponent
          quizID={'0f22d1b3-a9dc-41a6-841d-c7feda07f663'}
        />
      </QuizSection>

      <SectionHeader text="PRÁCTICA 2" type={SectionType.Practice} />
      <QuizSection>
        <QuizResponseComponent
          quizID={'389cc8d6-7bd7-4c95-b70e-6790ac5aa44d'}
        />
      </QuizSection>

      <SectionHeader text="PRÁCTICA 5" type={SectionType.Practice} />
      <Section title="¡Hola chicos!">
        <div className="m-4 max-w-full italic">
          <p>
            Me llamo Verdi, soy de Marte pero ahora estoy en una isla muy bonita
            en Paraíso. Tengo veinticuatro meses y dos semanas y soy del norte
            de Marte. Vivo en una ciudad muy bonita en las montañas. Tengo una
            familia grande. Soy turista profesional y en mi opinión es muy
            interesante visitar diferentes países. Estoy aquí con mi amigo Gordi
            y ahora tenemos dos amigos nuevos: la mujer se llama Eva y el hombre
            se llama Adán. Ellos tienen veinticinco años y tienen una casa muy
            grande. También tienen osos polares blancos y focas grises. Todos
            son muy simpáticos y amables.
          </p>
          <p className="mt-2">Un beso y hasta pronto,</p>
          <p>Verdi</p>
        </div>
      </Section>

      <SectionHeader text="PRÁCTICA 6" type={SectionType.Practice} />

      <Section title="Describe a las personas.">
        <div className="flex flex-col">
          <div className="w-full">
            <MainImage src="/images/017.svg" />
          </div>
          <div className="w-full">
            <div className="flex flex-row flex-wrap justify-center w-full">
              {WordList(['rubio/a', 'bigote', 'gafas', 'las cejas'])}
              {WordList(['barba', 'el pelo', 'sombrero', 'joven'])}
              {WordList(['moreno/a', 'corto', 'los ojos', 'mayor'])}
              {WordList(['pelirrojo/a', 'liso', 'la nariz', 'calvo'])}
              {WordList(['rizado', 'largo', 'la boca', 'castaño/a'])}
            </div>
          </div>
        </div>
      </Section>
    </div>
  )
}

export default Chapter
