import React, { useState, useContext } from 'react'
import { UserContext } from './providers/UserProvider'
import { Redirect, Link } from 'react-router-dom'
import { auth } from './firebase'
import classnames from 'classnames'
import {
  isUserAdmin,
  hasUserAccessToChapter,
  canUserKnowAboutChapter,
} from './Auth'
const c = classnames

interface Props {
  children: any
}

const Navigation = (props: Props) => {
  let chapterID: any = undefined

  const pathname = window.location.pathname
  if (pathname.match(/\/chapters\/[\d]+/)) {
    const pathnameParts = pathname.split('/')
    chapterID = pathnameParts[pathnameParts.length - 1]
  }

  const [moveTo, setMoveTo] = useState('')
  const [moveToDropdownOpen, setMoveToDropdownOpen] = useState(false)
  const [navigationMenuOpen, setMobileMenuOpen] = useState(false)
  const [currentChapterID, setCurrentChapterID] = useState(chapterID || '1')
  const userContext: any = useContext(UserContext)
  const user = userContext.user

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!navigationMenuOpen)
  }

  const chapterOption = (chapterNumber: number) => {
    if (!canUserKnowAboutChapter(user, chapterNumber)) {
      return
    }

    return (
      <a
        href={`/chapters/${chapterNumber}`}
        key={chapterNumber}
        className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
        role="menuitem"
        onClick={() => openChapter(`${chapterNumber}`)}
      >
        LECCIÓN {chapterNumber}
        {/* {chapterNumber > 9 && ' ⚒️'} */}
        {!hasUserAccessToChapter(user, chapterNumber) && ' 🗝'}
      </a>
    )
  }

  const renderChapterSwitcher = () => (
    <div className="relative inline-block text-left">
      <div>
        <span className="rounded-md shadow-sm">
          <button
            type="button"
            className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150"
            id="options-menu"
            aria-haspopup="true"
            aria-expanded="true"
            onClick={() => setMoveToDropdownOpen(!moveToDropdownOpen)}
          >
            LECCIÓN {currentChapterID}
            {/* Heroicon name: chevron-down --> */}
            <svg
              className="-mr-1 ml-2 h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </span>
      </div>

      {/*
      Dropdown panel, show/hide based on dropdown state.
  
      Entering: "transition ease-out duration-100"
        From: "transform opacity-0 scale-95"
        To: "transform opacity-100 scale-100"
      Leaving: "transition ease-in duration-75"
        From: "transform opacity-100 scale-100"
        To: "transform opacity-0 scale-95"
    */}
      <div
        className={c(
          { hidden: !moveToDropdownOpen },
          'origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg z-40'
        )}
      >
        <div className="rounded-md bg-white shadow-xs">
          <div
            className="py-1"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            {Array.from(Array(31).keys()).map((chapter) =>
              chapterOption(chapter + 1)
            )}
          </div>
        </div>
      </div>
    </div>
  )

  const openChapter = (chapterID: string) => {
    setCurrentChapterID(chapterID)
    setMoveTo(`/chapters/${chapterID}`)
    setMoveToDropdownOpen(false)
  }

  const renderNavigation = () => {
    const userInitials = (user.displayName || user.email).slice(0, 2)
    const userPhoto = user.photoURL ? (
      <img className="h-8 w-8 rounded-full" src={user.photoURL} alt="" />
    ) : (
      <span className="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-500">
        <span className="text-lg font-medium leading-none text-white uppercase">
          {userInitials}
        </span>
      </span>
    )

    return (
      <nav className="bg-gray-800">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex">
              <div className="-ml-2 mr-2 flex items-center md:hidden">
                {/* Mobile menu button */}
                <button
                  className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white transition duration-150 ease-in-out"
                  aria-label="Main menu"
                  aria-expanded="false"
                  onClick={() => toggleMobileMenu()}
                >
                  {/* Icon when menu is closed. */}
                  {/*
                    Heroicon name: menu

                    Menu open: "hidden", Menu closed: "block"
                  */}
                  <svg
                    className="block h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                  {/* Icon when menu is open. */}
                  {/* 
                  Heroicon name: x

                  Menu open: "block", Menu closed: "hidden"
                */}
                  <svg
                    className="hidden h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div className="flex-shrink-0 flex items-center">
                <img
                  className={c('lg:hidden', 'h-8', 'w-auto')}
                  src="/images/logo-white.png"
                  alt="Espanol de otro planeta logo"
                />
                <img
                  className="hidden lg:block h-8 w-auto"
                  src="/images/logo-white.png"
                  alt="Espanol de otro planeta logo"
                />
              </div>
              <div className="hidden md:ml-6 md:flex md:items-center">
                {isUserAdmin(user) && (
                  <Link
                    to="/admin"
                    className="ml-4 px-3 py-2 rounded-md text-sm font-medium leading-5 text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out"
                  >
                    Admin
                  </Link>
                )}

                {user.hasResponsesToEvaluate === true && (
                  <Link
                    to="/responses"
                    className="ml-4 px-3 py-2 rounded-md text-sm font-medium leading-5 text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out"
                  >
                    Responses
                  </Link>
                )}

                <a
                  href="https://www.espanoldeotroplaneta.com/contacto"
                  className="ml-4 px-3 py-2 rounded-md text-sm font-medium leading-5 text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out"
                >
                  Contact Us
                </a>
                {/*<a
                href="#"
                className="ml-4 px-3 py-2 rounded-md text-sm font-medium leading-5 text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out"
              >
                Projects
              </a>
              <a
                href="#"
                className="ml-4 px-3 py-2 rounded-md text-sm font-medium leading-5 text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out"
              >
                Calendar
              </a> */}
              </div>
            </div>
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <span className="rounded-md shadow-sm">
                  <div className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white  focus:outline-none focus:shadow-outline-indigo transition duration-150 ease-in-out">
                    {renderChapterSwitcher()}
                  </div>
                </span>
              </div>
              <div className="hidden md:ml-4 md:flex-shrink-0 md:flex md:items-center">
                {/* <button
                className="p-1 border-2 border-transparent text-gray-400 rounded-full hover:text-gray-300 focus:outline-none focus:text-gray-500 focus:bg-gray-100 transition duration-150 ease-in-out"
                aria-label="Notifications"
              > */}
                {/* Heroicon name: bell */}
                {/* <svg
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                  />
                </svg>
              </button> */}

                {/* Profile dropdown */}
                <div className="ml-3 relative">
                  <div>
                    <button
                      className="flex text-sm border-2 border-transparent rounded-full focus:outline-none focus:border-gray-300 transition duration-150 ease-in-out"
                      id="user-menu"
                      aria-label="User menu"
                      aria-haspopup="true"
                      onClick={() => toggleMobileMenu()}
                    >
                      {userPhoto}
                    </button>
                  </div>
                  {/* 
              Profile dropdown panel, show/hide based on dropdown state.

              Entering: "transition ease-out duration-200"
                From: "transform opacity-0 scale-95"
                To: "transform opacity-100 scale-100"
              Leaving: "transition ease-in duration-75"
                From: "transform opacity-100 scale-100"
                To: "transform opacity-0 scale-95"
            */}
                  <div
                    className={c(
                      { hidden: !navigationMenuOpen },
                      'origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg'
                    )}
                  >
                    <div
                      className="py-1 rounded-md bg-white shadow-xs"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="user-menu"
                    >
                      {/* <a
                      href="#"
                      className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                      role="menuitem"
                    >
                      Your Profile
                    </a>
                    <a
                      href="#"
                      className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                      role="menuitem"
                    >
                      Settings
                    </a> */}
                      <a
                        href="/signOut"
                        className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                        role="menuitem"
                        onClick={() => auth.signOut()}
                      >
                        Sign out
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* 
    Mobile menu, toggle classes based on menu state.

    Menu open: "block", Menu closed: "hidden"
  */}
        <div
          className={c(
            { block: navigationMenuOpen, hidden: !navigationMenuOpen },
            'md:hidden'
          )}
        >
          <div className="px-2 pt-2 pb-3 sm:px-3">
            {/* <a
            href="#"
            className="block px-3 py-2 rounded-md text-base font-medium text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out"
          >
            Contact Us
          </a> */}
            {isUserAdmin(user) && (
              <Link
                to="/admin"
                className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out"
              >
                Admin
              </Link>
            )}
            {user.hasResponsesToEvaluate === true && (
              <Link
                to="/responses"
                className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out"
              >
                Responses
              </Link>
            )}
            <a
              href="https://www.espanoldeotroplaneta.com/contacto"
              className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out"
            >
              Contact Us
            </a>
            {/*<a
            href="#"
            className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out"
          >
            Projects
          </a>
          <a
            href="#"
            className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out"
          >
            Calendar
          </a> */}
          </div>
          <div className="pt-4 pb-3 border-t border-gray-700">
            <div className="flex items-center px-5 sm:px-6">
              <div className="flex-shrink-0">{userPhoto}</div>
              <div className="ml-3">
                <div className="text-base font-medium leading-6 text-white">
                  {user.displayName && user.displayName}
                </div>
                <div className="text-sm font-medium leading-5 text-gray-400">
                  {user.email && user.email}
                </div>
              </div>
            </div>
            <div className="mt-3 px-2 sm:px-3">
              {/* <a
              href="#"
              className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out"
            >
              Your Profile
            </a> */}
              {/* <a
              href="#"
              className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out"
            >
              Settings
            </a> */}
              <a
                href="/signOut"
                className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out"
                onClick={() => auth.signOut()}
              >
                Sign out
              </a>
            </div>
          </div>
        </div>
      </nav>
    )
  }

  return (
    <div className="w-full flex flex-col">
      {moveTo && <Redirect push to={moveTo} />}
      {renderNavigation()}
      {props.children}
    </div>
  )
}

export default Navigation
