import React, { useState, useCallback, useEffect } from 'react'
import firebase from 'firebase/app'
import c from 'classnames'

const Users = () => {
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(false)
  const [inviting, setInviting] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [addNewUserPanelOn, setAddNewUserPanelOn] = useState(false)
  const [newUserInviteEmail, setUserInviteEmail] = useState('')
  const [status, setStatus] = useState('')

  const renderRole = (user: any) => {
    if (!user || !user.role) {
      return
    }

    return (
      <span
        className={c(
          'px-2 inline-flex text-xs leading-5 font-semibold rounded-full',
          {
            'bg-red-100 text-red-800': user.role === 'admin',
            'bg-yellow-100 text-yellow-800': user.role === 'free',
            'bg-green-100 text-green-800': user.role === 'student',
          }
        )}
      >
        {user.role}
      </span>
    )
  }

  const fetchData = useCallback(async () => {
    setLoading(true)

    const getAllUsers = firebase.functions().httpsCallable('getAllUsers')
    const users = await getAllUsers()

    setUsers(users.data)
    setLoading(false)
    setLoaded(true)
  }, [])

  useEffect(() => {
    if (!loaded && !loading) {
      fetchData()
    }
  })

  const inviteNewUser = async () => {
    setInviting(true)
    const inviteNewUserBackend = firebase
      .functions()
      .httpsCallable('inviteNewUser')

    await inviteNewUserBackend({ email: newUserInviteEmail })
    setInviting(false)

    setStatus(newUserInviteEmail + ' has been successfully invited.')
  }

  const renderAddNewUserPanel = () => (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        {/*  <!--
        Background overlay, show/hide based on modal state.
  
        Entering: "ease-out duration-300"
          From: "opacity-0"
          To: "opacity-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100"
          To: "opacity-0"
    --> */}
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        {/* <!-- This element is to trick the browser into centering the modal contents. -->*/}
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
        &#8203;
        {/*<!--
        Modal panel, show/hide based on modal state.
  
        Entering: "ease-out duration-300"
          From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          To: "opacity-100 translate-y-0 sm:scale-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100 translate-y-0 sm:scale-100"
          To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      -->*/}
        <div
          className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div>
            <div className="text-center">
              <h3
                className="text-lg leading-6 font-medium text-gray-900"
                id="modal-headline"
              >
                Invite a new user
              </h3>
              <div className="mt-2">
                <div className="w-full">
                  <label htmlFor="email" className="sr-only">
                    Email
                  </label>
                  <div className="relative rounded-md shadow-sm">
                    <input
                      id="email"
                      className="form-input block w-full sm:text-sm sm:leading-5"
                      placeholder="you@example.com"
                      value={newUserInviteEmail}
                      autoFocus
                      onChange={(e: any) =>
                        setUserInviteEmail(e.currentTarget.value)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
            <span className="flex w-full rounded-md shadow-sm sm:col-start-2">
              <button
                type="button"
                disabled={inviting}
                className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                onClick={async () => {
                  await inviteNewUser()
                  setAddNewUserPanelOn(false)
                }}
              >
                {inviting ? 'Inviting...' : 'Invite'}
              </button>
            </span>
            <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1">
              <button
                type="button"
                className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                onClick={() => setAddNewUserPanelOn(false)}
              >
                Cancel
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <div>
      {status && (
        <div className="bg-sadgreen text-white p-2 text-center mb-4">
          {status}
        </div>
      )}
      {addNewUserPanelOn && renderAddNewUserPanel()}
      {loading && (
        <div className="bg-darkpurple text-white p-2 text-center mb-4">
          loading...
        </div>
      )}
      <div className="w-full text-right pr-20 mb-10">
        <span className="inline-flex rounded-md shadow-sm">
          <button
            type="button"
            className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150"
            onClick={() => setAddNewUserPanelOn(true)}
          >
            Invite new user
          </button>
        </span>
      </div>
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 sm:px-10">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      ID
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      Name
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      E-mail
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      Role
                    </th>
                    <th className="px-6 py-3 bg-gray-50"></th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {users.map((user: any, index) => (
                    <tr key={index}>
                      <td className="px-6 py-4 whitespace-no-wrap">
                        {user.id}
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap">
                        <div className="flex items-center">
                          <div className="flex-shrink-0 h-10 w-10">
                            {user.photoURL && (
                              <img
                                className="h-10 w-10 rounded-full"
                                src={user.photoURL}
                                alt=""
                              />
                            )}
                          </div>
                          <div className="ml-4">
                            <div className="text-sm leading-5 font-medium text-gray-900">
                              {user.displayName}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap">
                        {user.email}
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                        {renderRole(user)}
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap text-right text-sm leading-5 font-medium">
                        <a
                          href={`/admin/users/${user.uid}/edit`}
                          className="text-indigo-600 hover:text-indigo-900"
                        >
                          Edit
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Users
