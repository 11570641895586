import React from 'react'
import Dialog from './components/Dialog'
import { SectionHeader, SectionType } from './components/SectionHeader'
import ChapterHeader from './components/ChapterHeader'
import QuestionsBox from './components/QuestionsBox'
import MainImage from './components/MainImage'
import {
  Table,
  TableSection,
  TableCell,
  BoldTableContent,
  Section,
  USO,
} from './components/Utils'
import QuizSection from './components/QuizSection'
import QuizResponseComponent from '../QuizResponseComponent'
import SideImage from './components/SideImage'
import SmallImage from './components/SmallImage'

const Chapter = () => {
  return (
    <div>
      <ChapterHeader text="Lección 2" />
      <MainImage src="/images/003.svg" />
      <SectionHeader type={SectionType.Dialog} text="diálogo 1" />
      <Dialog
        title="Dos marcianos en Paraíso"
        lines={[
          {
            speaker: 'Marciano 1',
            text: '¡Buenos días! ¿Vosotros sois de aquí?',
          },
          {
            speaker: 'Adán',
            text: 'Sí, somos de aquí.',
          },
          {
            speaker: 'Eva',
            text: 'Yo soy Eva y él es Adán. Y tú, ¿quién eres?',
          },
          {
            speaker: 'Marciano 1',
            text: 'Me llamo Gordi y él es Verdi. Somos de Marte.',
          },
          { speaker: 'Adán', text: '¡Bienvenidos!' },
          { speaker: 'Marciano 1', text: 'Muchas gracias.' },
          {
            speaker: 'Adán',
            text: 'Nosotros somos directores de Paraíso. ¿Y vosotros?',
          },
          { speaker: 'Marciano 1', text: 'Somos turistas.' },
          {
            speaker: 'Marciano 2',
            text: 'La isla...muy bonita. Perdón, yo no hablo muy bien español.',
          },
          {
            speaker: 'Eva',
            text:
              '¡No! Hablas muy bien. Y tu amigo también habla muy bien. Y ¿cómo es Marte?',
          },
          {
            speaker: 'Marciano 1',
            text: 'Marte es grande y rojo pero nosotros somos verdes.',
          },
          {
            speaker: 'Marciano 2',
            text: 'Por favor, ¿cómo se llama este animal?',
          },
          {
            speaker: 'Eva',
            text: 'Se llama oso polar. Los osos polares son blancos.',
          },
          { speaker: 'Marciano 2', text: '¡Es increíble!' },
        ]}
      />
      <QuestionsBox
        questions={[
          '¿De dónde son Gordi y Verdi?',
          '¿Cómo son Gordi y Verdi?',
          '¿Qué son los marcianos?',
          '¿Cómo es Marte?',
          '¿Qué son Adán y Eva?',
          '¿Cómo se llama el animal?',
          '¿Cómo es el oso?',
          'Y tú, ¿qué idiomas hablas? ¿Hablas español?',
        ]}
      />
      <SectionHeader type={SectionType.Grammar} text="GRAMÁTICA 1" />
      <TableSection>
        <Table>
          <thead>
            <tr className={'bg-lightpurple text-darkpurple font-bold'}>
              <th colSpan={4} className={'w-1/3'}>
                SER (presente)
              </th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <TableCell>
                <BoldTableContent>Yo</BoldTableContent>
              </TableCell>
              <TableCell>soy</TableCell>
              <TableCell>
                <BoldTableContent>Nosotros/nosotras</BoldTableContent>
              </TableCell>
              <TableCell>somos</TableCell>
            </tr>
            <tr>
              <TableCell>
                <BoldTableContent>Tú</BoldTableContent>
              </TableCell>
              <TableCell>eres</TableCell>
              <TableCell>
                <BoldTableContent>Vosotros/vosotras</BoldTableContent>
              </TableCell>
              <TableCell>sois</TableCell>
            </tr>
            <tr>
              <TableCell>
                <BoldTableContent>Él/ella/usted</BoldTableContent>
              </TableCell>
              <TableCell>es</TableCell>
              <TableCell>
                <BoldTableContent>Ellos/ellas/ustedes</BoldTableContent>
              </TableCell>
              <TableCell>son</TableCell>
            </tr>
          </tbody>
        </Table>
      </TableSection>

      <USO
        uses={[
          ['1. Identificación', 'Es un oso polar.'],
          ['2. Profesión', 'Somos directores.'],
          ['3. Nacionalidad/origen', 'Somos de Marte.'],
          ['4. Aspecto físico', 'El oso polar es blanco.'],
          ['5. Carácter', 'Eva es inteligente.'],
          ['6. Ideología/religión', 'Es comunista y vegetariano.'],
        ]}
      />

      <SectionHeader type={SectionType.Practice} text="PRÁCTICA 1" />
      <Section title="Construye frases libremente.">
        <div className="mt-4 w-full flex flex-row justify-center">
          <svg width="640" height="475" xmlns="http://www.w3.org/2000/svg">
            <g>
              <circle
                r="100"
                cy="160"
                cx="100"
                strokeWidth="36"
                stroke="#d9eae0"
                fill="#d9eae0"
                fillOpacity="0.5"
                strokeDashoffset={880}
                strokeDasharray="880"
              />

              <circle
                r="110"
                cy="130"
                cx="305"
                strokeWidth="36"
                stroke="#d9eae0"
                fill="#d9eae0"
                fillOpacity="0.5"
                strokeDashoffset={880}
                strokeDasharray="880"
              />

              <circle
                r="105"
                cy="372"
                cx="300"
                strokeWidth="36"
                stroke="#d9eae0"
                fill="#d9eae0"
                fillOpacity="0.5"
                strokeDashoffset={880}
                strokeDasharray="880"
              />

              <circle
                r="100"
                cy="200"
                cx="492"
                strokeWidth="36"
                stroke="#d9eae0"
                fill="#d9eae0"
                fillOpacity="0.5"
                strokeDashoffset={880}
                strokeDasharray="880"
              />

              <text
                x="0%"
                y="21%"
                className="text"
                textAnchor="middle"
                fill="#3b8777"
                fontSize="25px"
              >
                <tspan x="495" dy="1.6em">
                  pintor
                </tspan>
                <tspan x="495" dy="1.6em">
                  escritor
                </tspan>
                <tspan x="495" dy="1.6em">
                  cantante
                </tspan>
                <tspan x="495" dy="1.6em">
                  actor/actriz
                </tspan>
              </text>
              <text
                x="0%"
                y="15%"
                className="text"
                textAnchor="middle"
                fill="#3b8777"
                fontSize="25px"
              >
                <tspan x="104" dy="1.6em">
                  médico
                </tspan>
                <tspan x="104" dy="1.6em">
                  enfermero
                </tspan>
                <tspan x="104" dy="1.6em">
                  dentista
                </tspan>
                <tspan x="104" dy="1.6em">
                  veterinario
                </tspan>
              </text>

              <text
                x="0%"
                y="7%"
                className="text"
                textAnchor="middle"
                fill="#3b8777"
                fontSize="25px"
              >
                <tspan x="310" dy="1.6em">
                  profesor
                </tspan>
                <tspan x="310" dy="1.6em">
                  abogado
                </tspan>
                <tspan x="310" dy="1.6em">
                  ingeniero
                </tspan>
                <tspan x="310" dy="1.6em">
                  periodista
                </tspan>
              </text>

              <text
                x="0%"
                y="59%"
                className="text"
                textAnchor="middle"
                fill="#3b8777"
                fontSize="25px"
              >
                <tspan x="300" dy="1.6em">
                  bombero
                </tspan>
                <tspan x="300" dy="1.6em">
                  policía
                </tspan>
                <tspan x="300" dy="1.6em">
                  fontanero
                </tspan>
                <tspan x="300" dy="1.6em">
                  informático
                </tspan>
              </text>
            </g>
          </svg>
        </div>
      </Section>

      <SectionHeader type={SectionType.Practice} text="PRÁCTICA 2" />
      <QuizSection>
        <QuizResponseComponent
          quizID={'2bd9e4f9-c05c-48b5-9adb-e8d6c35a4d47'}
        />
      </QuizSection>

      <SideImage src="/images/005.svg" />

      <SectionHeader type={SectionType.Practice} text="PRÁCTICA 3" />
      <QuizSection>
        <QuizResponseComponent
          quizID={'5a185d4b-4474-4efd-b9fc-e651830b14af'}
        />
      </QuizSection>

      <SectionHeader type={SectionType.Practice} text="PRÁCTICA 4" />
      <Section title="Escribe. ¿De qué color es? (7 frases)">
        <div className="flex flex-row flex-wrap justify-center">
          <SmallImage src="/images/006.svg" />
          <SmallImage src="/images/009.svg" />
          <SmallImage src="/images/007.svg" />
          <SmallImage src="/images/008.svg" />
          <SmallImage src="/images/011.svg" />
          <SmallImage src="/images/012.svg" />
          <SmallImage src="/images/054.svg" />
          <SmallImage src="/images/055.svg" />
          <SmallImage src="/images/010.svg" />
        </div>
      </Section>
    </div>
  )
}

export default Chapter
