import React from 'react'

import { Quiz } from '../client'
import { QuizMode, MatchTwoIndexesDataSource } from './Quiz'
import { QuizComponentProps } from '../Quizes/Quiz'
import {
  QuizWrapper,
  QuizContainer,
  QuizInstruction,
} from '../chapters/components/Utils'
import Comment from './Comment'
import CommentReview from './CommentReview'
import Answer from './Answer'

import classnames from 'classnames'
const c = classnames

interface WordList extends Quiz {
  words: string[]
}

export interface PickOneWordQuiz extends Quiz {
  words: WordList[]
}

interface PickOneWordQuizQuizComponentProps extends QuizComponentProps {
  quiz: PickOneWordQuiz
  mode: QuizMode
}

export const PickOneWord: React.FC<PickOneWordQuizQuizComponentProps> = (
  props
) => {
  const { answers, evaluations, comments, notes, quiz, mode } = props

  const groups = quiz.words

  let newDataSource = new MatchTwoIndexesDataSource(
    answers,
    evaluations,
    comments,
    notes
  )

  const { setAnswerValue, setNotes } = newDataSource

  if (props.onAnswersUpdate) {
    newDataSource.onAnswersUpdate = props.onAnswersUpdate
  }

  if (props.onEvaluationsUpdate) {
    newDataSource.onEvaluationsUpdate = props.onEvaluationsUpdate
  }

  if (props.onCommentsUpdate) {
    newDataSource.onCommentsUpdate = props.onCommentsUpdate
  }

  if (props.onNotesUpdate) {
    newDataSource.onNotesUpdate = props.onNotesUpdate
  }

  const handleClick = (groupIndex: number, wordIndex: number) => {
    if (props.mode === QuizMode.Answer) {
      setAnswerValue(groupIndex, wordIndex)
    }
  }

  const renderWord = (
    groupIndex: number,
    word: string,
    wordIndex: number,
    selected: boolean,
    onClick: any
  ) => {
    let leftHand = '',
      rightHand = ''

    // if (selected) {
    //   leftHand = '👉'
    //   rightHand = '👈'
    // }

    return (
      <td
        key={wordIndex}
        className={c(
          'border border-sadgreen bg-lightgreen mt-2 ml-2 px-1 lg:mt-10 lg:py-4 text-center xl:px-4',
          {
            'bg-sadgreen': selected,
            'cursor-pointer': props.mode === QuizMode.Answer,
          }
        )}
        onClick={onClick}
      >
        {props.mode !== QuizMode.Answer && selected
          ? renderAnswer(groupIndex, wordIndex, word)
          : `${leftHand}${word}${rightHand}`}
      </td>
    )
  }

  const renderAnswer = (
    questionIndex: number,
    inputIndex: number,
    word: string
  ) => {
    const {
      setAnswerEvaluation,
      isAnswerEvaluated,
      isAnswerCorrect,
    } = newDataSource

    return (
      <Answer
        disabled={props.mode === QuizMode.Review}
        content={word}
        evaluated={isAnswerEvaluated && isAnswerEvaluated(questionIndex)}
        correct={isAnswerCorrect && isAnswerCorrect(questionIndex)}
        onEvaluate={(correct: boolean) =>
          setAnswerEvaluation && setAnswerEvaluation(questionIndex, correct)
        }
      />
    )
  }

  const renderGroup = (group: any, groupIndex: number) => {
    return (
      <tr key={groupIndex} className="w-full flex flex-row">
        <td className="font-bold mt-2 lg:mt-10 flex flex-col justify-center lg:mr-4">
          {groupIndex + 1}.{' '}
        </td>
        {group.words.map((word: string, wordIndex: number) => {
          const selected =
            newDataSource.getAnswerValue(groupIndex) === wordIndex
          return renderWord(groupIndex, word, wordIndex, selected, () =>
            handleClick(groupIndex, wordIndex)
          )
        })}
      </tr>
    )
  }

  const renderGroups = () => (
    <table className="">
      <tbody>{groups.map(renderGroup)}</tbody>
    </table>
  )

  const renderNotes = (notes?: string) =>
    notes && <CommentReview general={true} text={notes} />

  return (
    <QuizWrapper>
      <QuizInstruction>{quiz.instruction}</QuizInstruction>

      <div className="my-5 w-full">
        {mode === QuizMode.Evaluation && (
          <Comment
            label="General notes to the student"
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              setNotes && setNotes(e.currentTarget.value)
            }
          />
        )}
        {mode === QuizMode.Review && renderNotes(notes)}
      </div>

      <QuizContainer className="flex flex-row justify-start">
        {renderGroups()}
      </QuizContainer>
    </QuizWrapper>
  )
}

export default PickOneWord
