import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/functions'
import 'firebase/firestore'
import 'firebase/storage'

const projectId = 'edop-production'

let firebaseConfig

if (process.env.NODE_ENV === 'development') {
  firebaseConfig = {
    apiKey: 'AIzaSyBFPBi5-P11qQo8H4KkKMt1DSm-F7sHeyw',
    projectId,
    storageBucket: 'edop-production.appspot.com',
  }
} else {
  firebaseConfig = {
    apiKey: 'AIzaSyBFPBi5-P11qQo8H4KkKMt1DSm-F7sHeyw',
    authDomain: 'edop-production.firebaseapp.com',
    databaseURL: 'https://edop-production.firebaseio.com',
    projectId,
    storageBucket: 'edop-production.appspot.com',
    messagingSenderId: '519659244093',
    appId: '1:519659244093:web:e71a7a0eedb3151a507854',
  }
}

export const app = firebase.initializeApp(firebaseConfig)

if (process.env.NODE_ENV === 'development') {
  app.functions().useEmulator('0.0.0.0', 8101)
  app.firestore().useEmulator('0.0.0.0', 8102)
  //  app.auth().useEmulator('http://0.0.0.0:8103')
}

export const auth = app.auth()
export const firestore = app.firestore()
export const storage = app.storage()

const provider = new firebase.auth.GoogleAuthProvider()

export const signInWithGoogle = () => {
  auth.signInWithPopup(provider)
}
