import React, { useState, useCallback } from 'react'
import { isMobile } from 'react-device-detect'

import { Quiz } from '../client'
import { QuizMode, MatchTwoIndexesDataSource } from './Quiz'
import { QuizComponentProps } from '../Quizes/Quiz'
import Comment from './Comment'
import CommentReview from './CommentReview'
import {
  QuizWrapper,
  QuizContainer,
  QuizInstruction,
} from '../chapters/components/Utils'

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'

import { useDrop, useDrag } from 'react-dnd'
import Answer from './Answer'

export interface GroupProps {
  onDrop: (item: any) => void
  name: string
  index: number
  mode: QuizMode
}

export const Group: React.FC<GroupProps> = ({
  onDrop,
  name,
  mode,
  children,
}) => {
  let accept = ['word']

  if (mode !== QuizMode.Answer) {
    accept = ['']
  }

  const [{ isOver, canDrop }, drop] = useDrop({
    drop: onDrop,
    accept,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })

  const isActive = isOver && canDrop

  let backgroundColor = 'lightgreen'

  if (isActive) {
    backgroundColor = 'sadgreen'
  } else if (canDrop) {
    backgroundColor = 'green'
  }

  return (
    <div ref={drop} className="p-2 sm:p-4 w-1/2 min-h-1/4 flex-grow">
      <div
        style={{ backgroundColor }}
        className="sm:p-4 rounded-lg text-center min-h-1/4 flex-grow w-full"
      >
        <div className="font-bold">{name}</div>
        <div className="rounded-lg flex flex-row flex-wrap sm:p-4 min-h-10 h-full">
          {children}
        </div>
      </div>
    </div>
  )
}

export interface WordProps {
  name: string
  isDropped: boolean
  index: number
  groupIndex?: number
}

export const Word: React.FC<WordProps> = ({ index, name, isDropped }) => {
  const [{ opacity }, drag] = useDrag({
    item: { name, type: 'word', index },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
    }),
  })

  return (
    <div
      ref={drag}
      className="px-2 border border-gray-400 bg-white m-1 sm:m-2"
      style={{ opacity }}
    >
      <span className="hidden sm:inline text-gray-400 font-bold px-2">·</span>{' '}
      {name}
    </div>
  )
}

interface GroupState {
  name: string
  index: number
}

interface WordState {
  name: string
  index: number
  groupIndex?: number
}

export interface GroupSpec {
  lastDroppedItem: any
  name: string
  index: number
}

export interface WordSpec {
  name: string
  index: number
}

export interface AssignWordsToGroupsQuiz extends Quiz {
  groups: string[]
  words: string[]
}

interface AssignWordsToGroupsQuizComponentProps extends QuizComponentProps {
  quiz: AssignWordsToGroupsQuiz
  mode: QuizMode
}

export const AssignWordsToGroups: React.FC<AssignWordsToGroupsQuizComponentProps> = (
  props
) => {
  const { answers, evaluations, comments, notes, quiz, mode } = props

  let newDataSource = new MatchTwoIndexesDataSource(
    answers,
    evaluations,
    comments,
    notes
  )

  const {
    getAnswerValue,
    isAnswerCorrect,
    isAnswerEvaluated,
    setAnswerEvaluation,
    setNotes,
  } = newDataSource

  if (props.onAnswersUpdate) {
    newDataSource.onAnswersUpdate = props.onAnswersUpdate
  }

  if (props.onEvaluationsUpdate) {
    newDataSource.onEvaluationsUpdate = props.onEvaluationsUpdate
  }

  if (props.onCommentsUpdate) {
    newDataSource.onCommentsUpdate = props.onCommentsUpdate
  }

  if (props.onNotesUpdate) {
    newDataSource.onNotesUpdate = props.onNotesUpdate
  }

  const dataSource = newDataSource

  const [groups] = useState<GroupState[]>(
    quiz.groups.map((group, index) => ({ name: group, index }))
  )

  const [words, setWords] = useState<WordState[]>(
    quiz.words.map((word, index) => ({
      name: word,
      index,
      groupIndex: getAnswerValue(index),
    }))
  )

  function isDropped(word: WordState) {
    return (
      words.filter(
        (w) => word.index === w.index && typeof w.groupIndex === 'number'
      ).length > 0
    )
  }

  const handleDrop = useCallback(
    (group: GroupState, word: WordProps) => {
      const newWords = words.map((w) => {
        if (w.index === word.index) {
          w.groupIndex = group.index
          return w
        }

        return w
      })

      setWords(newWords)

      dataSource.setAnswerValue(word.index, group.index)
    },
    [words, dataSource]
  )

  const wordsInGroup = (group: GroupState) =>
    words.filter((w) => w.groupIndex === group.index)

  const renderWord = (word: WordState) => {
    const { index, name } = word
    const key = `word-${word.index}`

    if (mode !== QuizMode.Answer) {
      return (
        <Answer
          disabled={mode !== QuizMode.Evaluation}
          content={name}
          evaluated={isAnswerEvaluated(index)}
          className="mx-2 rounded-lg px-1"
          key={key}
          correct={isAnswerCorrect(index)}
          onEvaluate={(correct: boolean) => {
            setAnswerEvaluation(index, correct)
          }}
        />
      )
    }

    return (
      <Word
        name={word.name}
        isDropped={isDropped(word)}
        index={word.index}
        key={key}
      />
    )
  }

  const renderGroups = () => {
    return (
      <div className="flex flex-row flex-wrap justify-items-center w-full my-4">
        {groups.map((group, index) => (
          <Group
            onDrop={(item) => handleDrop(group, item)}
            key={`group-${index}`}
            mode={mode}
            index={index}
            name={group.name}
          >
            {wordsInGroup(group).map((word) => renderWord(word))}
          </Group>
        ))}
      </div>
    )
  }

  const renderUngroupedWords = () => {
    return (
      <div className="flex flex-row flex-wrap">
        {words.map((word: WordState, index: number) => {
          if (typeof word.groupIndex !== 'number') {
            return renderWord(word)
          }

          return ''
        })}
      </div>
    )
  }

  const renderNotes = (notes?: string) =>
    notes && <CommentReview general={true} text={notes} />

  return (
    <QuizWrapper>
      <QuizInstruction>{quiz.instruction}</QuizInstruction>

      <div className="my-5 w-full">
        {mode === QuizMode.Evaluation && (
          <Comment
            label="General notes to the student"
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              setNotes && setNotes(e.currentTarget.value)
            }
          />
        )}
        {mode === QuizMode.Review && renderNotes(notes)}
      </div>

      <QuizContainer className="w-full flex flex-col justify-start">
        <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
          {renderGroups()}
          {renderUngroupedWords()}
        </DndProvider>
      </QuizContainer>
    </QuizWrapper>
  )
}

export default AssignWordsToGroups
