import React from 'react'
import Dialog from './components/Dialog'
import { SectionHeader, SectionType } from './components/SectionHeader'
import ChapterHeader from './components/ChapterHeader'
import QuestionsBox from './components/QuestionsBox'
import MainImage from './components/MainImage'
import { QuizInstruction, Section } from './components/Utils'
import QuizSection from './components/QuizSection'
import QuizResponseComponent from '../QuizResponseComponent'

const Chapter = () => {
  return (
    <div>
      <ChapterHeader text="Lección 7" />
      <SectionHeader type={SectionType.Dialog} text="diálogo 1" />
      <Dialog
        lines={[
          {
            speaker: 'Gordi',
            text: 'Perdón, Adán, ¿dónde está la playa?',
          },
          {
            speaker: 'Adán',
            text:
              'La playa está muy cerca, a cincuenta metros de aquí. Y Marte, ¿está cerca o está lejos?',
          },
          {
            speaker: 'Gordi',
            text:
              'Marte está muy lejos pero el transporte es muy rápido. El avión es muy moderno.',
          },
          {
            speaker: 'Eva',
            text: '¿Dónde está el avión?',
          },
          {
            speaker: 'Verdi',
            text:
              'Está entre el hotel y la jungla, en el campo de golf porque parking para aviones no tenéis, ¿no?',
          },
          {
            speaker: 'Adán',
            text:
              'Tenemos un parking en la playa, al lado de la casa gris. La casa está encima de la roca y los caracoles viven debajo.',
          },
          {
            speaker: 'Gordi',
            text: '¡Qué complicado! Y ¿dónde viven las focas?',
          },
          {
            speaker: 'Eva',
            text:
              'Las focas viven dentro del mar pero los osos polares viven fuera del agua.',
          },
          {
            speaker: 'Verdi',
            text: 'Tengo una pregunta importante. ¿Dónde está el bar?',
          },
          {
            speaker: 'Adán',
            text: 'Está aquí, a la izquierda.',
          },
          {
            speaker: 'Verdi',
            text: '¿Dónde? No comprendo.',
          },
          {
            speaker: 'Eva',
            text: '¿Izquierda? Es donde tienes el corazón ❤️',
          },
          {
            speaker: 'Gordi',
            text: 'No comprendo. Los marcianos tienen el corazón a la derecha.',
          },
        ]}
      />
      <MainImage src="/images/019.svg" alt="019" />
      <QuestionsBox
        questions={[
          '¿Dónde está la playa?',
          '¿Dónde está el Marte?',
          '¿Dónde está el avión?',
          '¿Dónde está el campo de golf?',
          '¿Dónde está el parking para aviones?',
          '¿Dónde está la casa gris?',
          '¿Dónde viven los caracoles?',
          '¿Dónde está la roca?',
          '¿Dónde viven las focas y los osos?',
          '¿Dónde está el bar?',
          '¿Dónde tienen los marcianos el corazón?',
          '¿Dónde estás?',
          '¿Dónde está tu casa?',
        ]}
      />

      <SectionHeader type={SectionType.Grammar} text="GRAMÁTICA 1" />

      <QuizInstruction>¿Dónde está el caracol?</QuizInstruction>

      <MainImage src="/images/020.svg" alt="020" />

      <Section>
        <div className="w-full bg-lightpurple flex justify-around flex-col p-4 rounded-tr-lg items-center">
          <div className="font-bold my-2">de + el = del</div>
          <div className="my-2">
            El coche está al lado <strong>del</strong> cine.
          </div>
          <div className="my-2">
            El coche está al lado <strong>de la</strong> plaza.
          </div>
        </div>
      </Section>

      <SectionHeader type={SectionType.Practice} text="PRÁCTICA 1" />

      <MainImage src="/images/021.svg" alt="021" />

      <QuizSection>
        <QuizResponseComponent
          quizID={'fe7e6b4a-941c-4b91-9491-a6c30a33e04f'}
        />
      </QuizSection>

      <QuizSection>
        <QuizResponseComponent
          quizID={'efd78491-cbb5-461d-b2fe-e6aa9a0c66de'}
        />
      </QuizSection>
    </div>
  )
}

export default Chapter
