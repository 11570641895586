import React from 'react'
import c from 'classnames'

interface TextChatProps {
  bubbles: any[]
}

const Bubble = (
  bubble: { text: string; positive?: boolean },
  fromMe: boolean
) => (
  <p
    key={bubble.text}
    className={c(
      fromMe ? 'from-me' : 'from-them',
      { green: bubble.positive === true },
      { red: bubble.positive === false }
    )}
  >
    {bubble.text}
  </p>
)

const TextChat = ({ bubbles }: TextChatProps) => {
  return (
    <div className="p-6 max-w-sm mx-auto rounded-xl shadow-md flex items-center space-x-4 mt-20">
      <div className="imessage">
        {bubbles.map((bubble, bubbleIndex: number) =>
          Bubble(bubble, bubbleIndex % 2 === 0)
        )}
      </div>
    </div>
  )
}

export default TextChat
