import React from 'react'
import classnames from 'classnames'

const c = classnames

interface InputFieldProps {
  questionIndex: number
  setValue: Function
  inputIndex: number
  className: string
  fullWidth?: boolean
}

function InputField(props: InputFieldProps) {
  const { inputIndex, questionIndex, className, setValue, fullWidth } = props
  const key = `input-${questionIndex}-${inputIndex}`

  const finalClassName = c(
    'outline-none',
    'focus:shadow-outline',
    'italic',
    'font-bold',
    'border-dirtygray',
    'border-b-2',
    ...className.split(' ')
  )

  // if (inputIndex === 0 && questionIndex === 0) {
  const input = (
    <input
      className={finalClassName}
      placeholder="✍🏼"
      onChange={(event) => {
        setValue(event.target.value)
      }}
      key={key}
      style={fullWidth ? { display: 'table-cell', width: '100%' } : {}}
    />
  )

  if (fullWidth) {
    return <div className="w-full table">{input}</div>
  } else {
    return input
  }
}

export default InputField
