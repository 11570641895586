import React from 'react'
import { QuizMode, WriteTextDataSource } from './Quiz'
import { QuizComponentProps } from './Quiz'
import { Quiz } from '../client'
import {
  QuizWrapper,
  QuizContainer,
  QuizInstruction,
} from '../chapters/components/Utils'

// @ts-ignore
import { CKEditor } from '@ckeditor/ckeditor5-react'
// @ts-ignore
import BalloonEditor from '@ckeditor/ckeditor5-build-balloon'

interface State {
  dataSource: WriteTextDataSource
}

export interface WriteTextQuiz extends Quiz {}

interface WriteTextQuizComponentProps extends QuizComponentProps {
  quiz: WriteTextQuiz
  mode: QuizMode
}

class WriteText extends React.Component<WriteTextQuizComponentProps, State> {
  constructor(props: WriteTextQuizComponentProps) {
    super(props)

    const { answers, evaluations, notes } = props

    const newDataSource = new WriteTextDataSource(answers, evaluations, notes)

    if (props.onAnswersUpdate) {
      newDataSource.onAnswersUpdate = props.onAnswersUpdate
    }

    if (props.onEvaluationsUpdate) {
      newDataSource.onEvaluationsUpdate = props.onEvaluationsUpdate
    }

    if (props.onNotesUpdate) {
      newDataSource.onNotesUpdate = props.onNotesUpdate
    }

    this.state = {
      dataSource: newDataSource,
    }
  }

  renderAnswerReview = () => {
    const { dataSource } = this.state
    const { getNotes } = dataSource

    return (
      <CKEditor
        editor={BalloonEditor}
        disabled={true}
        config={{
          removePlugins: ['Heading', 'Link'],
        }}
        data={getNotes()}
      />
    )
  }

  renderAnswerEvaluation = () => {
    const { dataSource } = this.state
    const { getAnswerValue, setNotes } = dataSource

    return (
      <CKEditor
        editor={BalloonEditor}
        config={{
          removePlugins: ['Heading', 'Link'],
        }}
        data={getAnswerValue()}
        onChange={(event: any, editor: any) => {
          const data = editor.getData()
          setNotes(data)
        }}
      />
    )
  }

  renderAnswerInput = () => {
    const {
      dataSource: { setAnswerValue },
    } = this.state

    return (
      <CKEditor
        editor={BalloonEditor}
        config={{
          removePlugins: ['Heading', 'BlockQuote', 'Link'],
        }}
        data="Click and start writing your answer here..."
        onChange={(event: any, editor: any) => {
          const data = editor.getData()
          setAnswerValue(data)
        }}
      />
    )
  }

  renderQuestion = () => {
    const { mode } = this.props

    switch (mode) {
      case QuizMode.Answer:
        return this.renderAnswerInput()

      case QuizMode.Evaluation:
        return this.renderAnswerEvaluation()

      case QuizMode.Review:
        return this.renderAnswerReview()

      default:
        break
    }

    return
  }

  render() {
    const { quiz } = this.props

    return (
      <QuizWrapper>
        <QuizInstruction>{quiz.instruction}</QuizInstruction>

        <QuizContainer>{this.renderQuestion()}</QuizContainer>
      </QuizWrapper>
    )
  }
}

export default WriteText
