import React from 'react'
import Dialog from './components/Dialog'
import { SectionHeader, SectionType } from './components/SectionHeader'
import ChapterHeader from './components/ChapterHeader'
import QuestionsBox from './components/QuestionsBox'
import MainImage from './components/MainImage'
import QuizResponseComponent from '../QuizResponseComponent'

import { Table, TableSection, TableHeader, TableCell } from './components/Utils'

import classnames from 'classnames'
import QuizSection from './components/QuizSection'

const c = classnames

const S = (text: string) => <span className={'font-bold'}>{text}</span>

const Chapter = () => {
  const grammatica3TableData = [
    ['España', 'español', 'española'],
    ['Inglaterra', 'inglés', 'inglesa'],
    ['Francia', 'francés', 'francesa'],
    ['Estados Unidos', 'estadounidense'],
    ['Canadá', 'canadiense'],
    ['Polonia', 'polaco', 'polaca'],
    ['China', 'chino', 'china'],
    ['Alemania', 'alemán', 'alemana'],
    ['Rusia', 'ruso', 'rusa'],
    ['Ucrania', 'ucraniano', 'ucraniana'],
  ]

  return (
    <div>
      <ChapterHeader text="Lección 1" />

      <MainImage src="/images/001.svg" alt="001" />

      <SectionHeader type={SectionType.Dialog} text="diálogo 1" />

      <Dialog
        title="En Paraíso"
        lines={[
          {
            speaker: 'Adán',
            text: '¡Hola!',
          },
          {
            speaker: 'Eva',
            text: '¡Hola!',
          },
          { speaker: 'Adán', text: '¿Cómo te llamas?' },
          { speaker: 'Eva', text: 'Me llamo Eva. Y tú, ¿cómo te llamas?' },
          { speaker: 'Adán', text: 'Me llamo Adán.' },
          { speaker: 'Eva', text: 'Mucho gusto.' },
          { speaker: 'Adán', text: 'Mucho gusto.' },
        ]}
      />

      <QuestionsBox
        questions={[
          '¿Cómo se llama la mujer?',
          '¿Cómo se llama el hombre?',
          'Y tú, ¿Cómo te llamas?',
        ]}
      />

      <SectionHeader type={SectionType.Dialog} text="diálogo 2" />

      <Dialog
        lines={[
          {
            speaker: 'Adán',
            text: '¡Buenos días Eva! ¿Qué tal?',
          },
          {
            speaker: 'Eva',
            text: 'Bien, bien. ¿Y tú?',
          },
          { speaker: 'Adán', text: 'Así así.' },
          { speaker: 'Eva', text: '¿De dónde eres Adán?' },
          { speaker: 'Adán', text: 'Soy de Paraíso. Y tú, ¿de dónde eres?' },
          { speaker: 'Eva', text: 'Yo también soy de Paraíso.' },
          { speaker: 'Adán', text: '¡Qué interesante!' },
        ]}
      />

      <QuestionsBox
        questions={[
          '¿De dónde es Adán?',
          '¿De dónde es Eva?',
          '¿De dónde eres tú?',
        ]}
      />

      <SectionHeader type={SectionType.Dialog} text="diálogo 3" />

      <MainImage src="/images/002.svg" />

      <Dialog
        lines={[
          {
            speaker: 'Adán',
            text: '¿Qué haces Eva?',
          },
          {
            speaker: 'Eva',
            text: 'Soy directora de Paraíso ¿Y tú?',
          },
          {
            speaker: 'Adán',
            text: 'Yo también soy director de Paraíso. ¿Dónde vives Eva?',
          },
          { speaker: 'Eva', text: 'Vivo en el norte. Y tú ¿dónde vives?' },
          { speaker: 'Adán', text: 'Yo vivo en el sur, en una isla bonita.' },
          { speaker: 'Eva', text: '¿Es grande la isla?' },
          { speaker: 'Adán', text: 'No, mi isla es muy pequeña.' },
          { speaker: 'Eva', text: 'Bueno... es tarde. ¡Hasta luego!' },
          { speaker: 'Adán', text: '¡Buenas noches Eva!' },
        ]}
      />

      <QuestionsBox
        questions={[
          '¿Quién es Eva?',
          '¿Quién es el hombre?',
          '¿Dónde vive la mujer?',
          '¿Dónde vive Adán?',
          '¿Cómo es la isla de Adán?',
          'Y tú, ¿qué haces? ¿Dónde vives?',
        ]}
      />

      <SectionHeader type={SectionType.Grammar} text="GRAMÁTICA 1" />

      <TableSection>
        <Table>
          {TableHeader(['Ser', 'Vivir', 'Llamarse'])}
          <tbody>
            <tr>
              <TableCell>
                <div className={c('flex')}>
                  <div className={c('text-right pr-1 w-1/2')}>Yo soy</div>
                  <div className={c('text-left pl-1 w-1/2')}>mujer</div>
                </div>
              </TableCell>
              <TableCell>Yo vivo</TableCell>
              <TableCell>Yo me llamo Eva</TableCell>
            </tr>
            <tr>
              <TableCell>
                <div className={c('flex')}>
                  <div className={c('text-right pr-1 w-1/2')}>Tú eres</div>
                  <div className={c('text-left pl-1 w-1/2')}>de Paraíso</div>
                </div>
              </TableCell>
              <TableCell>Tú vives EN</TableCell>
              <TableCell>Tú te llamas Adán</TableCell>
            </tr>
            <tr>
              <TableCell>
                <div className={c('flex')}>
                  <div className={c('text-right pr-1 w-1/2')}>Él/ella es</div>
                  <div className={c('text-left pl-1 w-1/2')}>director/a</div>
                </div>
              </TableCell>
              <TableCell>Él/ella vive</TableCell>
              <TableCell>Él/ella se llama Adán/Eva</TableCell>
            </tr>
          </tbody>
        </Table>
      </TableSection>

      <SectionHeader type={SectionType.Practice} text="PRÁCTICA 1" />

      <QuizSection>
        <QuizResponseComponent
          quizID={'5e4ca2a3-a757-4804-bc76-8173d6d7619e'}
        />
      </QuizSection>

      <SectionHeader type={SectionType.Grammar} text="GRAMÁTICA 2" />

      <TableSection>
        <Table>
          {TableHeader(['', 'Masculino', 'Femenino'])}
          <tbody>
            <tr>
              <TableCell>
                <div className={c('font-bold text-dirtygray text-right')}>
                  Singular
                </div>
              </TableCell>
              <TableCell>
                {S('el')} chic{S('o')} buen
                {S('o')}
              </TableCell>
              <TableCell>
                {S('la')} chic{S('a')} buen{S('a')}
              </TableCell>
            </tr>
            <tr>
              <TableCell>
                <div className={c('font-bold text-dirtygray text-right')}>
                  Plural
                </div>
              </TableCell>
              <TableCell>
                {S('los')} chic{S('os')} buen
                {S('os')}
              </TableCell>
              <TableCell>
                {S('las')} chic{S('as')} buen
                {S('as')}
              </TableCell>
            </tr>
            <tr>
              <TableCell>
                <div className={c('font-bold text-dirtygray text-right')}>
                  Singular
                </div>
              </TableCell>
              <TableCell>
                {S('un')} hombr{S('e')} interesant{S('e')}
              </TableCell>
              <TableCell>
                {S('una')} muje{S('r')} interesant{S('e')}
              </TableCell>
            </tr>
            <tr>
              <TableCell>
                <div className={c('font-bold text-dirtygray text-right')}>
                  Plural
                </div>
              </TableCell>
              <TableCell>
                {S('unos')} hombre{S('s')} interesante{S('s')}
              </TableCell>
              <TableCell>
                {S('unas')} mujer{S('es')} interesante{S('s')}
              </TableCell>
            </tr>
          </tbody>
        </Table>
      </TableSection>

      <SectionHeader type={SectionType.Practice} text="PRÁCTICA 2" />

      <QuizSection>
        <QuizResponseComponent
          quizID={'9e81085b-1b17-4b19-b862-39bc347fb33a'}
        />
      </QuizSection>

      <SectionHeader type={SectionType.Grammar} text="GRAMÁTICA 3" />

      <TableSection>
        <Table>
          <thead>
            <tr className={'bg-lightpurple text-darkpurple font-bold'}>
              <th rowSpan={2} className={'w-1/3'}>
                País
              </th>
              <th colSpan={2} className={'p-2'}>
                Nacionalidad
              </th>
              <th></th>
            </tr>
            <tr
              className={'text-darkpurple font-bold bg-indigo-100 w-full m-0'}
            >
              <th className={'p-2 w-1/3'}>Él es</th>
              <th className={'p-2 w-1/3'}>Ella es</th>
            </tr>
          </thead>
          <tbody>
            {grammatica3TableData.map((d, index) => (
              <tr key={index}>
                <TableCell className={'font-bold text-right pr-4'}>
                  {d[0]}
                </TableCell>
                <TableCell colSpan={d.length === 3 ? 1 : 2} className={'p-4'}>
                  {d[1]}
                </TableCell>
                {d.length === 3 && <TableCell>{d[2]}</TableCell>}
              </tr>
            ))}
          </tbody>
        </Table>
      </TableSection>

      <SectionHeader type={SectionType.Practice} text="PRÁCTICA 3" />

      <QuizSection>
        <QuizResponseComponent
          quizID={'0b751a9a-6a0b-4b6f-a3eb-d589dd06128c'}
        />
      </QuizSection>

      <SectionHeader type={SectionType.Practice} text="PRÁCTICA 4" />

      <QuizSection>
        <QuizResponseComponent
          quizID={'706fbc86-43bb-48db-aa64-be23c8353bcc'}
        />
      </QuizSection>

      <QuestionsBox
        questions={[
          '¿Dónde vive el presidente de Estados Unidos?',
          '¿Cómo se llama tu amigo?',
          '¿Cómo eres?',
          '¿Dónde vives?',
          '¿De dónde es el oso panda?',
          '¿Qué haces?',
          '¿Eres artista?',
          '¿Dónde vive el oso polar?',
          '¿Cómo se llama la madre de tu madre?',
          '¿De dónde es el café?',
        ]}
      />
    </div>
  )
}

export default Chapter
