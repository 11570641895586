/* eslint-disable jsx-a11y/accessible-emoji */
import React from 'react'
import { Quiz } from '../client'
import { QuizMode } from './Quiz'
import { QuizComponentProps, MatchTwoIndexesDataSource } from '../Quizes/Quiz'
import {
  QuizWrapper,
  QuizContainer,
  QuizInstruction,
} from '../chapters/components/Utils'
import Comment from './Comment'
import CommentReview from './CommentReview'

import Answer from './Answer'
// import { GroupProps } from './AssignWordsToGroups'

export interface QuestionProps {
  question: string
  answers: string[]
  index: number
  onChange?: Function
  defaultValue?: string
}

export const Question: React.FC<QuestionProps> = ({
  index,
  question,
  answers,
  onChange,
  defaultValue,
}) => {
  const questionParts = question.split('....')

  const selectProps = {
    onChange: (event: any) => onChange && onChange(event.target.value),
    value: '-1',
  }

  if (defaultValue) {
    selectProps.value = defaultValue
  }

  return (
    <div className="mt-4" key={`question-${index}`}>
      {index + 1}.{' '}
      {questionParts.map((questionPart, questionPartIndex) => (
        <span className="questionPart" key={`qp-${index}-${questionPartIndex}`}>
          {questionPart}
          {questionPartIndex < questionParts.length - 1 && (
            <select {...selectProps} className="font-bold">
              <option
                disabled
                hidden
                key={`qds-${index}-${questionPartIndex}`}
                value="-1"
              >
                ✍🏼 ________
              </option>
              {answers.map((answer, answerIndex) => {
                const answerParts = answer.split('/')
                const answerPart =
                  answerParts.length === 1
                    ? answerParts[0]
                    : answerParts[questionPartIndex]

                return (
                  <option key={`q-${index}-${answerIndex}`} value={answerIndex}>
                    {answerPart}
                  </option>
                )
              })}
            </select>
          )}
        </span>
      ))}
    </div>
  )
}

export interface AssignWordsToGroupsQuiz extends Quiz {
  groups: string[]
  words: string[]
}

interface FillOutSingleChoiceAnswerInSentencesComponentProps
  extends QuizComponentProps {
  quiz: AssignWordsToGroupsQuiz
  mode: QuizMode
}

export const FillOutSingleChoiceAnswerInSentences: React.FC<FillOutSingleChoiceAnswerInSentencesComponentProps> = (
  props
) => {
  const { answers, evaluations, comments, notes, mode, commentTriggerForQuestion } = props

  const {
    quiz: { questions, instruction },
  } = props

  const dataSource = new MatchTwoIndexesDataSource(
    answers,
    evaluations,
    comments,
    notes
  )

  if (props.onAnswersUpdate) {
    dataSource.onAnswersUpdate = props.onAnswersUpdate
  }

  if (props.onEvaluationsUpdate) {
    dataSource.onEvaluationsUpdate = props.onEvaluationsUpdate
  }

  if (props.onCommentsUpdate) {
    dataSource.onCommentsUpdate = props.onCommentsUpdate
  }

  if (props.onNotesUpdate) {
    dataSource.onNotesUpdate = props.onNotesUpdate
  }

  const {
    isAnswerEvaluated,
    isAnswerCorrect,
    setAnswerEvaluation,
    getStringAnswerValue,
    getAnswerValue,
    setAnswerValue,
    setNotes
  } = dataSource

  const questionComponents = questions.map((question: any, index: number) => ({
    question: question.question,
    answers: question.answers,
    index: index,
    defaultValue: getStringAnswerValue(index),
    onChange: (value: string) => handleSelect(value, index),
  }))

  const handleSelect = (value: string, index: number) => {
    setAnswerValue(index, parseInt(value))
  }

  const renderCommentEditorIfNeeded = (quizMode: QuizMode, questionIndex: number) => {
    if (quizMode === QuizMode.Answer) {
      return
    }

    const { isCommentEditorOpenForQuestion, lastCommentEditorOpen } = props

    const autoFocus =
      lastCommentEditorOpen && lastCommentEditorOpen() === questionIndex

    if (
      isCommentEditorOpenForQuestion &&
      !isCommentEditorOpenForQuestion(questionIndex)
    ) {
      return undefined
    }

    const value = dataSource.getCommentForAnswer(questionIndex)

    if (quizMode === QuizMode.Review) {
      if (value && value.length > 0) {
        return <CommentReview text={value} />
      }

      return <div></div>
    }

    return (
      <Comment
        autoFocus={autoFocus}
        value={value}
        onChange={(e: React.FormEvent<HTMLInputElement>) =>
          dataSource.setCommentForAnswer(questionIndex, e.currentTarget.value)
        }
      />
    )
  }


  const renderAnswer = (_question: QuestionProps) => {
    const { index } = _question
    const { onMouseOverQuestion, onMouseLeftQuestion } = props
    const key = `question-${index}`

    const questionParts = _question.question.split('....')

    return (
      <div className="mt-4 pl-2" key={key}
        onMouseOver={() =>
          onMouseOverQuestion && onMouseOverQuestion(index)
        }
        onMouseLeave={() =>
          onMouseLeftQuestion && onMouseLeftQuestion(index)
        }
      >
        {commentTriggerForQuestion && commentTriggerForQuestion(index)}

        <span className="font-bold">{index + 1}.{' '}</span>
        {
          questionParts.map((questionPart, questionPartIndex) => {
            const answerIndex = getAnswerValue(index)
            let answerContent: string[] = []

            if (typeof answerIndex === 'number') {
              const answers = _question.answers
              if (answers && answers[answerIndex]) {
                const answer = answers[answerIndex]
                if (answer.match('/')) {
                  answerContent = answer.split(/\s+[/|]\s+/)
                } else {
                  answerContent = [answer]
                }
              }
            }

            return (
              <span
                className="questionPart"
                key={`qp-${index}-${questionPartIndex}`}
              >
                {questionPart}
                {questionPartIndex < questionParts.length - 1 && (
                  <Answer
                    disabled={mode !== QuizMode.Evaluation}
                    content={answerContent[questionPartIndex]}
                    evaluated={isAnswerEvaluated(index)}
                    key={key}
                    correct={isAnswerCorrect(index)}
                    onEvaluate={(correct: boolean) => {
                      setAnswerEvaluation(index, correct)
                    }}
                  />
                )}
              </span>
            )
          })
        }
        <div className="my-4">
          {renderCommentEditorIfNeeded(mode, index)}
        </div>
      </div>
    )
  }

  const renderQuestion = (question: QuestionProps) => {
    const key = `question-${question.index}`

    if (mode !== QuizMode.Answer) {
      return renderAnswer(question)
    }

    return <Question {...question} key={key} />
  }

  const renderQuiz = () => {
    return (
      <div className="questions">{questionComponents.map(renderQuestion)}</div>
    )
  }

  const renderNotes = (notes?: string) =>
    notes && <CommentReview general={true} text={notes} />

  return (
    <QuizWrapper>
      <QuizInstruction>{instruction}</QuizInstruction>

      <div className="my-5 w-full">
        {mode === QuizMode.Evaluation && (
          <Comment
            label="General notes to the student"
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              setNotes && setNotes(e.currentTarget.value)
            }
          />
        )}
        {mode === QuizMode.Review && renderNotes(notes)}
      </div>

      <QuizContainer className="w-full flex flex-row justify-start">
        {renderQuiz()}
      </QuizContainer>
    </QuizWrapper>
  )
}

export default FillOutSingleChoiceAnswerInSentences
