import React from 'react'
import c from 'classnames'

const CommentReview = (props: { className?: string, text?: string; general?: boolean }) => {
  const { className, text, general } = props

  if (!text) {
    return <div></div>
  }

  return (
    <div
      className={c('italic', 'bg-gray-200', 'rounded-l', 'px-4', 'mb-5', 'mt-0', 'py-2', {
        'bg-gray-400': general,
      }, className)}
    >
      <span role="img" aria-label="speech baloon">
        💬
      </span>
      <span className="ml-2">{text}</span>
    </div>
  )
}

export default CommentReview
