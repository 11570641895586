import React from 'react'
import Answer from './Answer'
import { QuizMode, FillInGapsDataSource } from './Quiz'
import InputField from './InputField'
import Comment from './Comment'
import { QuizComponentProps } from '../Quizes/Quiz'
import { Quiz } from '../client'
import {
  QuizWrapper,
  QuizContainer,
  QuizInstruction,
} from '../chapters/components/Utils'
import CommentReview from './CommentReview'
import classnames from 'classnames'
const c = classnames

interface State {
  dataSource: FillInGapsDataSource
}

export interface FillOutWordsInSentencesQuiz extends Quiz {
  dialog: boolean
}

interface FillOutWordsInSentencesQuizComponentProps extends QuizComponentProps {
  quiz: FillOutWordsInSentencesQuiz
  mode: QuizMode
}

class FillOutWordsInSentences extends React.Component<
  FillOutWordsInSentencesQuizComponentProps,
  State
> {
  constructor(props: FillOutWordsInSentencesQuizComponentProps) {
    super(props)

    const { answers, evaluations, comments, notes } = props

    const newDataSource = new FillInGapsDataSource(
      answers,
      evaluations,
      comments,
      notes
    )

    if (props.onAnswersUpdate) {
      newDataSource.onAnswersUpdate = props.onAnswersUpdate
    }

    if (props.onEvaluationsUpdate) {
      newDataSource.onEvaluationsUpdate = props.onEvaluationsUpdate
    }

    if (props.onCommentsUpdate) {
      newDataSource.onCommentsUpdate = props.onCommentsUpdate
    }

    if (props.onNotesUpdate) {
      newDataSource.onNotesUpdate = props.onNotesUpdate
    }

    this.state = {
      dataSource: newDataSource,
    }
  }

  renderAnswer = (questionIndex: number, inputIndex: number) => {
    const { dataSource } = this.state
    const {
      getAnswerValue,
      setAnswerEvaluation,
      isAnswerEvaluated,
      isAnswerCorrect,
    } = dataSource

    return (
      <Answer
        disabled={this.props.mode === QuizMode.Review}
        content={getAnswerValue(questionIndex, inputIndex)}
        evaluated={
          isAnswerEvaluated && isAnswerEvaluated(questionIndex, inputIndex)
        }
        correct={isAnswerCorrect && isAnswerCorrect(questionIndex, inputIndex)}
        onEvaluate={(correct: boolean) =>
          setAnswerEvaluation &&
          setAnswerEvaluation(questionIndex, inputIndex, correct)
        }
      />
    )
  }

  renderQuestion = (question: string, questionIndex: number) => {
    const { mode } = this.props

    switch (mode) {
      case QuizMode.Answer:
        return this.renderQuestionAnswer(question, questionIndex)

      case QuizMode.Evaluation:
        return this.renderEditableQuestionEvaluation(question, questionIndex)

      case QuizMode.Review:
        return this.renderQuestionEvaluation(question, questionIndex)

      default:
        break
    }

    return
  }

  splitQuestion = (question: string) => question.split(/\.\.\.+/)

  inputLenghts = (question: string) => {
    const inputs = []

    console.log(question)

    let regexp =  /[^\.]?(\.\.\.+)[^\.]?/g;
    let match = regexp.exec(question);
    do {
      if (match != null && match[1] === '.....') {
        inputs.push(true)
      } else {
        inputs.push(false)
      }
    } while((match = regexp.exec(question)) !== null);

    return inputs
  }

  questionFragmentSplitForBreaklines = (questionFragment: string) =>
    questionFragment.split(/\/\/\/\//)

  renderQuestionAnswer = (question: string, questionIndex: number) => {
    const {
      dataSource: { setAnswerValue },
    } = this.state

    const { quiz } = this.props

    const questionFragments = this.splitQuestion(question)
    const inputLenghts = this.inputLenghts(question)
    console.log('INPUT LENGTHS', inputLenghts)

    return (
      <li key={`input-list-${questionIndex}`} className={'mt-4 pl-2'}>
        {questionFragments.map((questionFragment, fragmentIndex) => {
          let parsedFragment: any = questionFragment
          let longInput = false

          if (quiz.dialog === true && fragmentIndex === 0) {
            parsedFragment = (
              <span className="font-bold">
                {questionFragment.split(' ')[0]}
              </span>
            )
          } else {
            const lines = this.questionFragmentSplitForBreaklines(
              questionFragment
            )
            if (lines.length > 1) {
              longInput = true
              parsedFragment = lines.map((line, lineIndex) =>
                lineIndex > 0 ? (
                  <div key={lineIndex}>{line}</div>
                ) : (
                  <span key={lineIndex}>{line}</span>
                )
              )
            }
          }

          return (
            <span key={`input-list-span-${questionIndex}-${fragmentIndex}`}>
              {parsedFragment}

              {fragmentIndex !== questionFragments.length - 1 && (
                <InputField
                  questionIndex={questionIndex}
                  inputIndex={fragmentIndex}
                  setValue={(value: string) =>
                    setAnswerValue(questionIndex, fragmentIndex, value)
                  }
                  className={c(`mx-4 w-32`, {
                    'w-full md:w-3/4': longInput || inputLenghts[fragmentIndex] === true,
                  })}
                />
              )}
            </span>
          )
        })}
      </li>
    )
  }

  renderCommentEditorIfNeeded = (quizMode: QuizMode, questionIndex: number) => {
    if (quizMode === QuizMode.Answer) {
      return
    }

    const { dataSource } = this.state
    const { isCommentEditorOpenForQuestion, lastCommentEditorOpen } = this.props

    const autoFocus =
      lastCommentEditorOpen && lastCommentEditorOpen() === questionIndex

    if (
      isCommentEditorOpenForQuestion &&
      !isCommentEditorOpenForQuestion(questionIndex)
    ) {
      return undefined
    }

    const value = dataSource.getCommentForAnswer(questionIndex)

    if (quizMode === QuizMode.Review) {
      if (value && value.length > 0) {
        return <CommentReview text={value} />
      }

      return <div></div>
    }

    return (
      <Comment
        autoFocus={autoFocus}
        value={value}
        onChange={(e: React.FormEvent<HTMLInputElement>) =>
          dataSource.setCommentForAnswer(questionIndex, e.currentTarget.value)
        }
      />
    )
  }

  renderQuestionEvaluation = (question: string, questionIndex: number) => {
    const questionFragments = this.splitQuestion(question)

    return (
      <li key={`input-list-${questionIndex}`} className="mt-4 pl-2">
        {questionFragments.map((fragment, fragmentIndex) => (
          <span key={`input-list-span-${questionIndex}-${fragmentIndex}`}>
            {fragment}
            {fragmentIndex !== questionFragments.length - 1 &&
              this.renderAnswer(questionIndex, fragmentIndex)}
          </span>
        ))}

        {this.renderCommentEditorIfNeeded(QuizMode.Review, questionIndex)}
      </li>
    )
  }

  renderEditableQuestionEvaluation = (
    question: string,
    questionIndex: number
  ) => {
    const questionFragments = this.splitQuestion(question)
    const {
      commentTriggerForQuestion,
      onMouseOverQuestion,
      onMouseLeftQuestion,
      quiz,
    } = this.props

    return (
      <li
        key={`input-list-${questionIndex}`}
        className={'mt-4 pl-2'}
        onMouseOver={() =>
          onMouseOverQuestion && onMouseOverQuestion(questionIndex)
        }
        onMouseLeave={() =>
          onMouseLeftQuestion && onMouseLeftQuestion(questionIndex)
        }
      >
        {commentTriggerForQuestion && commentTriggerForQuestion(questionIndex)}

        {questionFragments.map((fragment, fragmentIndex) => (
          <span key={`input-list-span-${questionIndex}-${fragmentIndex}`}>
            {quiz.dialog === true && fragmentIndex === 0 ? (
              <span className="font-bold">{fragment.split(' ')[0]}</span>
            ) : (
              fragment
            )}

            {fragmentIndex !== questionFragments.length - 1 &&
              this.renderAnswer(questionIndex, fragmentIndex)}
          </span>
        ))}
        {this.renderCommentEditorIfNeeded(QuizMode.Evaluation, questionIndex)}
      </li>
    )
  }

  renderNotes = (notes?: string) =>
    notes && <CommentReview general={true} text={notes} />

  render() {
    const { quiz, mode, notes } = this.props
    const {
      dataSource: { setNotes },
    } = this.state

    const listDecimal = !quiz.dialog && quiz.questions.length > 1

    return (
      <QuizWrapper>
        <QuizInstruction>{quiz.instruction}</QuizInstruction>

        <div className="my-5">
          {mode === QuizMode.Evaluation && (
            <Comment
              label="General notes to the student"
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setNotes && setNotes(e.currentTarget.value)
              }
            />
          )}
        </div>

        {mode === QuizMode.Review && this.renderNotes(notes)}
        <QuizContainer>
          <ol
            className={c('w-full', {
              'list-decimal': listDecimal,
              'pl-8': listDecimal,
            })}
          >
            {quiz.questions.map(this.renderQuestion)}
          </ol>
        </QuizContainer>
      </QuizWrapper>
    )
  }
}

export default FillOutWordsInSentences
