import React from 'react'
import Dialog from './components/Dialog'
import { SectionHeader, SectionType } from './components/SectionHeader'
import ChapterHeader from './components/ChapterHeader'
import QuestionsBox from './components/QuestionsBox'
import QuizSection from './components/QuizSection'
import QuizResponseComponent from '../QuizResponseComponent'
import MainImage from './components/MainImage'

const Chapter = () => {
  return (
    <div>
      <ChapterHeader text="Lección 9" />
      <SectionHeader type={SectionType.Dialog} text="diálogo 1" />
      <Dialog
        lines={[
          {
            speaker: 'Eva',
            text: '¡Hola Adán! Aquí está tu periódico.',
          },
          {
            speaker: 'Adán',
            text: 'Muchas gracias.',
          },
          {
            speaker: 'Eva',
            text: '¿Hay algo interesante?',
          },
          {
            speaker: 'Adán',
            text:
              'Sí, hay un artículo sobre México D.F. Es una ciudad muy grande.',
          },
          {
            speaker: 'Eva',
            text: '¿Qué es México D.F.?',
          },
          {
            speaker: 'Adán',
            text:
              'Es la capital de un país muy grande que se llama México y está en América Latina.',
          },
          {
            speaker: 'Eva',
            text: '¡Qué interesante!',
          },
        ]}
      />

      <blockquote className="bg-gray-200 border-l-8 border-gray-700 px-8 italic text-l xl:text-xl py-4 mt-4 text-justify">
        <div className="font-bold text-4xl">&quot;</div> La ciudad de México
        tiene casi veinticinco millones de habitantes. Hay muchos coches y mucho
        tráfico. También hay mucha industria, muchas fábricas y mucha
        contaminación pero es normal en una ciudad grande. No hay silencio y no
        hay aire limpio pero es una ciudad fantástica y tiene muchas
        atracciones. Hay universidades, actividades culturales, monumentos,
        centros comerciales, restaurantes, discotecas, cines, teatros y museos
        interesantes. Es una ciudad de contrastes: hay mucha gente pobre y mucha
        gente muy rica. Los mexicanos son alegres y optimistas y la cocina
        mexicana es famosa en todo el mundo especialmente las tortillas y el
        tequila.
      </blockquote>

      <QuestionsBox
        questions={[
          '¿Qué es México D.F.?',
          '¿Dónde está México D.F?',
          '¿Qué hay en México D.F.?',
          '¿Qué no hay en México D.F.?',
          '¿Cómo es México D.F.?',
          '¿Cómo son los mexicanos?',
          '¿Cómo es la cocina mexicana?',
          '¿Qué aspectos positivos tiene tu ciudad?',
          '¿Qué aspectos negativos tiene tu ciudad?',
          '¿Cómo es tu cocina nacional?',
        ]}
      />

      <SectionHeader type={SectionType.Practice} text="PRÁCTICA 1" />

      <QuizSection title="Describe una de las ciudades.">
        <ol className="list-lowerLatin pl-8">
          <li>Nueva York</li>
          <li>Tokio</li>
          <li>Moscú</li>
          <li>Londres</li>
          <li>El Cairo</li>
          <li>Río de Janeiro</li>
        </ol>
      </QuizSection>

      <SectionHeader type={SectionType.Review} text="REPASO 1" />

      <QuizSection>
        <QuizResponseComponent
          quizID={'01c997b1-90c3-4588-a373-dd5c65d546ab'}
        />
      </QuizSection>

      <SectionHeader type={SectionType.Review} text="REPASO 2" />

      <QuizSection>
        <QuizResponseComponent
          quizID={'662bba33-2127-4c21-9d73-75768454ebf1'}
        />
      </QuizSection>

      <SectionHeader type={SectionType.Review} text="REPASO 3" />

      <MainImage src="/images/023.svg" className="mt-4" alt="023" />

      <QuizSection>
        <QuizResponseComponent
          quizID={'ef9480c8-8297-43dd-9a83-b9c0c8c4d6c2'}
        />
      </QuizSection>

      <SectionHeader type={SectionType.Review} text="AUTOEVALUACIÓN 2" />

      <QuizSection>
        <QuizResponseComponent
          quizID={'30f2c653-d39b-4fb8-ac00-1c795631a45a'}
        />
      </QuizSection>
    </div>
  )
}

export default Chapter
