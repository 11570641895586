import React from 'react'
const BlockContent = require('@sanity/block-content-to-react')

interface SpeechBubbleProps {
  body: string
}

const SpeechBubble = ({ body }: SpeechBubbleProps) => (
  <div className="p-6 max-w-sm mx-auto bg-green-500 rounded-xl shadow-md flex items-center space-x-4">
    <div className="text-white text-center w-full italic text-2xl">
      <BlockContent blocks={body} />
    </div>
  </div>
)

export default SpeechBubble
