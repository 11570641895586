import React from 'react'
import Dialog from './components/Dialog'
import { SectionHeader, SectionType } from './components/SectionHeader'
import ChapterHeader from './components/ChapterHeader'
import QuestionsBox from './components/QuestionsBox'
import QuizSection from './components/QuizSection'
import QuizResponseComponent from '../QuizResponseComponent'
import { Section } from './components/Utils'
import {
  Table,
  TableSection,
  BoldTableContent,
  TableCell,
} from './components/Utils'

import c from 'classnames'

const CalendarEvent = (
  hour: string,
  name: string,
  color: string,
  marginTop?: string
) => (
  <div
    style={{ marginTop: marginTop ? marginTop : '2px' }}
    className={c('rounded-lg', color)}
  >
    <div>
      <strong>{hour}</strong>
    </div>
    <div>{name}</div>
  </div>
)

const Chapter = () => {
  return (
    <div>
      <ChapterHeader text="Lección 11" />
      <SectionHeader type={SectionType.Dialog} text="diálogo 1" />
      <Dialog
        lines={[
          {
            speaker: 'Eva',
            text: '¿Cómo es un día normal en Marte?',
          },
          {
            speaker: 'Gordi',
            text:
              'Normalmente los marcianos se levantan a las 7:00 y desayunan: beben un café verde y comen huevos fritos pero yo me levanto a las 10:00 porque trabajo en casa. Me ducho rápido, me lavo los dientes y me preparo para trabajar.',
          },
          {
            speaker: 'Verdi',
            text:
              'Yo no trabajo en casa. Mi novia y yo vamos al trabajo en metro. Salimos de casa a las 8:00 y llegamos a la oficina a las 9:00. Trabajamos 8 horas, no comemos mucho, regresamos a casa y cenamos.',
          },
          {
            speaker: 'Eva',
            text:
              'Nosotros nos duchamos por la mañana y por la tarde, yo me peino y me pinto los ojos antes de cenar y Adán se afeita todos los días. Y en Marte, ¿os ducháis con agua fría o con agua caliente?',
          },
          {
            speaker: 'Gordi',
            text: 'Yo me ducho con agua fría pero tú, Verdi, te bañas ¿verdad?',
          },
        ]}
      />

      <QuestionsBox
        questions={[
          '¿Cuándo se ducha Adán?',
          '¿Qué desayunan los marcianos?',
          '¿A qué hora llegan a la oficina Verdi y Roti?',
          '¿A qué hora desayunas tú?',
          '¿Cuántas horas al día trabajas?',
          '¿A qué hora cenas?',
        ]}
      />

      <SectionHeader type={SectionType.Review} text="Repaso 1" />
      <QuizSection>
        <QuizResponseComponent
          quizID={'3642b1fb-e0a5-48f4-9b6c-28e37b90cb19'}
        />
      </QuizSection>
      <SectionHeader type={SectionType.Grammar} text="GRAMÁTICA 1" />
      <QuizSection>
        <QuizResponseComponent
          quizID={'694b67a8-889b-4d6f-8b8c-a2e620dab37f'}
        />
      </QuizSection>
      <SectionHeader type={SectionType.Practice} text="PRÁCTICA 1" />
      <QuizSection>
        <QuizResponseComponent
          quizID={'76319bac-430d-4999-a25d-ca5591bc0adc'}
        />
      </QuizSection>
      <SectionHeader type={SectionType.Grammar} text="GRAMÁTICA 2" />
      <div className="mt-10">
        <TableSection>
          <Table>
            <thead>
              <tr className={'bg-lightpurple text-darkpurple font-bold'}>
                <th className={''}></th>
                <th className={''}>
                  <strong>IR</strong>
                </th>
                <th className={''}>
                  <strong>SALIR</strong>
                </th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <TableCell>
                  <BoldTableContent>yo</BoldTableContent>
                </TableCell>
                <TableCell className="whitespace-no-wrap text-center">
                  <span className="font-bold">voy</span>
                </TableCell>
                <TableCell className="whitespace-no-wrap text-center">
                  <span className="font-bold">salgo</span>
                </TableCell>
              </tr>
              <tr>
                <TableCell>
                  <BoldTableContent>tú</BoldTableContent>
                </TableCell>
                <TableCell className="whitespace-no-wrap text-center">
                  <span className="font-bold">vas</span>
                </TableCell>
                <TableCell className="whitespace-no-wrap text-center">
                  sales
                </TableCell>
              </tr>
              <tr>
                <TableCell>
                  <BoldTableContent>él/ella/usted</BoldTableContent>
                </TableCell>
                <TableCell className="whitespace-no-wrap text-center">
                  <span className="font-bold">va</span>
                </TableCell>
                <TableCell className="whitespace-no-wrap text-center">
                  sale
                </TableCell>
              </tr>
              <tr>
                <TableCell>
                  <BoldTableContent>nosotros/as</BoldTableContent>
                </TableCell>
                <TableCell className="whitespace-no-wrap text-center">
                  <span className="font-bold">vamos</span>
                </TableCell>
                <TableCell className="whitespace-no-wrap text-center">
                  salimos
                </TableCell>
              </tr>
              <tr>
                <TableCell>
                  <BoldTableContent>vosotros/as</BoldTableContent>
                </TableCell>
                <TableCell className="whitespace-no-wrap text-center">
                  <span className="font-bold">vais</span>
                </TableCell>
                <TableCell className="whitespace-no-wrap text-center">
                  salís
                </TableCell>
              </tr>
              <tr>
                <TableCell>
                  <BoldTableContent>ellos/as/ustedes</BoldTableContent>
                </TableCell>
                <TableCell className="whitespace-no-wrap text-center">
                  <span className="font-bold">van</span>
                </TableCell>
                <TableCell className="whitespace-no-wrap text-center">
                  salen
                </TableCell>
              </tr>
            </tbody>
          </Table>
        </TableSection>
      </div>
      <SectionHeader type={SectionType.Grammar} text="GRAMÁTICA 3" />

      <Section>
        <div className="flex flex-col">
          <div className="flex flex-row justify-evenly">
            <div className="w-1/2 flex flex-col justify-center">
              <ul className="list-disc">
                <li>Es la una en punto.</li>
                <li>Es la una y diez.</li>
                <li>Son las dos y cuarto.</li>
                <li>Son las tres y veinte.</li>
                <li>Son las cuatro y media.</li>
                <li>Son las seis menos veinte.</li>
                <li>Son las doce menos cuarto.</li>
              </ul>
            </div>
            <div className="w-1/2">
              <img
                src="/images/026.svg"
                alt="background"
                className="object-center max-h-2/3 w-full"
              />
            </div>
          </div>
          <div className="flex flex-row justify-evenly mt-4">
            <div className="w-1/2">
              <p>
                <strong>¿Qué hora es...? /</strong>
              </p>
              <p>
                <strong>¿Qué horas son...?</strong>
              </p>
            </div>
            <div className="w-1/2">
              <p>Es la una y media. Son las dos.</p>
              <p>Son las cuatro y cuarto.</p>
            </div>
          </div>
          <div className="flex flex-row justify-evenly mt-4">
            <div className="w-1/2">
              <p>
                <strong>¿A qué hora...?</strong>
              </p>
            </div>
            <div className="w-1/2">
              <p>A las cinco de la mañana.</p>
              <p>A la una de la tarde.</p>
            </div>
          </div>
          <div className="flex flex-row justify-evenly mt-4">
            <div className="w-1/2">
              <p>
                <strong>De.....a</strong>
              </p>
              <p>
                <strong>Desde las.........hasta las</strong>
              </p>
            </div>
            <div className="w-1/2">
              <p>
                Trabajo <strong>de</strong> ocho <strong>a</strong> cinco.
              </p>
              <p>
                Trabajo <strong>desde las</strong> ocho{' '}
                <strong>hasta las</strong> cuatro.
              </p>
            </div>
          </div>
        </div>
      </Section>

      <SectionHeader type={SectionType.Practice} text="PRÁCTICA 2" />
      <QuizSection>
        <QuizResponseComponent
          quizID={'32488880-3334-435e-ac6a-c22d6d7f8eb0'}
        />
      </QuizSection>
      <SectionHeader type={SectionType.Practice} text="PRÁCTICA 3" />
      <QuizSection>
        <QuizResponseComponent
          quizID={'e0c2c4be-c980-4bf6-b598-d753d6bf4adc'}
        />
      </QuizSection>

      <SectionHeader type={SectionType.Practice} text="PRÁCTICA 4" />

      <div className="mt-10">
        <h2 className="text-2xl sm:text-4xl font-light w-full text-center">
          ¿Cuándo va Carmen al cine?
        </h2>
        <Section className="flex flex-col text-xxs">
          <table className="table-auto border-collapse">
            <thead>
              <tr className="bg-lightgreen">
                <th>lunes</th>
                <th>martes</th>
                <th>miércoles</th>
                <th>jueves</th>
                <th>viernes</th>
                <th className="text-sadgreen">sábado</th>
                <th className="text-sadgreen">domingo</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border border-0 border-r-1 border-gray-500">
                  <div className="h-full flex flex-col justify-start">
                    {CalendarEvent(
                      '8:00',
                      'salir de casa',
                      'bg-semilightblue',
                      '20px'
                    )}
                    {CalendarEvent(
                      '12:00',
                      'reunión con el jefe',
                      'bg-lightgreen'
                    )}
                    {CalendarEvent('19:45', 'cine con Paco', 'bg-lightpurple')}
                  </div>
                </td>
                <td className="border border-r-1 border-gray-500">
                  <div className="h-full flex flex-col justify-start">
                    {CalendarEvent(
                      '10:00',
                      'desayunar con el cliente',
                      'bg-semilightblue',
                      '60px'
                    )}
                    {CalendarEvent(
                      '18:15',
                      'practicar tenis con Juan',
                      'bg-lightgreen'
                    )}
                  </div>
                </td>
                <td className="border border-r-1 border-gray-500">
                  <div className="h-full flex flex-col justify-start">
                    {CalendarEvent(
                      '7:45',
                      'clase de español',
                      'bg-semilightblue',
                      '15px'
                    )}
                    {CalendarEvent(
                      '13:30',
                      'hablar por teléfono con el supervisor de México',
                      'bg-lightpurple'
                    )}
                  </div>
                </td>
                <td className="border border-r-1 border-gray-500">
                  <div className="h-full flex flex-col justify-start">
                    {CalendarEvent(
                      '6:00',
                      'viajar a Madrid en tren',
                      'bg-lightpurple'
                    )}
                    {CalendarEvent(
                      '10:00-13:00',
                      'participar en una conferencia sobre atención al cliente',
                      'bg-semilightblue'
                    )}
                  </div>
                </td>
                <td className="border border-r-1 border-gray-500">
                  <div className="h-full flex flex-col justify-start">
                    {CalendarEvent(
                      '9:00-15:00',
                      'organizar la reunión con los colaboradores',
                      'bg-lightpurple',
                      '40px'
                    )}
                    {CalendarEvent(
                      '21:00',
                      'salir de copas con los compañeros de trabajo',
                      'bg-semilightblue'
                    )}
                  </div>
                </td>
                <td className="border border-r-1 border-gray-500">
                  <div className="h-full flex flex-col justify-start">
                    {CalendarEvent(
                      '10:00-12:00',
                      'limpiar la casa',
                      'bg-lightgreen',
                      '60px'
                    )}
                    {CalendarEvent(
                      '13:00',
                      'clase de yoga',
                      'bg-semilightblue'
                    )}
                    {CalendarEvent('17:00', 'ver la tele', 'bg-semilightblue')}
                  </div>
                </td>
                <td className="border border-r-1 border-gray-500">
                  <div className="h-full flex flex-col justify-start">
                    {CalendarEvent(
                      '14:00',
                      'comer con la familia',
                      'bg-semilightblue',
                      '125px'
                    )}
                    {CalendarEvent(
                      '20:30-22:00',
                      'leer una novela',
                      'bg-lightgreen'
                    )}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </Section>

        <QuizSection>
          <QuizResponseComponent
            quizID={'cbddd8a4-b11f-4af6-acb4-a01e5ee3e485'}
          />
        </QuizSection>
      </div>

      <SectionHeader type={SectionType.Practice} text="PRÁCTICA 5" />

      <QuizSection>
        <QuizResponseComponent
          quizID={'414e0406-348b-43f5-abec-ca4d518153a5'}
        />
      </QuizSection>
    </div>
  )
}

export default Chapter
